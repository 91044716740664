import { Button, Typography } from '@mui/material';

import CopyWithLinks from '../../components/Display/CopyWithLinks';
import Fetching from '../../components/Display/Fetching';
import StaticPage from '../../components/Display/StaticPage';
import WarningBox from '../../components/Display/WarningBox';
import useFetchData from '../../components/hooks/useFetchData';
import Link from '../../components/Interface/Link';
import { discordInviteUrl, patreonUrl } from '../../config';
import { path } from '../../config/path';

import styles from './index.module.css';

// -- Types --------------------------------------------------------------------

export interface AboutCopy {
  body: string[];
  creditList: { name: string; url: string }[];
  credits: string;
  intro: string[];
  warning: string;
}

// -- Config -------------------------------------------------------------------

/** Path to the road map data file. */
const aboutJsonPath = '/data/about.json';

// -- Public Component ---------------------------------------------------------

/**
 * Renders the about page.
 */
export default function AboutPage() {
  const { data: aboutCopy, ...fetchState } = useFetchData<AboutCopy>(aboutJsonPath);

  return (
    <StaticPage
      browserTitle="About"
      metaDescription="All about the Mystic Waffle D&D Dungeon Generator. Who created it? Is it awesome? Let's find out!"
      path={path.about}
      title="About Mystic Waffle"
    >
      <Fetching {...fetchState} />

      {aboutCopy &&
        <div>
          {aboutCopy.intro.map((paragraph, i) => (
            <p className="paragraph" key={i}>
              {paragraph}
            </p>
          ))}

          <WarningBox className="paragraph">
            <Typography
              component="sup"
              variant="body2"
            >
              {aboutCopy.warning}
            </Typography>
          </WarningBox>

          {aboutCopy.body.map((paragraph, i) => (
            <p className="paragraph" key={i}>
              <CopyWithLinks copy={paragraph} />
            </p>
          ))}

          <div className={styles.center}>
            <Button
              color="primary"
              href={discordInviteUrl}
              rel="noopener noreferrer"
              size="medium"
              target="_blank"
              variant="text"
            >
              👉 Join Us On Discord Here 👈
            </Button>

            <span aria-hidden className={styles.buttonSeparator}>•</span>

            <Button
              color="primary"
              href={patreonUrl}
              rel="noopener noreferrer"
              size="medium"
              target="_blank"
              variant="text"
            >
              Support the Generator Here ❤️
            </Button>

            <p>Happy adventuring! &ndash; AJ</p>
          </div>

          <div className={styles.team}>
            <div>
              <img alt="AJ" src="/img/team/aj.jpg" />
              <Typography variant="h4">AJ</Typography>
              <Typography align="center" component="p" fontSize="0.825rem">Sorcerer</Typography>
            </div>
            <div>
              <img alt="Paul" src="/img/team/paul.jpg" />
              <Typography variant="h4">Paul</Typography>
              <Typography align="center" component="p" fontSize="0.825rem">Shaman</Typography>
            </div>
          </div>

          <Typography
            marginTop={4}
            textAlign="center"
            variant="h2"
          >
            Credits
          </Typography>

          <p>{aboutCopy.credits}</p>

          <Typography component="ul">
            {aboutCopy.creditList.map(({ name, url }, i) => (
              <li key={i}><Link href={url}>{name}</Link></li>
            ))}
          </Typography>
        </div>
      }
    </StaticPage>
  );
}
