import { useCallback } from 'react';

import { getMapEntryRequired } from '../../../lib';
import { CONNECTION, TOOL } from '../../../lib/matrix';
import { getConnectionDirection, isConnection, isConnectionFlippable } from '../../../lib/matrix/utility';

import type { Brush, CellValue, MatrixInstructions } from '../../../lib/matrix';
import type { InteractiveMapState } from './hooks/../useInteractiveMap';

// -- Types --------------------------------------------------------------------

interface UseRotateProps {
  activeBrush: Brush;
  activeTool: TOOL;
  connectionInstructions: MatrixInstructions['connections'];
  onFlipConnection: (connectionId: number) => void;
  selectedRegionId: CellValue;
}

export type OnRotate = (regionId?: CellValue) => void;

// -- Public Hook --------------------------------------------------------------

/**
 * Returns a callback which handles content rotation when the "r" key is
 * pressed (see `useMapKeyboard()`). Supports rotating flippable connections
 * when selected or actively being drawn.
 */
export default function useRotate({
  activeBrush,
  activeTool,
  connectionInstructions,
  onFlipConnection,
  selectedRegionId,
}: UseRotateProps): OnRotate {
  return useCallback((regionId) => {
    if (isConnectionSelectionFlippable(activeTool, connectionInstructions, selectedRegionId)) {
      onFlipConnection(selectedRegionId);
      return;
    }

    if (isConnectionDrawFlippable(activeTool, activeBrush, connectionInstructions, regionId)) {
      onFlipConnection(regionId);
      return;
    }
  }, [
    activeBrush,
    activeTool,
    connectionInstructions,
    onFlipConnection,
    selectedRegionId,
  ]);
}

// -- Private Functions --------------------------------------------------------

/**
 * Checks if the current selection is a connection which can be flipped.
 */
function isConnectionSelectionFlippable(
  activeTool: TOOL,
  connectionInstructions: MatrixInstructions['connections'],
  selectedRegionId: CellValue
): selectedRegionId is number {
  return activeTool === TOOL.Select
    && isConnection(selectedRegionId)
    && getIsConnectionFlippable(connectionInstructions, selectedRegionId);
}

/**
 * Checks if the current selection is a connection which can be flipped.
 */
function isConnectionDrawFlippable(
  activeTool: TOOL,
  activeBrush: Brush,
  connectionInstructions: MatrixInstructions['connections'],
  regionId?: CellValue
): regionId is number {
  return activeTool === TOOL.Draw
    && activeBrush in CONNECTION
    && isConnection(regionId)
    && getIsConnectionFlippable(connectionInstructions, regionId);
}

/**
 * Extracts connection info from connection instructions and checks if the
 * connection is flippable.
 */
function getIsConnectionFlippable(
  connectionInstructions: InteractiveMapState['instructions']['connections'],
  connectionId: number
): boolean {
  const { areaDirections, type } = getMapEntryRequired(connectionInstructions, connectionId);
  const direction = getConnectionDirection(areaDirections);

  return isConnectionFlippable(type, direction);
}
