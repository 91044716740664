import { memo } from 'react';
import { Group, Rect as Rectangle, Text } from 'react-konva';

import {
  cellPx,
  colors,
  embellishmentStrokePx,
  fontFamilyTitle,
  fontSizeMapTitleTiny,
} from '../../../config/map';

// -- Config -------------------------------------------------------------------

/** Scale container height in grid units. */
export const scaleHeight = 2;

/** The height of the scale indicators in pixels. */
const segmentHeight = cellPx / 4;

/** Font size of the scale's unit labels. */
const textSize = cellPx / 2;

/** X offset of the scale's unit labels. */
const textOffsetX = textSize * -0.5;

/** Y offset of the scale's "Feet" label. */
const textFeetLabelOffsetY = fontSizeMapTitleTiny * 1.25;

/** Scale unit label props. */
const textProps = {
  align: 'center',
  fill: colors.label,
  fontFamily: fontFamilyTitle,
  fontSize: textSize,
  fontStyle: '600',
  height: textSize,
  verticalAlign: 'top',
  width: textSize,
  y: textSize * -1.2,
};

// -- Public Component ---------------------------------------------------------

/**
 * Renders the a distance scale embellishment.
 *
 * When the scale is contained an additional segment is rendered to fill up the
 * visual space.
 */
export default memo(function Scale({
  segments,
  x,
  y,
}: {
  segments: 2 | 3;
  x: number;
  y: number;
}) {
  return (
    <Group
      data-id="map-scale"
      x={x}
      y={y}
    >
      <Rectangle
        fill={colors.background}
        height={segmentHeight}
        stroke={colors.border}
        strokeWidth={embellishmentStrokePx}
        width={cellPx}
        x={0}
        y={0}
      />

      <Rectangle
        fill={colors.shadow}
        height={segmentHeight}
        stroke={colors.border}
        strokeWidth={embellishmentStrokePx}
        width={cellPx}
        x={cellPx}
        y={0}
      />

      {segments === 3 &&
        <Rectangle
          fill={colors.background}
          height={segmentHeight}
          stroke={colors.border}
          strokeWidth={embellishmentStrokePx}
          width={cellPx}
          x={cellPx * 2}
          y={0}
        />
      }

      <Text
        {...textProps}
        text="0"
        x={textOffsetX}
      />

      <Text
        {...textProps}
        text="5"
        x={cellPx + textOffsetX}
      />

      <Text
        {...textProps}
        text="10"
        x={(cellPx * 2) + textOffsetX}
      />

      {segments === 3 &&
        <Text
          {...textProps}
          text="15"
          x={(cellPx * 3) + textOffsetX}
        />
      }

      <Text
        align="center"
        fill={colors.label}
        fontFamily={fontFamilyTitle}
        fontSize={fontSizeMapTitleTiny}
        fontStyle="600"
        height={fontSizeMapTitleTiny}
        letterSpacing={2}
        text="Feet"
        width={segments * cellPx}
        x={0}
        y={textFeetLabelOffsetY}
      />
    </Group>
  );
});
