import { memo } from 'react';
import { Group, Rect as Rectangle, Text } from 'react-konva';

import {
  cellPx,
  colors,
  embellishmentBoxInsetPx,
  embellishmentBoxWidthPx,
  fontFamilyTitle,
  fontSizeMapTitleTiny,
  gridStrokePx,
} from '../../../config/map';
import { getMapEntryRequired } from '../../../lib';
import Matrix, {
  AREA,
  areaRegions,
  connections,
  DETAIL,
  details,
  DRAW_OPTION,
} from '../../../lib/matrix';
import { toWords } from '../../../lib/string';
import DetailArt from '../Detail';
import Regions from '../Regions';

import type { Theme } from '../../../lib/map';
import type {
  Detail,
  MatrixInstructions,
  RegionType,
} from '../../../lib/matrix';

// -- Config -------------------------------------------------------------------

/** Seeds for detail art. */
const detailSeeds: Partial<Record<DETAIL, string>> = {
  [DETAIL.Crates]: 'z',
  [DETAIL.Rubble]: 'd',
};

/** Region instructions for all area and connection types. */
const regionInstructions = getRegionInstructions();

/** Detail instructions for all detail types. */
const detailInstructions = getDetailInstructions();

/** Height of each legend entry. */
const entryHeight = cellPx * 2;

/**
 * Amount of space each legend entry is indented from the region container on
 * both the x & y axis.
 */
const entryIndentation = cellPx * 2;

/** Height compensation for the legend container's inset.  */
const heightInset = embellishmentBoxInsetPx * 2;

/** Amount of space each legend entry labels are indented on the x axis. */
const labelIndentation = cellPx * 3.35;

/** Max character count for applying line breaks to labels. */
const maxLabelCharacterBreak = 12;

/** Default props for legend `<Region>` previews. */
const regionEntryProps = {
  areaInfo: {},
  connectionInfo: {},
  showAreaShadows: false,
  showBorderShadows: false,
};

// -- Public Component ---------------------------------------------------------

/**
 * Renders the map legend.
 */
export default memo(Legend, (prev, curr) => {
  if (prev.matrixHeight !== curr.matrixHeight) {
    return false;
  }

  if (prev.availableHeight !== curr.availableHeight) {
    return false;
  }

  if (prev.theme !== curr.theme) {
    return false;
  }

  const { types: prevTypes } = getActiveLegendTypes(prev.instructions);
  const { types: currTypes } = getActiveLegendTypes(curr.instructions);

  if (prevTypes.size !== currTypes.size) {
    return false;
  }

  return [ ...prevTypes ].every((value) => currTypes.has(value));
});

// -- Private Components -------------------------------------------------------

/**
 * Renders the legend.
 */
function Legend({
  availableHeight,
  instructions,
  matrixHeight,
  theme,
}: {
  availableHeight: number;
  instructions: MatrixInstructions;
  matrixHeight: number;
  theme: Theme;
}) {
  const { detailTypes, regionTypes } = getActiveLegendTypes(instructions);

  const items = [
    ...regionInstructions.filter(({ areas: areaInstructions }) => {
      const area = areaInstructions.get(1);
      return area && regionTypes.has(area.type);
    }),
    ...regionInstructions.filter(({ connections: connectionInstructions }) => {
      const connection = connectionInstructions.get(-1);
      return connection && regionTypes.has(connection.type);
    }),
    ...detailInstructions.filter(({ type }) => detailTypes.has(type)),
  ];

  const matrixHeightPx = matrixHeight * cellPx;
  const maxItems = Math.floor(((availableHeight - 1) * cellPx) / entryHeight);
  const displayedItems = items.slice(0, maxItems);
  const hasOverflow = items.length > maxItems;
  const height = (displayedItems.length * entryHeight) + cellPx;
  const topOffset = matrixHeightPx - height - cellPx;

  return (
    <Group
      data-id="map-legend"
      x={0}
      y={topOffset}
    >
      <Rectangle
        data-id="map-embellishment-box"
        fill={colors.background}
        height={height - heightInset}
        stroke={colors.border}
        width={embellishmentBoxWidthPx}
        x={embellishmentBoxInsetPx}
        y={cellPx + embellishmentBoxInsetPx}
      />

      {displayedItems.map((item, i) => (
        'areas' in item
          ? (
            <LegendEntryRegion
              index={i}
              instructions={item}
              key={i}
            />
          ) : (
            <LegendEntryDetail
              index={i}
              item={item}
              key={i}
              theme={theme}
            />
          )
      ))}

      {hasOverflow && (
        <Text
          align="right"
          fill={colors.detailShadow}
          fontFamily={fontFamilyTitle}
          fontSize={fontSizeMapTitleTiny}
          fontStyle="600"
          height={cellPx}
          letterSpacing={2}
          text={`+ ${items.length - displayedItems.length} more...`}
          verticalAlign="bottom"
          width={embellishmentBoxWidthPx}
          x={0}
          y={height - fontSizeMapTitleTiny}
        />
      )}
    </Group>
  );
}

/**
 * Renders a legend entry.
 */
function LegendEntry({
  children,
  index,
  label,
  type,
}: {
  children: React.ReactNode;
  index: number;
  label: string;
  type: DETAIL | RegionType;
}) {
  return (
    <Group
      data-id={`map-legend-${type}`}
      x={cellPx * -1}
      y={index * entryHeight}
    >
      <Rectangle
        fill={colors.regionDungeon}
        height={cellPx}
        stroke={colors.gridLines}
        strokeWidth={gridStrokePx}
        width={cellPx}
        x={entryIndentation}
        y={entryIndentation}
      />

      {children}

      <Text
        align="left"
        fill={colors.label}
        fontFamily={fontFamilyTitle}
        fontSize={fontSizeMapTitleTiny}
        fontStyle="600"
        height={cellPx}
        letterSpacing={2}
        text={label}
        verticalAlign="middle"
        width={embellishmentBoxWidthPx}
        x={labelIndentation}
        y={entryIndentation}
      />
    </Group>
  );
}

/**
 * Renders a detail legend entry.
 */
function LegendEntryDetail({ index, item, theme }: {
  index: number;
  item: Detail;
  theme: Theme;
}) {
  return (
    <LegendEntry
      index={index}
      label={toWords(item.type)}
      type={item.type}
    >
      <DetailArt
        details={[ item ]}
        seed={detailSeeds[item.type] || ''}
        theme={theme}
      />
    </LegendEntry>
  );
}

/**
 * Renders a region legend entry.
 */
function LegendEntryRegion({ index, instructions }: { index: number; instructions: MatrixInstructions }) {
  const { type } = instructions.areas.get(1) || getMapEntryRequired(instructions.connections, -1);

  let label = toWords(type);

  if (label.length > maxLabelCharacterBreak && label.includes(' ')) {
    label = label.replace(/ /g, '\n');
  }

  return (
    <LegendEntry
      index={index}
      label={label}
      type={type}
    >
      <Regions
        theme="classic"
        {...regionEntryProps}
        {...instructions}
      />
    </LegendEntry>
  );
}

// -- Private Functions --------------------------------------------------------

/**
 * Returns sets of detail and region types which should be included in the
 * legend based on the current matrix instructions.
 */
function getActiveLegendTypes(instructions: MatrixInstructions): {
  detailTypes: Set<DETAIL>;
  regionTypes: Set<RegionType>;
  types: Set<DETAIL | RegionType>;
} {
  const detailTypes: DETAIL[] = [];
  const regionTypes: RegionType[] = [];

  if (instructions?.areas.size) {
    for (const area of instructions.areas.values()) {
      regionTypes.push(area.type);

      if (area.details) {
        detailTypes.push(...area.details.map(({ type }) => type));
      }
    }
  }

  if (instructions?.connections.size) {
    for (const connection of instructions.connections.values()) {
      regionTypes.push(connection.type);
    }
  }

  return {
    detailTypes: new Set(detailTypes),
    regionTypes: new Set(regionTypes),
    types: new Set([ ...detailTypes, ...regionTypes ]),
  };
}

/**
 * Creates an array of Details from the detail types.
 */
function getDetailInstructions(): Detail[] {
  return [ ...details ].map((type) => ({
    coordinates: [ 2, 2 ],
    type,
  }));
}

/**
 * Returns an array of region instructions for every region type.
 *
 * A potential micro-optimization would be to draw all regions on a single
 * matrix and then extract the instructions from that matrix.
 */
function getRegionInstructions(): MatrixInstructions[] {
  const instructions = [];

  for (const areaType of areaRegions) {
    const matrix = new Matrix({ entry: { dimensions: [ 5, 4 ] }});

    matrix.draw([[ 2, 2 ]], areaType, DRAW_OPTION.Rectangle);

    instructions.push(matrix.getInstructions());
  }

  for (const connectionType of connections) {
    const matrix = new Matrix({ entry: { dimensions: [ 5, 4 ] }});

    matrix.draw([[ 1, 2 ]], AREA.Dungeon, DRAW_OPTION.Rectangle);
    matrix.draw([[ 2, 2 ]], connectionType, DRAW_OPTION.Rectangle);
    matrix.draw([[ 3, 2 ]], AREA.Dungeon, DRAW_OPTION.Rectangle);

    instructions.push({
      areas: new Map(),
      connections: matrix.getInstructions().connections,
    });
  }

  return instructions;
}
