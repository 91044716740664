import { Typography } from '@mui/material';

import { appVersion, brandName, discordInviteUrl } from '../../config';
import { path } from '../../config/path';
import Link from '../Interface/Link';

import styles from './Footer.module.css';

// -- Config -------------------------------------------------------------------

/** Footer font size. */
const fontSize = '0.825rem';

/** Current year. */
const year = new Date().getFullYear();

// -- Public Component ---------------------------------------------------------

/**
 * Renders the application footer bar.
 */
export default function Footer({ className }: { className: string }) {
  return (
    <footer
      className={className}
      role="contentinfo"
    >
      <Typography
        component="small"
        fontSize={fontSize}
      >
        <span>© {brandName}, {year}</span>
        <Separator />
        <span>Beta v{appVersion}</span>
      </Typography>

      <Typography
        component="nav"
        display="flex"
        fontSize={fontSize}
      >
        <Link to={path.support}>
          Support{' '}
          <span className={styles.supportExtra}>
            the Generator{' '}
            <span className={styles.heartEmoji}>❤️</span>
          </span>
        </Link>

        <Separator />

        <Link href={discordInviteUrl}>
          Discord
        </Link>

        <Separator />

        <Link to={path.releaseNotes}>
          Release Notes
        </Link>

        <Separator />

        <Link to={path.roadmap}>
          Roadmap
        </Link>

        <Separator />

        <Link to={path.about}>
          About
        </Link>

        <Separator />

        <Link href="https://blog.mysticwaffle.com">
          Blog
        </Link>

        <Separator />

        <Link to={path.privacy}>
          Privacy
        </Link>
      </Typography>
    </footer>
  );
}

// -- Private Components -------------------------------------------------------

/** Renders a nav separator. */
function Separator() {
  return (
    <span aria-hidden data-separator>•</span>
  );
}
