import React from 'react';

import { appVersion } from '../../config';
import AlertDialog from '../Interface/AlertDialog';

// -- Types --------------------------------------------------------------------

interface ErrorBoundaryProps {
  children?: React.ReactNode;
}

interface ErrorBoundaryState {
  hasError: boolean;
}

// -- Config -------------------------------------------------------------------

/** URL to post error logs to. */
const logUrl = `${globalThis.window.location.origin}/logs/js-error.php`;

/** Build manifest for extracting bundle JS filepath. */
const manifestUrl = `${globalThis.window.location.origin}/.vite/manifest.json`;

// -- Public Component ---------------------------------------------------------

/**
 * Application error boundary.
 *
 * To test simulating error UI & logging:
 *
 * - In React: Call `simulateError()` in the console. See `<ErrorSimulator />`.
 * - In the browser: Run the following in the console:
 *
 * @example
 *
 * globalThis.document.body.addEventListener('click', () => {
 *   throw new Error('Simulated window error');
 * });
 *
 * globalThis.document.body.click();
 */
export default class ErrorBoundary extends React.Component<ErrorBoundaryProps, ErrorBoundaryState> {
  public state: ErrorBoundaryState = { hasError: false };

  /** Get derived state from error */
  static getDerivedStateFromError(): ErrorBoundaryState {
    return { hasError: true };
  }

  /** Handle error */
  handleWindowError = (event: ErrorEvent) => {
    this.setState({ hasError: true });

    const { colno, filename, lineno, message } = event;

    void logError({ colno, filename, lineno, message });
  };

  /** Mount */
  componentDidMount() {
    globalThis.window.addEventListener('error', this.handleWindowError);
  }

  /** Unmount */
  componentWillUnmount() {
    globalThis.window.removeEventListener('error', this.handleWindowError);
  }

  /** Catch & log error */
  componentDidCatch({ message, stack }: Error) {
    void logError({ message, stack });
  }

  /** Render */
  render() {
    const { children } = this.props;
    const { hasError } = this.state;

    if (hasError) {
      return (
        <AlertDialog
          confirmLabel="Reload"
          isOpen
          onConfirm={() => globalThis.window.location.reload()}
          title="Oh no!"
        >
          <p>
            Goblins have infiltrated the castle and hacked into the JavaScript!
          </p>

          <p>
            This error has been scribbled on a magical scroll by a preposterous robot so AJ can fix this bug.
          </p>
        </AlertDialog>
      );
    }

    return children;
  }
}

// -- Private Functions --------------------------------------------------------

/**
 * Logs an error to the server.
 */
async function logError(info: Record<string, number | string | undefined>) {
  try {
    const manifest = await globalThis.fetch(manifestUrl);
    const manifestJson = await manifest.json() as {
      'index.html': { file: string };
    };

    const response = await globalThis.fetch(logUrl, {
      body: JSON.stringify({
        agent: globalThis.window.navigator.userAgent,
        file: manifestJson?.['index.html']?.file,
        time: Date.now(),
        url: globalThis.window.location.href,
        version: appVersion,
        ...info,
      }),
      headers: {
        Accept: 'application/json',
        'Content-Type': 'text/plain',
      },
      method: 'POST',
    });

    const { status = 'unknown' } = await response.json() as { status: string };

    console.log('Log status: ' + status); // eslint-disable-line no-console
  } catch (error) {
    console.log('Log status: failure'); // eslint-disable-line no-console
    console.error(error);
  }
}
