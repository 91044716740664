import { FormControl, TextField } from '@mui/material';

import WidowFix from '../../Display/WidowFix';
import Link from '../Link';

// -- Config -------------------------------------------------------------------

/** Link to wikipedia's "Random seed" article. */
const randomSeedWikipediaHref = 'https://wikipedia.org/wiki/Random_seed';

// -- Public Component ---------------------------------------------------------

/**
 * Renders the seed input used in the advanced settings section of a generator's
 * settings panel.
 */
export default function SeedInput({
  onSeedChange,
  seed = '',
  showInfo,
}: {
  onSeedChange: (newSeed: string) => void;
  seed?: string;
  showInfo: boolean;
}) {
  return (
    <FormControl>
      <TextField
        helperText={showInfo ? <InfoText /> : undefined}
        label="Seed"
        name="seed"
        onChange={(e) => onSeedChange(e.target.value)}
        value={seed}
      />
    </FormControl>
  );
}

// -- Private Components -------------------------------------------------------

/**
 * Seed input help text.
 */
function InfoText() {
  return (
    <>
      Enter a <WikipediaSeedLink /> for repeatable randomization.{' '}
      <WidowFix>
        Otherwise a random seed will be used by default.
        As content and functionality is added, noted by the version number in
        the footer, results for the same seed may differ.
      </WidowFix>
    </>
  );
}

/**
 * Seed input help text.
 */
function WikipediaSeedLink() {
  return (
    <Link href={randomSeedWikipediaHref}>
      seed
    </Link>
  );
}
