import { useEffect, useState } from 'react';

import { AREA, Brush, brushes, CONNECTION, DETAIL, TOOL } from '../../../lib/matrix';

// -- Config -------------------------------------------------------------------

/** Input tag names which ignore map keydown events when focused. */
const inputMatchers = [ 'input', 'textarea' ].join(', ');

/** Available areas in the brush palette. */
const areaBrushes = Object.values(AREA);

/** The first area in the area palette. */
const [ firstArea ] = areaBrushes;

/** Available connections in the brush palette. */
const connectionBrushes = Object.values(CONNECTION);

/** The first connection in the connection palette. */
const [ firstConnection ] = connectionBrushes;

/** Available details in the brush palette. */
const detailBrushes = Object.values(DETAIL);

/** The first detail in the detail palette. */
const [ firstDetail ] = detailBrushes;

// -- Public Hook --------------------------------------------------------------

/**
 * Handles map keyboard events.
 *
 * TDL delete selected
 */
export default function useMapKeyboard({
  activeBrush,
  activeTool,
  isMouseDown,
  onCancel,
  onChangeBrush,
  onChangeTool,
  onMouseLeave,
  onRedo,
  onResetView,
  onRotate,
  onUndo,
  onZoom,
}: {
  activeBrush: Brush;
  activeTool: TOOL;
  isMouseDown: boolean;
  onCancel: () => void;
  onChangeBrush: (brush: Brush) => void;
  onChangeTool: (tool: TOOL) => void;
  onMouseLeave: () => void;
  onRedo?: () => void;
  onResetView: () => void;
  onRotate: () => void;
  onUndo?: () => void;
  onZoom: (zoom: 'in' | 'out') => void;
}) {
  const [ previousTool, setPreviousTool ] = useState(activeTool);

  useEffect(() => {

    /** Handles key down events. */
    function onKeyDown(event: KeyboardEvent) {
      event.stopImmediatePropagation();

      const {
        ctrlKey,
        key,
        metaKey,
        repeat,
        shiftKey,
        target,
      } = event;

      if (target instanceof Element && target.matches(inputMatchers)) {
        return;
      }

      // -- Cancel -------------------------------------------------------------

      if (key === 'Escape') {
        onCancel();
        return;
      }

      if (isMouseDown) {
        return;
      }

      // -- Zoom ---------------------------------------------------------------

      if (key === '=' || key === '+') {
        onZoom('in');
        return;
      }

      if (key === '-') {
        onZoom('out');
        return;
      }

      if (repeat) {
        return;
      }

      if (key === 'f') {
        onResetView();
        return;
      }

      // -- Tools --------------------------------------------------------------

      if (key === 'd') {
        onChangeTool(TOOL.Draw);
        return;
      }

      if (key === 'e') {
        onChangeTool(TOOL.Erase);
        return;
      }

      if (key === 's') {
        onChangeTool(TOOL.Select);
        return;
      }

      if (key === 'v') {
        onChangeTool(TOOL.Pan);
        return;
      }

      if (key === ' ') {
        setPreviousTool(activeTool);
        onChangeTool(TOOL.Pan);
        return;
      }

      // -- Rotate -------------------------------------------------------------

      if (key === 'r') {
        onRotate();
        return;
      }

      // -- Brushes ------------------------------------------------------------

      if (key === 'a') {
        // TDL cycle area brushes on 'a' key once there are more area brushes.
        onChangeBrush(firstArea);
        return;
      }

      if (key === 'c') {
        const brush = activeTool === TOOL.Draw && activeBrush in CONNECTION
          ? getNextBrush(connectionBrushes, activeBrush)
          : firstConnection;

        onChangeBrush(brush);
        return;
      }

      if (key === 'x') {
        const brush = activeTool === TOOL.Draw && activeBrush in DETAIL
          ? getNextBrush(detailBrushes, activeBrush)
          : firstDetail;

        onChangeBrush(brush);
        return;
      }

      // -- Brush Navigation ---------------------------------------------------

      if ((key === 'ArrowDown' || key === 'ArrowUp') && activeTool !== TOOL.Draw) {
        onChangeBrush(brushes[0]);
        return;
      }

      if (key === 'ArrowDown') {
        onChangeBrush(getNextBrush(brushes, activeBrush));
        return;
      }

      if (key === 'ArrowUp') {
        onChangeBrush(getPreviousBrush(brushes, activeBrush));
        return;
      }

      // -- Undo/Redo ----------------------------------------------------------

      if (activeTool !== TOOL.Select) {
        if (key === 'z' && (ctrlKey || metaKey) && shiftKey) {
          onRedo?.();
          return;
        }

        if (key === 'z' && (ctrlKey || metaKey)) {
          onUndo?.();
          return;
        }
      }
    }

    /** Handles key up events. */
    function onKeyUp(event: KeyboardEvent) {
      if (event.target instanceof Element && event.target.matches(inputMatchers)) {
        return;
      }

      if (event.repeat) {
        return;
      }

      if (event.key === ' ') {
        if (isMouseDown) {
          onMouseLeave();
        }

        onChangeTool(previousTool);
      }
    }

    globalThis.document.addEventListener('keydown', onKeyDown);
    globalThis.document.addEventListener('keyup', onKeyUp);

    return () => {
      globalThis.document.removeEventListener('keydown', onKeyDown);
      globalThis.document.removeEventListener('keyup', onKeyUp);
    };
  }, [
    activeBrush,
    activeTool,
    isMouseDown,
    onCancel,
    onChangeBrush,
    onChangeTool,
    onMouseLeave,
    onRedo,
    onResetView,
    onRotate,
    onUndo,
    onZoom,
    previousTool,
  ]);
}

// -- Private Functions --------------------------------------------------------

/**
 * Returns the next brush in the set when stepping through the entire brush
 * pallette or a subset of the brush palette.
 */
function getNextBrush(brushGroup: Brush[] | readonly Brush[], activeBrush: Brush): Brush {
  const index = brushGroup.indexOf(activeBrush);

  return index === brushGroup.length - 1
    ? brushGroup[0]
    : brushGroup[index + 1];
}

/**
 * Returns the previous brush in the set when stepping through the entire brush
 * pallette or a subset of the brush palette.
 */
function getPreviousBrush(brushGroup: Brush[] | readonly Brush[], activeBrush: Brush): Brush {
  const index = brushGroup.indexOf(activeBrush);

  return index === 0
    ? brushGroup[brushGroup.length - 1]
    : brushGroup[index - 1];
}
