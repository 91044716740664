import { memo } from 'react';
import { Text } from 'react-konva';

import {
  cellPx,
  colors,
  fontFamilyTitle,
  fontSizeMapTitleLarge,
  fontSizeMapTitleMedium,
  fontSizeMapTitleSmall,
} from '../../../config/map';

import type { MatrixInstructionsArea } from '../../../lib/matrix';

// -- Config -------------------------------------------------------------------

/** Minimum dimension for a rect to display a numerical label. */
const areaIdLabelRectMinDimensions = 3;

// -- Public Component (Memoized) ----------------------------------------------

export default memo(AreaLabel, (oldProps, newProps) => {
  return Object.values(oldProps.rect).join(',') === Object.values(newProps.rect).join(',')
    && oldProps.showAreaNumbers === newProps.showAreaNumbers
    && oldProps.number === newProps.number
    && oldProps?.title === newProps?.title;
});

// -- Private Components -------------------------------------------------------

/**
 * Renders an area label.
 */
function AreaLabel({
  number,
  rect,
  showAreaNumbers = true,
  title,
}: NonNullable<MatrixInstructionsArea['label']> & {
  showAreaNumbers?: boolean;
  title?: string;
}) {
  const label = getLabel(number, showAreaNumbers, title);

  if (!label) {
    return null;
  }

  const { height, width, x, y } = rect;

  const fontSize = getFontSize(width, height, title);

  return (
    <Text
      align="center"
      fill={colors.label}
      fontFamily={fontFamilyTitle}
      fontSize={fontSize}
      fontStyle="400"
      height={height * cellPx}
      text={label}
      verticalAlign="middle"
      width={width * cellPx}
      wrap="word"
      x={x * cellPx}
      y={y * cellPx}
    />
  );
}

// -- Private Functions --------------------------------------------------------

/** Returns title font size. */
function getFontSize(width: number, height: number, title?: string) {
  if (width < areaIdLabelRectMinDimensions || height < areaIdLabelRectMinDimensions) {
    return fontSizeMapTitleSmall;
  }

  if (title) {
    return fontSizeMapTitleMedium;
  }

  return fontSizeMapTitleLarge;
}

/** Returns the area label */
function getLabel(number: number, showAreaNumbers?: boolean, title?: string): string | null {
  if (!showAreaNumbers && !title?.trim()) {
    return null;
  }

  if (showAreaNumbers && !title) {
    return number.toString();
  }

  if (!showAreaNumbers && title) {
    return title;
  }

  return `${number}. ${title}`;
}
