import { memo } from 'react';

import Background from './Background';
import Embellishment from './Embellishment';
import GridLines from './GridLines';
import Regions from './Regions';

import type { MapInfo } from '../../lib/map';
import type { InteractiveMapState } from './hooks/useInteractiveMap';

// -- Types --------------------------------------------------------------------

export type ArtworkProps = MapInfo & Pick<InteractiveMapState, 'instructions'
  | 'matrixHeight'
  | 'matrixWidth'
  | 'title'
>;

// -- Public Component ---------------------------------------------------------

/**
 * Renders map artwork.
 *
 * Note: Must be wrapped in a konva `<Layer>`. Moving `<Layer>` into this
 * component causes a Konva error when reading the map into a data string for
 * image downloads.
 */
export default memo(function Artwork({
  areaInfo,
  connectionInfo,
  instructions,
  matrixHeight,
  matrixWidth,
  showAreaNumbers,
  showCompass,
  showLegend,
  showScale,
  theme,
  title,
}: ArtworkProps) {
  return (
    <>
      <Background height={matrixHeight} width={matrixWidth} />

      <Regions
        areaInfo={areaInfo}
        connectionInfo={connectionInfo}
        showAreaNumbers={showAreaNumbers}
        theme={theme}
        {...instructions}
      />

      <GridLines height={matrixHeight} width={matrixWidth} />

      <Embellishment
        instructions={instructions}
        matrixHeight={matrixHeight}
        matrixWidth={matrixWidth}
        showCompass={showCompass}
        showLegend={showLegend}
        showScale={showScale}
        theme={theme}
        title={title}
      />
    </>
  );
});
