import { memo } from 'react';

import { cellPx } from '../../../config/map';
import {
  CONNECTION,
  connectionDirectionsMeridian,
  connectionsLockable,
} from '../../../lib/matrix';
import {
  getBoundingRect,
  getConnectionDirection,
} from '../../../lib/matrix/utility';
import Doorway from './Doorway';
import Lock from './Lock';
import Passageway from './Passageway';
import SecretDoor from './SecretDoor';
import SecretPassageway from './SecretPassageway';
import Stairway from './Stairway';

import type { RegionConnectionInfo } from '../../../lib/map';
import type {
  ConnectionDirection,
  MatrixInstructionsConnection,
} from '../../../lib/matrix';

// -- Types --------------------------------------------------------------------

export type ConnectionProps = Pick<RegionConnectionInfo, 'flipped'> & {
  direction: ConnectionDirection;
  heightPx: number;
  widthPx: number;
  xPx: number;
  yPx: number;
};

// -- Config -------------------------------------------------------------------

/** Connection types with no details. */
const emptyConnectionTypes: Set<CONNECTION> = new Set([
  CONNECTION.Connection,
]);

// -- Public Component (Memoized) ----------------------------------------------

/**
 * Renders a single connection's details, if any.
 */
export default memo(Connection, (prev, curr) => {
  return prev.coordinates.join() === curr.coordinates.join()
    && [ ...prev.areaDirections.entries() ].join(':') === [ ...curr.areaDirections.entries() ].join(':')
    && prev.type === curr.type
    && prev.locked === curr.locked
    && prev.flipped === curr.flipped;
});

// -- Public Component ---------------------------------------------------------

/**
 * Renders connection details.
 */
function Connection({
  areaDirections,
  coordinates,
  flipped,
  locked,
  type,
}: MatrixInstructionsConnection & RegionConnectionInfo) {
  if (emptyConnectionTypes.has(type)) {
    return null;
  }

  const { height, width, x, y } = getBoundingRect(coordinates);
  const direction = getConnectionDirection(areaDirections);

  const widthPx = width * cellPx;
  const heightPx = height * cellPx;

  const xPx = x * cellPx;
  const yPx = y * cellPx;

  return (
    <>
      <ConnectionSwitch
        direction={direction}
        flipped={flipped}
        heightPx={heightPx}
        type={type}
        widthPx={widthPx}
        xPx={xPx}
        yPx={yPx}
      />

      {locked && connectionsLockable.has(type) &&
        <LockIndicator
          direction={direction}
          xPx={xPx}
          yPx={yPx}
        />
      }
    </>
  );
}

// -- Private Components -------------------------------------------------------

/**
 * Switches connection rendering.
 */
function ConnectionSwitch({
  type,
  ...props
}: ConnectionProps & {
  type: CONNECTION;
}) {
  switch (type) {
    case CONNECTION.Passageway:
      return <Passageway {...props} />;

    case CONNECTION.SecretDoor:
      return <SecretDoor {...props} />;

    case CONNECTION.SecretPassageway:
      return <SecretPassageway {...props} />;

    case CONNECTION.Stairway:
      return <Stairway {...props} />;

    case CONNECTION.WoodDoor:
      return <Doorway {...props} />;

    default:
      throw new TypeError(`Invalid connection type "${type}" in <Connection>`);
  }
}

/**
 * Renders a lock indicator offset from the connection.
 */
function LockIndicator({ direction, xPx, yPx }: Pick<ConnectionProps, 'direction' | 'xPx' | 'yPx'>) {
  const isMeridian = connectionDirectionsMeridian.has(direction);
  const offsetX = isMeridian ? -cellPx : 0;
  const offsetY = isMeridian ? 0 : -cellPx;

  return (
    <Lock
      xPx={xPx + offsetX}
      yPx={yPx + offsetY}
    />
  );
}
