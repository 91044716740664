import {
  connectionDirectionsMeridian,
  connectionMaxSpan,
  directionsCardinal,
} from './';
import {
  getAdjacentCells,
  getCellValueWeak,
  getConnectionCellDirection,
  getCoordinatesInDirection,
  getMatrixDimensions,
  getOppositeDirectionCardinal,
  getRectangleCoordinates,
  isArea,
  isBoundary,
  isConnection,
  toCoordinatesMap,
} from './utility';

import type {
  AdjacentCellMap,
  ConnectionDirection,
  Coordinates,
  CoordinatesMap,
  DirectionCardinal,
  MatrixImmutable,
} from './';

// -- Public Functions ---------------------------------------------------------

/**
 * Returns a coordinates map containing valid area cells for an area draw.
 */
export function filterAreaDraw(
  matrix: MatrixImmutable,
  coordinates: Coordinates[]
): CoordinatesMap {
  const cellMap: CoordinatesMap = new Map();
  const dimensions = getMatrixDimensions(matrix);

  for (const cellCoordinates of coordinates) {
    if (isBoundary(dimensions, cellCoordinates)) {
      continue;
    }

    const cellValue = getCellValueWeak(matrix, cellCoordinates);

    if (isArea(cellValue) || typeof cellValue === 'undefined') {
      continue;
    }

    const [ x, y ] = cellCoordinates;

    cellMap.set(`${x},${y}`, cellCoordinates);
  }

  return cellMap;
}

/**
 * Returns an array of valid connection coordinates for a connection draw and
 * optional start and end coordinates for a rectangular area draw based on the
 * remainder of the draw.
 */
export function filterConnectionDraw(
  matrix: MatrixImmutable,
  coordinates: Coordinates[]
): { area: Coordinates[]; connection: Coordinates[] } {
  if (!coordinates.length) {
    return {
      area: [],
      connection: [],
    };
  }

  if (coordinates.length > 2) {
    throw new RangeError(`Invalid rectangle draw of "${coordinates.length}" coordinates in getConnectionSlice)(), expected 1 or 2 in filterConnectionDraw()`);
  }

  const [ originCoordinates ] = coordinates;
  const value = getCellValueWeak(matrix, originCoordinates);

  // TDL replace or allow adjacent connection
  // TDL add interior connections
  if (isConnection(value)) {
    throw new Error('Invalid connection origin cell for connection draw in filterConnectionDraw()');
  }

  if (isArea(value)) {
    return {
      area: [],
      connection: filterInteriorConnectionDraw(matrix, originCoordinates),
    };
  }

  const connectionCoordinates = filterExteriorConnectionDraw(
    matrix,
    getConnectionSlice(matrix, coordinates)
  );

  return {
    area: getConnectionAreaDraw(matrix, coordinates, connectionCoordinates),
    connection: connectionCoordinates,
  };
}

/**
 * Returns an array of valid detail coordinates for a detail draw.
 */
export function filterDetailDraw(
  matrix: MatrixImmutable,
  coordinates: Coordinates[]
): Coordinates[] {
  const cells: Coordinates[] = [];

  for (const cellCoordinates of coordinates) {
    if (isArea(getCellValueWeak(matrix, cellCoordinates))) {
      cells.push(cellCoordinates);
    }
  }

  return cells;
}

/**
 * Returns an array of valid coordinates which can be erased.
 */
export function filterErase(
  matrix: MatrixImmutable,
  coordinates: Coordinates[]
): Coordinates[] {
  const cells: Coordinates[] = [];

  for (const cellCoordinates of coordinates) {
    const cellValue = getCellValueWeak(matrix, cellCoordinates);

    if (cellValue) {
      cells.push(cellCoordinates);
    }
  }

  return cells;
}

// -- Private Functions --------------------------------------------------------

/**
 * Returns valid cell coordinates and coordinate keys for a connection draw
 * originating from an empty cell.
 */
function filterExteriorConnectionDraw(
  matrix: MatrixImmutable,
  coordinates: Coordinates[]
): Coordinates[] {
  const cells: Coordinates[] = [];

  const [ originCoordinates ] = coordinates;
  const connectionDirection = getConnectionCellDirection(matrix, originCoordinates);

  if (!connectionDirection) {
    return cells;
  }

  const drawDirection = getConnectionDrawDirection(connectionDirection, coordinates);
  const oppositeDirection = getOppositeDirectionCardinal(drawDirection);

  // Check adjacent connection cell span in the opposite direction of the draw.

  let connectionSpan = getConnectionCellCount(
    matrix,
    originCoordinates,
    oppositeDirection
  );

  const isOppositeCellConflicting = isConflictingConnectionCell(
    matrix,
    getCoordinatesInDirection(originCoordinates, oppositeDirection),
    connectionDirection
  );

  if (isOppositeCellConflicting) {
    // Opposite connection cell has an inconsistent connection direction.
    return cells;
  }

  const isMeridian = connectionDirectionsMeridian.has(connectionDirection);
  const parallelDirections: DirectionCardinal[] = isMeridian
    ? [ 'north', 'south' ]
    : [ 'east', 'west' ];

  // Iterate the connection draw, checking for connection consistency and
  // aggregate adjacent connection cell spans in the draw direction.

  for (const cellCoordinates of coordinates.slice(0, connectionMaxSpan)) {
    if (getConnectionCellDirection(matrix, cellCoordinates) !== connectionDirection) {
      // Cell has an inconsistent connection direction.
      return cells;
    }

    for (const direction of parallelDirections) {
      const isParallelConflicting = isConflictingConnectionCell(
        matrix,
        getCoordinatesInDirection(cellCoordinates, direction),
        connectionDirection
      );

      if (isParallelConflicting) {
        return cells;
      }
    }

    const isNextCellConflicting = isConflictingConnectionCell(
      matrix,
      getCoordinatesInDirection(cellCoordinates, drawDirection),
      connectionDirection
    );

    if (isNextCellConflicting) {
      // Next connection cell has an inconsistent connection direction.
      return cells;
    }

    const connectionSpanInDirection = getConnectionCellCount(
      matrix,
      cellCoordinates,
      drawDirection
    );

    if ((connectionSpan + connectionSpanInDirection) >= connectionMaxSpan) {
      // Drawing the next connection cell would exceed the max connection span.
      return cells;
    }

    connectionSpan++;

    // Exclude existing connection cells, they will be merged after the draw.
    if (!isConnection(getCellValueWeak(matrix, cellCoordinates))) {
      cells.push(cellCoordinates);
    }
  }

  return cells;
}

/**
 * Returns valid cell coordinates and coordinate keys for a connection draw
 * originating from an area cell.
 */
function filterInteriorConnectionDraw(
  matrix: MatrixImmutable,
  coordinates: Coordinates
): Coordinates[] {
  const adjacentOriginCells = getAdjacentCells(matrix, coordinates);

  let isAreaAdjacent = false;
  let isConnectionAdjacent = false;

  for (const { value } of adjacentOriginCells.values()) {
    if (isArea(value)) {
      isAreaAdjacent = true;
    } else if (isConnection(value)) {
      isConnectionAdjacent = true;
    }
  }

  if (isConnectionAdjacent || !isAreaAdjacent) {
    // Connection origin can not be adjacent to a connection cell.
    // Connection origin must be adjacent to an area cell.
    return [];
  }

  const cellsInDirections: {
    eastWest: Coordinates[] | null;
    northSouth: Coordinates[] | null;
  } = {
    eastWest: [ coordinates ],
    northSouth: [ coordinates ],
  };

  for (const lineDirection of directionsCardinal) {
    const key: keyof typeof cellsInDirections = lineDirection === 'north' || lineDirection === 'south'
      ? 'northSouth'
      : 'eastWest';

    const validCellsInDirection = cellsInDirections[key];

    if (validCellsInDirection === null) {
      // Connection has been invalidated in this direction.
      continue;
    }

    for (let i = 1; i <= connectionMaxSpan; i++) {
      const coordinatesToCheck = getCoordinatesInDirection(coordinates, lineDirection, i);
      const value = getCellValueWeak(matrix, coordinatesToCheck);

      if (!isArea(value)) {
        if (isConnection(value)) {
          // Connection found in this direction, invalidate the direction.
          cellsInDirections[key] = null;
        }

        // Edge of area reached.
        break;
      }

      if (validCellsInDirection.length === connectionMaxSpan) {
        // Max connection span exceeded for direction, invalidate
        // the direction.
        cellsInDirections[key] = null;
        break;
      }

      const directionsToCheck: DirectionCardinal[] = key === 'northSouth'
        ? [ 'east', 'west' ]
        : [ 'north', 'south' ];

      const haConsistentAdjacentCells = isConsistentAdjacentConnectionCells(
        matrix,
        coordinatesToCheck,
        directionsToCheck,
        adjacentOriginCells
      );

      if (!haConsistentAdjacentCells) {
        // Perpendicular cells have inconsistent values. Connections must either
        // be consistently inside an area or adjacent to an edge.
        cellsInDirections[key] = null;
        break;
      }

      // Valid connection cell found in this direction.
      validCellsInDirection.push(coordinatesToCheck);
    }
  }

  const { eastWest, northSouth } = cellsInDirections;

  if (northSouth && eastWest) {
    if (northSouth.length < eastWest.length) {
      return northSouth;
    }

    return eastWest;
  }

  if (northSouth) {
    return northSouth;
  }

  if (eastWest) {
    return eastWest;
  }

  return [];
}

/**
 * Calculates and returns the start and end rectangular coordinates of an area
 * which can be extracted from the remainder of connection draw. The area is
 * bound to the connection's min & max width or height depending on the
 * direction of the connection.
 */
function getConnectionAreaDraw(
  matrix: MatrixImmutable,
  coordinates: Coordinates[],
  connection: Coordinates[]
): Coordinates[] {
  if (coordinates.length < 2 || !connection.length) {
    return [];
  }

  const { 0: connectionStart, [connection.length - 1]: connectionEnd } = connection;
  const connectionDirection = getConnectionCellDirection(matrix, connectionStart);

  if (!connectionDirection || connectionDirection === 'north-south' || connectionDirection === 'east-west') {
    return [];
  }

  const [ cx1, cy1 ] = connectionStart;
  const [ cx2, cy2 ] = connectionEnd;

  const [ , [ dx2, dy2 ]] = coordinates;

  switch (connectionDirection) {
    case 'north':
    case 'south': {
      const offset = connectionDirection === 'north' ? 1 : -1;
      const condition = connectionDirection === 'north'
        ? cy1 >= dy2
        : cy1 <= dy2;

      return condition
        ? []
        : [ ...toCoordinatesMap([[ cx1, cy1 + offset ], [ cx2, dy2 ]]).values() ];
    }

    case 'east':
    case 'west': {
      const offset = connectionDirection === 'east' ? -1 : 1;
      const condition = connectionDirection === 'east'
        ? cx1 <= dx2
        : cx1 >= dx2;

      return condition
        ? []
        : [ ...toCoordinatesMap([[ cx1 + offset, cy1 ], [ dx2, cy2 ]]).values() ];
    }
  }
}

/**
 * Scans for adjacent connection cells in the given direction returning a count.
 */
function getConnectionCellCount(
  matrix: MatrixImmutable,
  coordinates: Coordinates,
  direction: DirectionCardinal
): number {
  let count = 0;

  for (let i = 1; i <= connectionMaxSpan; i++) {
    const coordinatesToCheck = getCoordinatesInDirection(coordinates, direction, i);
    const cellValue = getCellValueWeak(matrix, coordinatesToCheck);

    if (!isConnection(cellValue)) {
      break;
    }

    count++;
  }

  return count;
}

/**
 * Calculates the direction of a connection draw based on the given connection
 * direction and starting coordinates.
 */
function getConnectionDrawDirection(
  connectionDirection: ConnectionDirection,
  coordinates: Coordinates[]
): DirectionCardinal {
  const isMeridian = connectionDirectionsMeridian.has(connectionDirection);

  if (coordinates.length === 1) {
    // Default draw direction for a single connection cell draw.
    return isMeridian ? 'east' : 'south';
  }

  const [[ x1, y1 ], [ x2, y2 ]] = coordinates;

  if (isMeridian) {
    return x1 < x2 ? 'east' : 'west';
  }

  return y1 < y2 ? 'south' : 'north';
}

/**
 * Returns a single cell column or row slice of a rectangular connection draw
 * based on the first cell's connection direction.
 */
function getConnectionSlice(
  matrix: MatrixImmutable,
  coordinates: Coordinates[]
): Coordinates[] {
  const [ start, end ] = coordinates;
  const connectionDirection = getConnectionCellDirection(matrix, start);

  if (coordinates.length < 2 || !connectionDirection) {
    return coordinates;
  }

  const [ x1, y1 ] = start;
  const [ x2, y2 ] = end;

  return connectionDirectionsMeridian.has(connectionDirection)
    ? getRectangleCoordinates(start, [ x2, y1 ])
    : getRectangleCoordinates(start, [ x1, y2 ]);
}

/**
 * Returns true if the given coordinates is a connection cell with a conflicting
 * connection direction.
 */
function isConflictingConnectionCell(
  matrix: MatrixImmutable,
  coordinates: Coordinates,
  connectionDirection: ConnectionDirection
): boolean {
  if (isConnection(getCellValueWeak(matrix, coordinates))) {
    if (getConnectionCellDirection(matrix, coordinates) !== connectionDirection) {
      return true;
    }
  }

  return false;
}

/**
 * Validates if the cells adjacent to the given coordinates in the given
 * directions have consistent values (area id or null) with the adjacent cell
 * comparison map.
 */
function isConsistentAdjacentConnectionCells(
  matrix: MatrixImmutable,
  coordinates: Coordinates,
  directions: DirectionCardinal[],
  compare: AdjacentCellMap
): boolean {
  for (const direction of directions) {
    const coordinatesToCheck = getCoordinatesInDirection(coordinates, direction);
    const adjacentCellValue = getCellValueWeak(matrix, coordinatesToCheck);

    if (adjacentCellValue !== compare.get(direction)?.value) {
      return false;
    }
  }

  return true;
}
