import { memo } from 'react';
import { Group } from 'react-konva';

import { CONNECTION, directionsCardinal } from '../../../lib/matrix';
import ExteriorIndicator from './ExteriorIndicator';
import ExteriorLineCaps from './ExteriorLineCaps';

import type { DirectionCardinal } from '../../../lib/matrix';
import type { ConnectionProps } from './';

// -- Public Component (Memoized) ----------------------------------------------

/**
 * Renders an exterior passageway connection indicator.
 */
export default memo(function Passageway(props: ConnectionProps) {
  const isExterior = directionsCardinal.has(props.direction as DirectionCardinal);

  if (!isExterior) {
    return null;
  }

  const { xPx, yPx } = props;

  return (
    <Group
      data-id={CONNECTION.Passageway}
      x={xPx}
      y={yPx}
    >
      <ExteriorLineCaps {...props} />
      <ExteriorIndicator {...props} />
    </Group>
  );
});
