import { useCallback, useState } from 'react';

import { getCellValueWeak } from '../../../lib/matrix/utility';

import type { CellValue, Coordinates } from '../../../lib/matrix';
import type { InteractiveMapState } from './useInteractiveMap';

// -- Types --------------------------------------------------------------------

export type OnSelect = (coordinates?: Coordinates) => void;

// -- Public Hook --------------------------------------------------------------

/**
 * Handles interactive map selection events and state.
 */
export default function useMapSelection(matrix: InteractiveMapState['matrix']): {
  onSelect: OnSelect;
  selectedRegionId: CellValue;
} {
  const [ selectedRegionId, setSelectedRegionId ] = useState<CellValue>(null);

  /** Callback for selecting map regions and details. */
  const onSelect = useCallback((coordinates?: Coordinates) => {
    if (typeof coordinates === 'undefined') {
      setSelectedRegionId(null);
      return;
    }

    const cellValue = getCellValueWeak(matrix, coordinates);

    if (cellValue === null || typeof cellValue === 'undefined') {
      setSelectedRegionId(null);
      return;
    }

    if (cellValue === selectedRegionId) {
      setSelectedRegionId(null);
      return;
    }

    setSelectedRegionId(cellValue);
  }, [ matrix, selectedRegionId ]);

  return {
    onSelect,
    selectedRegionId,
  };
}
