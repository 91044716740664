import { useCallback, useReducer } from 'react';

import type {
  MapInfo,
  MapSnapshot,
  RegionAreaInfo,
  RegionConnectionInfo,
  Theme,
} from '../../../lib/map';

// -- Types --------------------------------------------------------------------

type ActionFlipConnection = {
  connectionId: number;
  type: 'flipConnection';
};

type ActionSetAreaInfo = RegionAreaInfo & {
  areaId: number;
  type: 'setAreaInfo';
};

type ActionSetConnectionInfo = RegionConnectionInfo & {
  connectionId: number;
  type: 'setConnectionInfo';
};

type ActionSetShow = {
  show: boolean;
  type: 'setShowAreaNumbers'
    | 'setShowCompass'
    | 'setShowLegend'
    | 'setShowScale';
};

interface ActionSetTheme {
  theme: Theme;
  type: 'setTheme';
}

export interface MapInfoState {

  /** Map info state. */
  mapInfo: MapInfo;

  /** Callback which flips a specific connection. */
  onFlipConnection: (connectionId: number) => void;

  /** Callback which sets info for an area. */
  onSetAreaInfo: (areaId: number, info: Partial<RegionAreaInfo>) => void;

  /** Callback which sets info for a connection. */
  onSetConnectionInfo: (connectionId: number, info: Partial<RegionConnectionInfo>) => void;

  /** Callback which sets the display state of a map display toggle. */
  onSetShow: (action: ActionSetShow['type'], show: boolean) => void;

  /** Callback which sets the map's theme. */
  onSetTheme: (theme: Theme) => void;
}

type ReducerAction = ActionFlipConnection
  | ActionSetAreaInfo
  | ActionSetConnectionInfo
  | ActionSetShow
  | ActionSetTheme;

type ReducerState = MapInfo;

// -- Public Hook --------------------------------------------------------------

/**
 * Manages map info: title, theme, settings, region settings, etc.
 */
export default function useMapInfo(snapshot?: MapSnapshot): MapInfoState {
  const [ state, dispatch ] = useReducer(infoReducer, null, () => getDefaultState(snapshot));

  const onSetAreaInfo = useCallback((areaId: number, info: Partial<RegionAreaInfo>) => {
    dispatch({ areaId, type: 'setAreaInfo', ...info });
  }, []);

  const onSetConnectionInfo = useCallback((connectionId: number, info: Partial<RegionConnectionInfo>) => {
    dispatch({ connectionId, type: 'setConnectionInfo', ...info });
  }, []);

  const onFlipConnection = useCallback((connectionId: number) => {
    dispatch({ connectionId, type: 'flipConnection' });
  }, []);

  const onSetShow = useCallback((action: ActionSetShow['type'], show: boolean) => {
    dispatch({ show, type: action });
  }, []);

  /** Sets the map's theme. */
  const onSetTheme = useCallback((newTheme: Theme) => {
    dispatch({ theme: newTheme, type: 'setTheme' });
  }, []);

  return {
    mapInfo: state,
    onFlipConnection,
    onSetAreaInfo,
    onSetConnectionInfo,
    onSetShow,
    onSetTheme,
  };
}

// -- Reducer ------------------------------------------------------------------

/**
 * Map info reducer.
 */
function infoReducer(state: ReducerState, action: ReducerAction): ReducerState {

  // -- Rotate -----------------------------------------------------------------

  if (action.type === 'flipConnection') {
    const connectionInfo = state.connectionInfo;
    const connectionId = action.connectionId;

    const flipped = !connectionInfo[connectionId]?.flipped;

    return getUpdatedConnectionInfoState(state, connectionId, { flipped });
  }

  // -- Set Region Info --------------------------------------------------------

  if (action.type === 'setAreaInfo') {
    const { areaId, type, ...info } = action;

    return getUpdatedAreaInfoState(state, areaId, info);
  }

  if (action.type === 'setConnectionInfo') {
    const { connectionId, type, ...info } = action;

    return getUpdatedConnectionInfoState(state, connectionId, info);
  }

  // -- Set Show Embellishment -------------------------------------------------

  if (action.type === 'setShowAreaNumbers') {
    return { ...state, showAreaNumbers: action.show };
  }

  if (action.type === 'setShowCompass') {
    return { ...state, showCompass: action.show };
  }

  if (action.type === 'setShowLegend') {
    return { ...state, showLegend: action.show };
  }

  if (action.type === 'setShowScale') {
    return { ...state, showScale: action.show };
  }

  // -- Set Theme --------------------------------------------------------------

  if (action.type === 'setTheme') {
    return { ...state, theme: action.theme };
  }/* v8 ignore next 4 */

  throw new TypeError(`Unknown action type "${action.type as string}" in useMapInfo(), mapReducer()`);
}

// -- Private Functions --------------------------------------------------------

/** Initializes reducer state. */
function getDefaultState(snapshot?: MapSnapshot): ReducerState {
  return {
    areaInfo: snapshot?.areaInfo ?? {},
    connectionInfo: snapshot?.connectionInfo ?? {},
    showAreaNumbers: snapshot?.showAreaNumbers ?? true,
    showCompass: snapshot?.showCompass ?? true,
    showLegend: snapshot?.showLegend ?? false,
    showScale: snapshot?.showScale ?? true,
    theme: snapshot?.theme ?? 'classic',
  };
}

/** Updates area info state. */
function getUpdatedAreaInfoState(state: ReducerState, areaId: number, info: Partial<RegionAreaInfo>): ReducerState {
  const { areaInfo } = state;

  return {
    ...state,
    areaInfo: {
      ...areaInfo,
      [areaId]: {
        ...areaInfo[areaId],
        ...info,
      },
    },
  };
}

/** Updates connection info state. */
function getUpdatedConnectionInfoState(state: ReducerState, connectionId: number, info: Partial<RegionConnectionInfo>): ReducerState {
  const { connectionInfo } = state;

  return {
    ...state,
    connectionInfo: {
      ...connectionInfo,
      [connectionId]: {
        ...connectionInfo[connectionId],
        ...info,
      },
    },
  };
}
