import { Button } from '@mui/material';

import CopyWithLinks from '../../components/Display/CopyWithLinks';
import Fetching from '../../components/Display/Fetching';
import StaticPage from '../../components/Display/StaticPage';
import useFetchData from '../../components/hooks/useFetchData';
import { patreonUrl } from '../../config';
import { path } from '../../config/path';

// -- Types --------------------------------------------------------------------

export type SupportCopy = string[];

// -- Config -------------------------------------------------------------------

/** Path to the road map data file. */
const supportJsonPath = '/data/support.json';

// -- Public Component ---------------------------------------------------------

/**
 * Renders the support page.
 */
export default function SupportPage() {
  const { data: supportCopy, ...fetchState } = useFetchData<SupportCopy>(supportJsonPath);

  return (
    <StaticPage
      metaDescription="Support the Mystic Waffle D&D dungeon generator, suggest features, and join the community."
      path={path.support}
      title="Support the Generator"
    >
      <Fetching {...fetchState} />

      {supportCopy?.map((paragraph, i) => {
        return (
          <p className="paragraph" key={i}>
            <CopyWithLinks copy={paragraph} />
          </p>
        );
      })}

      {supportCopy &&
        <Button
          color="secondary"
          href={patreonUrl}
          rel="noopener noreferrer"
          size="large"
          target="_blank"
          variant="contained"
        >
          Support the generator on Patreon
        </Button>
      }

    </StaticPage>
  );
}
