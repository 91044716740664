import { memo } from 'react';

import { DETAIL } from '../../../../lib/matrix';
import PixelArt from './';
import Cell from './Cell';

import type { Coordinates } from '../../../../lib/matrix';
import type { Pixels } from './';

// -- Config -------------------------------------------------------------------

/** Crates pixels: v2 */
const pixels: Pixels = [
  [ 'black', [[ 13, 11 ], [ 14, 11 ], [ 14, 15 ], [ 15, 15 ], [ 15, 14 ], [ 16, 14 ], [ 16, 15 ], [ 17, 15 ], [ 17, 8 ], [ 16, 8 ], [ 16, 9 ], [ 15, 9 ], [ 15, 8 ], [ 14, 8 ], [ 14, 9 ], [ 13, 9 ], [ 13, 8 ], [ 11, 8 ], [ 11, 9 ], [ 10, 9 ], [ 10, 8 ], [ 4, 8 ], [ 4, 7 ], [ 8, 7 ], [ 8, 6 ], [ 9, 6 ], [ 9, 7 ], [ 17, 7 ], [ 17, 6 ], [ 18, 6 ], [ 18, 16 ], [ 12, 16 ], [ 12, 19 ], [ 2, 19 ], [ 2, 9 ], [ 3, 9 ], [ 3, 10 ], [ 7, 10 ], [ 7, 9 ], [ 8, 9 ], [ 8, 10 ], [ 11, 10 ], [ 11, 9 ], [ 12, 9 ], [ 12, 15 ], [ 13, 15 ]], [[ 6, 14 ], [ 5, 14 ], [ 5, 13 ], [ 6, 13 ]], [[ 16, 10 ], [ 16, 13 ], [ 15, 13 ], [ 15, 10 ]], [[ 3, 18 ], [ 5, 18 ], [ 5, 16 ], [ 6, 16 ], [ 6, 18 ], [ 8, 18 ], [ 8, 17 ], [ 9, 17 ], [ 9, 18 ], [ 11, 18 ], [ 11, 11 ], [ 9, 11 ], [ 9, 16 ], [ 8, 16 ], [ 8, 11 ], [ 6, 11 ], [ 6, 12 ], [ 5, 12 ], [ 5, 11 ], [ 3, 11 ]], [[ 4, 9 ], [ 3, 9 ], [ 3, 8 ], [ 4, 8 ]], [[ 10, 4 ], [ 16, 4 ], [ 16, 5 ], [ 13, 5 ], [ 13, 6 ], [ 12, 6 ], [ 12, 5 ], [ 10, 5 ]], [[ 10, 6 ], [ 9, 6 ], [ 9, 5 ], [ 10, 5 ]], [[ 17, 6 ], [ 16, 6 ], [ 16, 5 ], [ 17, 5 ]]],
  [ 'gray10', [[ 10, 11 ], [ 10, 18 ], [ 9, 18 ], [ 9, 11 ]], [[ 5, 11 ], [ 5, 14 ], [ 6, 14 ], [ 6, 11 ], [ 8, 11 ], [ 8, 18 ], [ 6, 18 ], [ 6, 15 ], [ 5, 15 ], [ 5, 18 ], [ 3, 18 ], [ 3, 11 ]], [[ 13, 11 ], [ 13, 15 ], [ 12, 15 ], [ 12, 9 ], [ 11, 9 ], [ 11, 8 ], [ 13, 8 ], [ 13, 10 ], [ 14, 10 ], [ 14, 8 ], [ 15, 8 ], [ 15, 15 ], [ 14, 15 ], [ 14, 11 ]], [[ 10, 9 ], [ 11, 9 ], [ 11, 10 ], [ 8, 10 ], [ 8, 9 ], [ 7, 9 ], [ 7, 10 ], [ 3, 10 ], [ 3, 9 ], [ 4, 9 ], [ 4, 8 ], [ 10, 8 ]], [[ 12, 6 ], [ 13, 6 ], [ 13, 5 ], [ 16, 5 ], [ 16, 6 ], [ 17, 6 ], [ 17, 7 ], [ 9, 7 ], [ 9, 6 ], [ 10, 6 ], [ 10, 5 ], [ 12, 5 ]]],
  [ 'gray20', [[ 11, 11 ], [ 11, 18 ], [ 10, 18 ], [ 10, 11 ]], [[ 9, 17 ], [ 8, 17 ], [ 8, 16 ], [ 9, 16 ]], [[ 6, 16 ], [ 5, 16 ], [ 5, 15 ], [ 6, 15 ]], [[ 15, 13 ], [ 16, 13 ], [ 16, 10 ], [ 15, 10 ], [ 15, 9 ], [ 16, 9 ], [ 16, 8 ], [ 17, 8 ], [ 17, 15 ], [ 16, 15 ], [ 16, 14 ], [ 15, 14 ]], [[ 6, 13 ], [ 5, 13 ], [ 5, 12 ], [ 6, 12 ]], [[ 14, 10 ], [ 13, 10 ], [ 13, 9 ], [ 14, 9 ]]],
  [ 'gray30', [[ 18, 16 ], [ 18, 13 ], [ 19, 13 ], [ 19, 17 ], [ 13, 17 ], [ 13, 20 ], [ 4, 20 ], [ 4, 19 ], [ 12, 19 ], [ 12, 16 ]]],
];

// -- Memoized Components ------------------------------------------------------

const DetailsMemo = memo(Details);

// -- Public Component ---------------------------------------------------------

/**
 * Renders pixel art crates.
 */
export default function Crates({ coordinates }: { coordinates: Coordinates }) {
  return (
    <Cell
      coordinates={coordinates}
      type={DETAIL.Crates}
    >
      <DetailsMemo />
    </Cell>
  );
}

// -- Private Components -------------------------------------------------------

/** Renders pixels. */
function Details() {
  return <PixelArt pixels={pixels} />;
}
