import { Button, FormControl, FormHelperText, Stack, TextField, Typography } from '@mui/material';
import { useCallback, useEffect, useId, useRef, useState } from 'react';

import { matrixDimensionMax, matrixDimensionMin, themes } from '../../../config/map';
import { capitalize, toWords } from '../../../lib/string';
import WarningBox from '../../Display/WarningBox';
import WidowFix from '../../Display/WidowFix';
import LabeledSwitch from '../../Input/LabeledSwitch';
import Select from '../../Input/Select';
import AlertDialog from '../../Interface/AlertDialog';
import InputPanel from '../../Interface/InputPanel';

import styles from './MapEditorInputs.module.css';

import type { Theme } from '../../../lib/map';
import type { InteractiveMapState } from '../hooks/useInteractiveMap';
import type { MapInfoState } from '../hooks/useMapInfo';

// -- Config -------------------------------------------------------------------

/** Incomplete themes. */
const incompleteThemes: Theme[] = [ 'pixelArt' ];

/** Delay before showing dimension errors in milliseconds. */
const dimensionValidationDelay = 1000;

// -- Public Component ---------------------------------------------------------

/**
 * Renders the map settings editor.
 */
export default function MapEditorInputs({
  isMapEmpty,
  mapInfo,
  matrixHeight,
  matrixWidth,
  onSetDimensions,
  onSetShow,
  onSetTheme,
  onSetTitle,
  title,
}: Pick<InteractiveMapState, 'matrixHeight'
  | 'matrixWidth'
  | 'onSetDimensions'
  | 'onSetTitle'
  | 'title'
> & Pick<MapInfoState, 'mapInfo' | 'onSetShow' | 'onSetTheme'> & {
  isMapEmpty: boolean;
}) {
  const [ showInfo, setShowInfo ] = useState(false);

  return (
    <InputPanel
      aria-label="Map settings"
      onSetShowInfo={setShowInfo}
      showInfo={showInfo}
    >
      <FormControl>
        <TextField
          helperText={showInfo ? 'Give your map a title!' : undefined}
          label="Map Title"
          name="map-title"
          onChange={(e) => onSetTitle(e.target.value)}
          value={title}
        />
      </FormControl>

      <DimensionInputs
        isMapEmpty={isMapEmpty}
        key={`${matrixWidth}x${matrixHeight}`}
        matrixHeight={matrixHeight}
        matrixWidth={matrixWidth}
        onSetDimensions={onSetDimensions}
        showInfo={showInfo}
      />

      <Select
        infoText="Select a theme for your map."
        items={themes}
        label="Theme"
        onChange={(newTheme) => onSetTheme(newTheme as Theme)}
        showInfo={showInfo}
        value={mapInfo.theme}
      />

      {incompleteThemes.includes(mapInfo.theme) &&
        <WarningBox
          className={styles.warning}
          component="aside"
        >
          <WidowFix>
            {`The ${capitalize(toWords(mapInfo.theme))} theme is incomplete. The default theme will be used for any missing tiles.`}
          </WidowFix>
        </WarningBox>
      }

      <LabeledSwitch
        infoText={
          <WidowFix>
            Whether areas should be numbered on the map.
          </WidowFix>
        }
        isChecked={mapInfo?.showAreaNumbers ?? false}
        label="Show Area Numbers"
        onChange={(checked: boolean) => onSetShow('setShowAreaNumbers', checked)}
        showInfo={showInfo}
      />

      <LabeledSwitch
        infoText={
          <WidowFix>
            Whether the compass rose should be displayed on the map.
          </WidowFix>
        }
        isChecked={mapInfo?.showCompass ?? false}
        label="Show Compass Rose"
        onChange={(checked: boolean) => onSetShow('setShowCompass', checked)}
        showInfo={showInfo}
      />

      <LabeledSwitch
        infoText={
          <WidowFix>
            Whether the distance scale should be displayed on the map.
          </WidowFix>
        }
        isChecked={mapInfo?.showScale ?? false}
        label="Show Scale"
        onChange={(checked: boolean) => onSetShow('setShowScale', checked)}
        showInfo={showInfo}
      />

      <LabeledSwitch
        infoText={
          <WidowFix>
            Whether the legend should be displayed on the map.
          </WidowFix>
        }
        isChecked={mapInfo?.showLegend ?? false}
        label="Show Legend"
        onChange={(checked: boolean) => onSetShow('setShowLegend', checked)}
        showInfo={showInfo}
      />
    </InputPanel>
  );
}

// -- Private Components -------------------------------------------------------

/**
 * Renders dimension inputs.
 *
 * The apply dimensions buttons is disabled instantly if an invalid input is
 * entered. When dimensions are entered below the minimum, however, error styles
 * and the error message is delayed to make inputting numbers more seamless.
 */
function DimensionInputs({
  isMapEmpty,
  matrixHeight,
  matrixWidth,
  onSetDimensions,
  showInfo,
}: {
  isMapEmpty: boolean;
  matrixHeight: number;
  matrixWidth: number;
  onSetDimensions: (dimensions: [ number, number ]) => void;
  showInfo: boolean;
}) {
  const id = useId();
  const dimensionsInfoTextId = `dimensions-desc-${id}`;

  const [ width, setWidth ] = useState(matrixWidth.toString());
  const [ height, setHeight ] = useState(matrixHeight.toString());

  const [ showChangeDimensionsAlert, setShowChangeDimensionsAlert ] = useState(false);

  const widthNumber = Number(width);
  const heightNumber = Number(height);

  const isValidWidth = isValidDimension(widthNumber);
  const isValidHeight = isValidDimension(heightNumber);

  const showWidthError = useDelayedError(isValidWidth, { skip: !(widthNumber < matrixDimensionMin) });
  const showHeightError = useDelayedError(isValidHeight, { skip: !(heightNumber < matrixDimensionMin) });

  const isModifiedDimensions = widthNumber !== matrixWidth
    || heightNumber !== matrixHeight;

  const dimensionDescribedBy = showInfo || showWidthError || showHeightError
    ? dimensionsInfoTextId
    : undefined;

  const isDisabled = !isValidWidth || !isValidHeight || !isModifiedDimensions;

  /** Handles change width & height submission. */
  const handleApplyDimensions = useCallback(() => {
    if (!isMapEmpty && (widthNumber < matrixWidth || heightNumber < matrixHeight)) {
      setShowChangeDimensionsAlert(true);
      return;
    }

    onSetDimensions([ widthNumber, heightNumber ]);
  }, [
    heightNumber,
    isMapEmpty,
    matrixHeight,
    matrixWidth,
    onSetDimensions,
    widthNumber,
  ]);

  return (
    <>
      <div>
        <FormControl>
          <Stack
            direction="row"
            spacing={2}
          >
            <TextField
              error={showWidthError}
              InputProps={{ 'aria-describedby': dimensionDescribedBy }}
              label="Width"
              name="map-width"
              onChange={(e) => setWidth(e.target.value)}
              value={width}
            />

            <TextField
              error={showHeightError}
              InputProps={{ 'aria-describedby': dimensionDescribedBy }}
              label="Height"
              name="map-height"
              onChange={(e) => setHeight(e.target.value)}
              value={height}
            />

            <Button
              aria-label="Apply dimensions"
              className={styles.applyDimensionsButton}
              disabled={isDisabled}
              onClick={handleApplyDimensions}
            >
              Apply
            </Button>
          </Stack>

          <DimensionInfoText
            id={dimensionsInfoTextId}
            isInvalidDimensions={showWidthError || showHeightError}
            isModifiedDimensions={isModifiedDimensions}
            showInfo={showInfo}
          />
        </FormControl>
      </div>

      <AlertDialog
        isOpen={showChangeDimensionsAlert}
        onCancel={() => {
          setWidth(matrixWidth.toString());
          setHeight(matrixHeight.toString());
          setShowChangeDimensionsAlert(false);
        }}
        onConfirm={() => {
          onSetDimensions([ widthNumber, heightNumber ]);
          setShowChangeDimensionsAlert(false);
        }}
        title="Reduce dimensions?"
      >
        <Typography component="p">
          <WidowFix>
            Shrinking the map may result in discarded content. Are you sure you
            want to hack away at your map?
          </WidowFix>
        </Typography>
      </AlertDialog>
    </>
  );
}

/**
 * Renders dimension input help text.
 */
function DimensionInfoText({
  id,
  isInvalidDimensions,
  isModifiedDimensions,
  showInfo,
}: {
  id: string;
  isInvalidDimensions: boolean;
  isModifiedDimensions: boolean;
  showInfo: boolean;
}) {
  if (isInvalidDimensions) {
    return (
      <FormHelperText error id={id}>
        <WidowFix>
          {`Map dimensions must be integers between ${matrixDimensionMin} and ${matrixDimensionMax}.`}
        </WidowFix>
      </FormHelperText>
    );
  }

  if (showInfo) {
    return (
      <FormHelperText id={id}>
        <WidowFix>
          {`Map dimensions, from ${matrixDimensionMin} to ${matrixDimensionMax}.
          Reducing dimensions may result in discarded content.`}
        </WidowFix>
      </FormHelperText>
    );
  }

  if (isModifiedDimensions) {
    return (
      <FormHelperText id={id}>
        <WidowFix>
          Click Apply to change dimensions.
        </WidowFix>
      </FormHelperText>
    );
  }

  return null;
}

// -- Private Hooks ------------------------------------------------------------

/**
 * Delays a validation error.
 */
function useDelayedError(isValid: boolean, { skip }: { skip: boolean }): boolean {
  const errorTimer = useRef<NodeJS.Timeout>();
  const [ showError, setShowError ] = useState(false);

  useEffect(() => {
    globalThis.clearTimeout(errorTimer.current);

    if (isValid) {
      setShowError(false);
      return;
    }

    if (skip) {
      setShowError(!isValid);
      return;
    }

    errorTimer.current = globalThis.setTimeout(() => {
      setShowError(true);
    }, dimensionValidationDelay);

    return () => globalThis.clearTimeout(errorTimer.current);
  }, [ isValid, skip ]);

  return showError;
}

// -- Private Functions --------------------------------------------------------

/**
 * Returns whether the width or height is invalid.
 */
function isValidDimension(value: number): boolean {
  if (isNaN(value)) {
    return false;
  }

  if (!Number.isInteger(value)) {
    return false;
  }

  if (value < matrixDimensionMin || value > matrixDimensionMax) {
    return false;
  }

  return true;
}
