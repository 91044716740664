import { Rect as Rectangle } from 'react-konva';

import {
  cellPx,
  colors,
  embellishmentBoxInsetPx,
  embellishmentBoxWidthPx,
} from '../../../config/map';
import CompassRose, { compassHeight } from './CompassRose';
import Legend from './Legend';
import Scale, { scaleHeight } from './Scale';
import Title from './Title';

import type { ArtworkProps } from '../Artwork';

// -- Types --------------------------------------------------------------------

type EmbellishmentProps = Pick<ArtworkProps, 'instructions'
  | 'matrixHeight'
  | 'matrixWidth'
  | 'showCompass'
  | 'showLegend'
  | 'showScale'
  | 'theme'
  | 'title'
>;

// -- Config -------------------------------------------------------------------

/** X position of the compass rose embellishment when the legend is shown. */
const compassInsetX = (cellPx + embellishmentBoxInsetPx) / 2;

/** Scale container y position in grid units. */
const scaleY = cellPx * 1.25;

/** Scale container y position in grid units. */
const scaleY2 = (compassHeight + 1) * cellPx;

/** X position of the compass rose embellishment when the legend is shown. */
const scaleInsetX = cellPx + (embellishmentBoxInsetPx / 2);

// -- Public Component ---------------------------------------------------------

/**
 * Renders map embellishments.
 */
export default function Embellishment(props: EmbellishmentProps) {
  const {
    instructions,
    matrixHeight,
    matrixWidth,
    showCompass,
    showLegend,
    showScale,
    theme,
    title,
  } = props;

  const compassAndScaleBoxHeight = getCompassAndScaleBoxHeight(props);
  const availableLegendHeight = matrixHeight - compassAndScaleBoxHeight;

  const showCompassAndScaleBox = showLegend && (showCompass || showScale);

  return (
    <>
      <Title matrixWidth={matrixWidth} title={title} />

      {showCompassAndScaleBox &&
        <Rectangle
          data-id="map-embellishment-box"
          fill={colors.background}
          height={compassAndScaleBoxHeight * cellPx}
          stroke={colors.border}
          width={embellishmentBoxWidthPx}
          x={embellishmentBoxInsetPx}
          y={embellishmentBoxInsetPx}
        />
      }

      {showCompass &&
        <CompassRose
          x={showLegend ? compassInsetX : 0}
          y={0}
        />
      }

      {showScale &&
        <Scale
          segments={showLegend ? 3 : 2}
          x={showLegend ? scaleInsetX : cellPx}
          y={showCompass ? scaleY2 : scaleY}
        />
      }

      {showLegend &&
        <Legend
          availableHeight={availableLegendHeight}
          instructions={instructions}
          matrixHeight={matrixHeight}
          theme={theme}
        />
      }
    </>
  );
}

// -- Private Functions --------------------------------------------------------

/**
 * Returns the height of the compass and scale box.
 */
function getCompassAndScaleBoxHeight({
  showCompass,
  showScale,
}: EmbellishmentProps) {
  if (showCompass && showScale) {
    return compassHeight + scaleHeight;
  }

  if (showCompass) {
    return compassHeight + 0.5;
  }

  if (showScale) {
    return scaleHeight;
  }

  return 0;
}
