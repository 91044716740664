import { memo } from 'react';
import { RegularPolygon } from 'react-konva';

import { areaBorderPx, cellPx, colors } from '../../../config/map';

import type { ConnectionDirection } from '../../../lib/matrix';
import type { ConnectionProps } from './';

// -- Types --------------------------------------------------------------------

type ExteriorIndicatorProps = Pick<ConnectionProps, 'direction' | 'heightPx' | 'widthPx'>;

// -- Config -------------------------------------------------------------------

/** Indicator radius. */
const radius = cellPx / 7;

/** Indicator stroke width. */
const strokeWidth = areaBorderPx / 3;

// -- Public Component (Memoized) ----------------------------------------------

/**
 * Renders an exterior connection indicator.
 */
export default memo(function ExteriorIndicator({
  direction,
  heightPx,
  widthPx,
}: ExteriorIndicatorProps) {
  const x = widthPx / 2;
  const y = heightPx / 2;

  const rotation = getRotation(direction);

  return (
    <RegularPolygon
      fill={colors.marker}
      lineJoin="round"
      radius={radius}
      rotation={rotation}
      scaleY={1.4}
      sides={3}
      stroke={colors.marker}
      strokeWidth={strokeWidth}
      x={x}
      y={y}
    />
  );
});

// -- Private Functions --------------------------------------------------------

/**
 * Returns the indicator's rotation.
 */
function getRotation(direction: ConnectionDirection): number {
  switch (direction) {
    case 'north':
      return 0;

    case 'east':
      return 90;

    case 'south':
      return 180;

    case 'west':
      return 270;

    default:
      throw new TypeError(`Invalid cardinal direction "${direction}" in <ExteriorIndicator>, getRotation()`);
  }
}
