import { useCallback, useRef, useState } from 'react';

import { downloadFile, getFilenameStem, getJsonDataUrl } from '../../../lib/download';
import getDataUrl from '../../../lib/konva/getDataUrl';
import { filterMapSnapshot } from '../../../lib/map';

import type { MapSnapshot } from '../../../lib/map';
import type Konva from 'konva';
import type React from 'react';

// -- Public Hook --------------------------------------------------------------

/**
 * Returns state for the `<DownloadStage>` component. `<DownloadStage>` should
 * be rendered when `renderDownloadStage` is `true`. In the next animation
 * frame, the canvas data url will be extracted and downloaded along with the
 * map snapshot in JSON format, then `renderDownloadStage` will be set to
 * `false`.
 */
export default function useDownloadMap(snapshot: MapSnapshot): {
  onCloseDownloadOptions: () => void;
  onDownloadJson: () => void;
  onDownloadPng: () => void;
  onShowDownloadOptions: () => void;
  renderDownloadStage: boolean;
  showDownloadOptions: boolean;
  stageRef: React.RefObject<Konva.Stage>;
} {
  const stageRef = useRef<Konva.Stage>(null);
  const [ showDownloadOptions, setShowDownloadOptions ] = useState(false);
  const [ renderDownloadMap, setRenderDownloadMap ] = useState(false);

  const { title } = snapshot;
  const filenameStem = title ? getFilenameStem(title) : 'map';

  const onDownloadPng = useCallback(() => {
    setRenderDownloadMap(true);

    const requestId = globalThis.requestAnimationFrame(() => {
      const data = getDataUrl(stageRef);

      if (!data) {
        throw new TypeError('Failed to extract map data url in useDownloadMap()');
      }

      triggerDownloadPng(data, filenameStem);
      setRenderDownloadMap(false);
    });

    return () => globalThis.cancelAnimationFrame(requestId);
  }, [ filenameStem ]);

  const onDownloadJson = useCallback(() => {
    triggerDownloadJson(snapshot, filenameStem);
  }, [ snapshot, filenameStem ]);

  const onShowDownloadOptions = useCallback(() => {
    setShowDownloadOptions(true);
  }, []);

  const onCloseDownloadOptions = useCallback(() => {
    setShowDownloadOptions(false);
  }, []);

  return {
    onCloseDownloadOptions,
    onDownloadJson,
    onDownloadPng,
    onShowDownloadOptions,
    renderDownloadStage: renderDownloadMap,
    showDownloadOptions,
    stageRef,
  };
}

// -- Private Functions --------------------------------------------------------

/**
 * Downloads the map image.
 */
function triggerDownloadPng(mapDataUrl: string, filenameStem: string) {
  downloadFile(mapDataUrl, `${filenameStem}.mw.png`);
}

/**
 * Downloads the map JSON snapshot.
 */
function triggerDownloadJson(snapshot: MapSnapshot, filenameStem: string) {
  const filteredSnapshot = filterMapSnapshot(snapshot);
  downloadFile(getJsonDataUrl(JSON.stringify(filteredSnapshot)), `${filenameStem}.mw.json`);
}
