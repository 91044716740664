import { Stack } from '@mui/material';

import { rarities } from '../../config/attributes';
import { conditions } from '../../config/attributes/condition';
import { quantities } from '../../config/attributes/quantity';
import { lootCategories } from '../../config/loot';
import { inputStackPadding, inputStackSpacing } from '../../config/ui';
import { maxPercent, minPercent } from '../../lib/dice';
import WidowFix from '../Display/WidowFix';
import LabeledSlider from '../Input/LabeledSlider';
import LabeledSwitch from '../Input/LabeledSwitch';
import Multiselect from '../Input/Multiselect';
import Select from '../Input/Select';

import type { Condition, Quantity, Rarity } from '../../config/attributes';
import type { LootCategory } from '../../config/loot';
import type { LootSettings } from '../../lib/generate/loot';
import type { LootSettingsAction } from './hooks/useLootSettings';

// -- Types --------------------------------------------------------------------

interface LootInputProps {
  onSettingsChange: (action: LootSettingsAction) => void;
  showInfo: boolean;
}

// -- Config -------------------------------------------------------------------

/**
 * Exclude "zero" quantity from the loot generator.
 */
const lootQuantities: (Quantity | 'random')[] = quantities.filter((quantity) => quantity !== 'zero');
lootQuantities.unshift('random');

// -- Public Component ---------------------------------------------------------

/**
 * Renders loot generator inputs.
 */
export default function LootInputs({
  categories: selectedCategories,
  conditions: selectedConditions,
  enableMagicItems,
  magicItemProbability,
  paddingTop = inputStackPadding,
  quantity,
  rarities: selectedRarities,
  ...inputProps
}: LootInputProps & LootSettings & {
  paddingTop?: number;
}) {
  return (
    <Stack p={inputStackPadding} pt={paddingTop} spacing={inputStackSpacing}>
      <CategoryMultiselect
        {...inputProps}
        selectedCategories={selectedCategories}
      />

      <QuantitySelect
        {...inputProps}
        quantity={quantity}
      />

      <ConditionMultiselect
        {...inputProps}
        selectedConditions={selectedConditions}
      />

      <RarityMultiselect
        {...inputProps}
        selectedRarities={selectedRarities}
      />

      <MagicItemsSwitch
        {...inputProps}
        enableMagicItems={enableMagicItems}
      />

      <MagicItemsSlider
        {...inputProps}
        enableMagicItems={enableMagicItems}
        magicItemProbability={magicItemProbability}
      />
    </Stack>
  );
}

// -- Private Components -------------------------------------------------------

/**
 * Renders the loot category multiselect.
 */
function CategoryMultiselect({
  onSettingsChange,
  selectedCategories,
  showInfo,
}: LootInputProps & {
  selectedCategories: LootCategory[];
}) {
  return (
    <Multiselect
      infoText={
        <WidowFix>
          The categories which will be used for loot generation.
        </WidowFix>
      }
      items={lootCategories}
      label="Loot"
      onChange={(items) => onSettingsChange({ categories: items as LootCategory[] })}
      selectedItems={selectedCategories}
      showInfo={showInfo}
    />
  );
}

/**
 * Renders the loot quantity select.
 */
function QuantitySelect({
  onSettingsChange,
  quantity,
  showInfo,
}: LootInputProps & {
  quantity: Quantity | 'random';
}) {
  return (
    <Select
      infoText={
        <WidowFix>
          How much loot to generate.
        </WidowFix>
      }
      items={lootQuantities}
      label="Quantity"
      onChange={(value: string) => onSettingsChange({ quantity: value as Quantity | 'random' })}
      showInfo={showInfo}
      value={quantity}
    />
  );
}

/**
 * Renders the loot condition multiselect.
 */
function ConditionMultiselect({
  onSettingsChange,
  selectedConditions,
  showInfo,
}: LootInputProps & {
  selectedConditions: Condition[];
}) {
  return (
    <Multiselect
      allowEmpty
      infoText={
        <WidowFix>
          The possible physical condition of each piece of loot.
          Condition randomization is distributed by a probability table.
          If no conditions are selected, loot condition will be excluded from results.
        </WidowFix>
      }
      items={conditions}
      label="Conditions"
      onChange={(items: string[]) => onSettingsChange({ conditions: items as Condition[] })}
      selectedItems={selectedConditions}
      showInfo={showInfo}
    />
  );
}

/**
 * Renders the loot rarity multiselect.
 */
function RarityMultiselect({
  onSettingsChange,
  selectedRarities,
  showInfo,
}: LootInputProps & {
  selectedRarities: Rarity[];
}) {
  return (
    <Multiselect
      infoText={
        <WidowFix>
          The possible rarity of each piece of loot.
          Rarity randomization is distributed by a probability table.
        </WidowFix>
      }
      items={rarities}
      label="Rarities"
      onChange={(items: string[]) => onSettingsChange({ rarities: items as Rarity[] })}
      selectedItems={selectedRarities}
      showInfo={showInfo}
    />
  );
}

/**
 * Renders the magic items switch.
 */
function MagicItemsSwitch({
  enableMagicItems,
  onSettingsChange,
  showInfo,
}: LootInputProps & {
  enableMagicItems: boolean;
}) {

  /** Handles magic items switch change events. */
  function onChange(checked: boolean) {
    onSettingsChange({ enableMagicItems: checked });
  }

  return (
    <LabeledSwitch
      infoText="Whether magical items should be included in loot generation."
      isChecked={enableMagicItems}
      label="Magical Items"
      onChange={onChange}
      showInfo={showInfo}
    />
  );
}

/**
 * Renders the magic item probability slider.
 */
function MagicItemsSlider({
  enableMagicItems,
  magicItemProbability,
  onSettingsChange,
  showInfo,
}: LootInputProps & {
  enableMagicItems: boolean;
  magicItemProbability: number;
}) {
  if (!enableMagicItems) {
    return null;
  }

  return (
    <LabeledSlider
      infoText="The probability that magic items will be included in loot randomization. Note that some loot categories do not contain any magical items."
      label="Magical Item Probability"
      max={maxPercent}
      min={minPercent}
      onChange={(value: number) => onSettingsChange({ magicItemProbability: value })}
      showInfo={showInfo}
      value={magicItemProbability}
      valueDisplay="percent"
    />
  );
}
