import { Button, Stack } from '@mui/material';

import AlertDialog from '../../Interface/AlertDialog';

/**
 * Renders the download options panel.
 */
export default function DownloadOptions({
  isOpen,
  onClose,
  onDownloadJson,
  onDownloadPng,
}: {
  isOpen: boolean;
  onClose: () => void;
  onDownloadJson: () => void;
  onDownloadPng: () => void;
}) {
  return (
    <AlertDialog
      confirmLabel="Done"
      isOpen={isOpen}
      onConfirm={onClose}
      title="Map download options"
    >
      <Stack>
        <Button
          color="primary"
          fullWidth
          onClick={onDownloadPng}
          size="large"
          variant="text"
        >
          Download Image (PNG)
        </Button>

        <Button
          color="primary"
          fullWidth
          onClick={onDownloadJson}
          size="large"
          variant="text"
        >
          Download Restore File (JSON)
        </Button>
      </Stack>
    </AlertDialog>
  );
}
