import { memo } from 'react';
import { Circle, Group, Star, Text } from 'react-konva';

import {
  cellPx,
  colors,
  embellishmentStrokePx,
  fontFamilyTitle,
} from '../../../config/map';

// -- Config -------------------------------------------------------------------

/** Compass rose container height in grid units. */
export const compassHeight = 4;

/** Width of the compass rose's bounding box. */
const width = cellPx * 4;

/** The relative size of the compass rose. */
const size = cellPx * 2.5;

/** Inner radius of the outer ordinal. */
const starCardinalInnerRadius = size / 10;

/** Outer radius of the outer ordinal. */
const starCardinalOuterRadius = size / 2;

/** Center Y coordinate relative to the compass rose's bounding box. */
const starCenterY = cellPx * 2.75;

/** Center X coordinate relative to the compass rose's bounding box. */
const starCenterX = width / 2;

/** Inner radius of the outer ordinal. */
const starOrdinalInnerRadius = size / 8;

/** Outer radius of the outer ordinal. */
const starOrdinalOuterRadius = size / 2.75;

/** Radius of the compass roses's circle. */
const starCircleRadius = size / 4;

/** Font size of compass rose's direction indicator. */
const textSize = size / 5;

/** Y position of the compass rose's direction indicator. */
const textY = cellPx * 0.8;

// -- Public Component ---------------------------------------------------------

/**
 * Renders the compass rose embellishment, which is contained in a 4 x 4 grid
 * unit box, at the given x and y pixel coordinates.
 */
export default memo(function CompassRose({ x, y }: { x: number; y: number }) {
  return (
    <Group
      data-id="map-compass"
      x={x}
      y={y}
    >
      <Circle
        fill={colors.shadow}
        radius={starCircleRadius}
        stroke={colors.border}
        strokeWidth={embellishmentStrokePx}
        x={starCenterX}
        y={starCenterY}
      />

      <Star
        fill={colors.background}
        innerRadius={starOrdinalInnerRadius}
        lineJoin="round"
        numPoints={4}
        outerRadius={starOrdinalOuterRadius}
        rotation={45}
        stroke={colors.border}
        strokeWidth={embellishmentStrokePx}
        x={starCenterX}
        y={starCenterY}
      />

      <Star
        fill={colors.background}
        innerRadius={starCardinalInnerRadius}
        lineJoin="round"
        numPoints={4}
        outerRadius={starCardinalOuterRadius}
        stroke={colors.border}
        strokeWidth={embellishmentStrokePx}
        x={starCenterX}
        y={starCenterY}
      />

      <Text
        align="center"
        fill={colors.label}
        fontFamily={fontFamilyTitle}
        fontSize={textSize}
        fontStyle="600"
        height={textSize}
        text="N"
        verticalAlign="top"
        width={width}
        x={0}
        y={textY}
      />
    </Group>
  );
});
