import { useReducer } from 'react';

import { conditions, rarities } from '../../../config/attributes';
import { lootCategories } from '../../../config/loot';

import type { LootSettings } from '../../../lib/generate/loot';

// -- Types --------------------------------------------------------------------

export type LootSettingsAction = Partial<LootSettings>;

export type LootSettingsState = [
  settings: LootSettings,
  onSettingsChange: (action: LootSettingsAction) => void
];

// -- Config -------------------------------------------------------------------

const defaultSettings: LootSettings = {
  categories: [ ...lootCategories ],
  conditions: [ ...conditions ],
  enableMagicItems: true,
  magicItemProbability: 10,
  quantity: 'random',
  rarities: [ ...rarities ],
};

// -- Public Hook --------------------------------------------------------------

/**
 * Loot settings state hook.
 */
export default function useLootSettings({
  initialSettings = defaultSettings,
}: {
  initialSettings?: LootSettings;
} = {}): LootSettingsState {
  const [ settings, onSettingsChange ] = useReducer(settingsReducer, initialSettings);

  return [
    settings,
    onSettingsChange,
  ];
}

// -- Private Functions --------------------------------------------------------

/**
 * Loot settings reducer.
 */
function settingsReducer(state: LootSettings, action: LootSettingsAction): LootSettings {
  return {
    ...state,
    ...action,
  };
}
