import AlertDialog from '../../Interface/AlertDialog';
import useModifierKey from '../../Interface/hooks/useModifierKey';

import styles from './Keybindings.module.css';

/**
 * Renders a modal showing available keyboard shortcuts.
 */
export default function Keybindings({ onClose }: { onClose: () => void }) {
  const modifierKey = useModifierKey();

  return (
    <AlertDialog
      isOpen
      isQuickDismiss
      maxWidth="md"
      onConfirm={onClose}
      title="Shortcuts"
    >
      <div className={styles.keybindings}>
        <div>
          <ul>
            <li><kbd>D</kbd>Draw</li>
            <li><kbd>E</kbd>Erase</li>
            <li><kbd>S</kbd>Select</li>
            <li><kbd>V</kbd>/<kbd>Hold Space</kbd>Pan Canvas</li>
          </ul>
          <ul>
            <li><kbd>+</kbd>Zoom In</li>
            <li><kbd>-</kbd>Zoom Out</li>
            <li><kbd>F</kbd>Fit Map</li>
          </ul>
        </div>
        <div>
          <ul>
            <li><kbd>A</kbd>Draw Area</li>
            <li><kbd>C</kbd>Draw Connection</li>
            <li><kbd>X</kbd>Draw Detail</li>
            <li><kbd>↑</kbd>/<kbd>↓</kbd>Cycle Brushes</li>
          </ul>
          <ul>
            <li><kbd>{modifierKey} Z</kbd>Undo</li>
            <li><kbd>{modifierKey} Shift Z</kbd>Redo</li>
            <li><kbd>Esc</kbd>Cancel Draw / Erase</li>
          </ul>
        </div>
      </div>
    </AlertDialog>
  );
}
