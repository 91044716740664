import { useCallback, useState } from 'react';

import useSeedState from '../../../components/Interface/GeneratorPanel/hooks/useSeedState';
import useLootSettings from '../../../components/Loot/hooks/useLootSettings';
import { getDiceBag } from '../../../lib/dice';
import generateLoot from '../../../lib/generate/loot';

import type { LootSettingsAction } from '../../../components/Loot/hooks/useLootSettings';
import type { LootResults, LootSettings } from '../../../lib/generate/loot';

// -- Types --------------------------------------------------------------------

interface LootPageGeneratorState {
  mode: Mode;
  onClear: () => void;
  onGenerate: () => void;
  onSeedChange: (seed: string) => void;
  onSettingsChange: (action: LootSettingsAction) => void;
  onToggleToc: () => void;
  results?: LootResults;
  seed?: string;
  settings: LootSettings;
}

type Mode = 'generate' | 'toc';

interface UseLootPageGeneratorProps {
  clearChangesFlag: () => void;
  onComplete: () => void;
}

// -- Public Hook --------------------------------------------------------------

/**
 * Loot page generator state hook.
 */
export default function useLootPageGenerator({
  clearChangesFlag,
  onComplete,
}: UseLootPageGeneratorProps): LootPageGeneratorState {
  const { onSeedChange, seed } = useSeedState();
  const [ mode, setMode ] = useState<Mode>('generate');
  const [ results, setResults ] = useState<LootResults>();
  const [ settings, onSettingsChange ] = useLootSettings();

  /** Toggles the table of contents mode. */
  const onToggleToc = useCallback(() => {
    setMode(mode === 'toc' ? 'generate' : 'toc');
  }, [ mode, setMode ]);

  /** Generates loot. */
  const onGenerate = useCallback(() => {
    if (mode === 'toc') {
      setMode('generate');
    }

    const dice = getDiceBag({ seed });
    setResults(generateLoot(dice, settings));
    onComplete();
  }, [ mode, onComplete, seed, setMode, settings ]);

  /** Clears loot results */
  const onClear = useCallback(() => {
    clearChangesFlag();
    setResults(undefined);
  }, [ clearChangesFlag ]);

  return {
    mode,
    onClear,
    onGenerate,
    onSeedChange,
    onSettingsChange,
    onToggleToc,
    results,
    seed,
    settings,
  };
}
