import { memo } from 'react';
import { Group, Line, Text } from 'react-konva';

import {
  areaBorderPx,
  cellPx,
  colors,
  fontFamilyTitle,
  fontSizeMapTitleMedium,
} from '../../../config/map';
import { CONNECTION, connectionDirectionsMeridian } from '../../../lib/matrix';

import type { ConnectionProps } from './';

// -- Config -------------------------------------------------------------------

const lineInset = cellPx / 4;
const dashLength = cellPx / 30;
const dashSpace = cellPx / 5;

// -- Public Component (Memoized) ----------------------------------------------

/**
 * Renders secret passageway details.
 */
export default memo(function SecretPassageway(props: ConnectionProps) {
  const linePointSets = getLinePointSets(props);
  const { heightPx, widthPx, xPx, yPx } = props;

  return (
    <Group
      data-id={CONNECTION.SecretPassageway}
      x={xPx}
      y={yPx}
    >
      {linePointSets.map((points, i) => (
        <Line
          dash={[ dashLength, dashSpace ]}
          key={i}
          lineCap="round"
          points={points}
          stroke={colors.shadowDetail}
          strokeWidth={areaBorderPx}
        />
      ))}

      <Text
        align="center"
        fill={colors.shadowDetail}
        fontFamily={fontFamilyTitle}
        fontSize={fontSizeMapTitleMedium}
        fontStyle="400"
        fontVariant="small-caps"
        height={heightPx}
        text="s"
        verticalAlign="middle"
        width={widthPx}
        wrap="word"
      />
    </Group>
  );
});

// -- Private Functions --------------------------------------------------------

/**
 * Returns secret passageway edge line points.
 */
function getLinePointSets({
  direction,
  heightPx,
  widthPx,
}: ConnectionProps): number[][] {
  if (connectionDirectionsMeridian.has(direction)) {
    return [
      /* eslint-disable @stylistic/no-multi-spaces */
      [ 0,       lineInset, 0,       heightPx - lineInset ],
      [ widthPx, lineInset, widthPx, heightPx - lineInset ],
      /* eslint-enable @stylistic/no-multi-spaces */
    ];
  }

  return [
    /* eslint-disable @stylistic/no-multi-spaces */
    [ lineInset, 0,        widthPx - lineInset, 0        ],
    [ lineInset, heightPx, widthPx - lineInset, heightPx ],
    /* eslint-enable @stylistic/no-multi-spaces */
  ];
}
