import { Stack } from '@mui/material';
import { useState } from 'react';

import { Door as DoorIcon } from '../../components/Display/Icons';
import WarningBox from '../../components/Display/WarningBox';
import WidowFix from '../../components/Display/WidowFix';
import AlertDialog from '../../components/Interface/AlertDialog';
import BigJuicyButton from '../../components/Interface/BigJuicyButton';
import EmptyState from '../../components/Interface/EmptyState';
import Link from '../../components/Interface/Link';
import Layout from '../../components/Layout';
import Metadata from '../../components/Utility/Metadata';
import { v1Url } from '../../config';
import { path } from '../../config/path';

import styles from './styles.module.css';

// -- Public Component ---------------------------------------------------------

/**
 * Renders the area generator page.
 */
export default function AreasPage() {
  const [ showAlert, setShowAlert ] = useState(false);

  /** Triggers the WIP alert. */
  function onGenerate() {
    setShowAlert(true);
  }

  return (
    <>
      <Metadata
        description="Generate areas for your D&D adventure including descriptions and loot."
        path={path.areas}
        title="D&D Area Generator"
      />

      <Layout>
        <EmptyState
          description="Generate one or more spaces for your adventure’s scene; perhaps as a wizard’s laboratory, a smithy, or an armory."
          title="Area Generator"
        >
          <Stack
            direction="row"
            mb={4}
            mt={4}
            spacing={4}
          >
            <BigJuicyButton
              icon={DoorIcon}
              label="Generate Area"
              onClick={onGenerate}
            />
          </Stack>

          <p className={styles.instructions}>
            Set your desired area configurations, then click <em className="emphasize">Generate</em>.
          </p>

          <WarningBox component="aside">
            <p>
              The area generator is still being migrated from the <Link href={v1Url}>Alpha v1 Dungeon Generator</Link>, <WidowFix>please hang tight.</WidowFix>
            </p>
          </WarningBox>
        </EmptyState>
      </Layout>

      <AlertDialog
        isOpen={showAlert}
        onConfirm={() => setShowAlert(false)}
        title="Work in progress"
      >
        <p>
          The area generator is still being migrated from the <Link href={v1Url}>Alpha v1 dungeon generator</Link>,
          please hang tight while we finish the migration. <WidowFix>You can use the v1 area generator in the meantime.</WidowFix>
        </p>
      </AlertDialog>
    </>
  );
}
