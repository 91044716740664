import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
} from '@mui/material';
import { useState } from 'react';

import AreaInputs from '../../components/Area/AreaInputs';
import { ChevronRight as ChevronRightIcon } from '../../components/Display/Icons';
import GeneratorPanel from '../../components/Interface/GeneratorPanel';
import useGeneratorPanelState from '../../components/Interface/GeneratorPanel/hooks/useGeneratorPanelState';
import MapInputs from '../../components/Map/MapInputs';

import type { MapPageGeneratorState } from './hooks/useMapPageGenerator';

// -- Public Component ---------------------------------------------------------

/**
 * Renders the map generator options panel.
 *
 * TDL Reset settings
 */
export default function MapGeneratorOptions({
  areaSettings,
  lootSettings, // eslint-disable-line @typescript-eslint/no-unused-vars
  mapSettings,
  onAreaSettingsChange,
  onGenerate,
  onLootSettingsChange, // eslint-disable-line @typescript-eslint/no-unused-vars
  onMapSettingsChange,
  onSeedChange,
  seed,
}: MapPageGeneratorState) {
  const [ expandedPane, setExpandedPane ] = useState<false | string>('map-dungeon-inputs'); // TODO string | null
  const generatorPanelState = useGeneratorPanelState();
  const { showInfo } = generatorPanelState;

  /** Handles accordion change events. */
  const onAccordionClick = (panel: string) => (event: React.SyntheticEvent, newExpanded: boolean) => {
    setExpandedPane(newExpanded ? panel : false);
  };

  return (
    <GeneratorPanel
      aria-label="Map generator settings"
      onGenerate={onGenerate}
      onSeedChange={onSeedChange}
      seed={seed}
      {...generatorPanelState}
    >
      <AccordionGroup
        expandedPane={expandedPane}
        id="map-dungeon-inputs"
        label="Dungeon Settings"
        onAccordionClick={onAccordionClick}
      >
        <MapInputs
          onSettingsChange={onMapSettingsChange}
          showInfo={showInfo}
          {...mapSettings}
        />
      </AccordionGroup>

      <AccordionGroup
        expandedPane={expandedPane}
        id="map-area-inputs"
        label="Area Settings"
        onAccordionClick={onAccordionClick}
      >
        <AreaInputs
          onSettingsChange={onAreaSettingsChange}
          showInfo={showInfo}
          {...areaSettings}
        />
      </AccordionGroup>

      {/* <AccordionGroup // TDL wire up loot to map generator
        expandedPane={expandedPane}
        id="map-loot-inputs"
        label="Loot Settings"
        onAccordionClick={onAccordionClick}
      >
        <LootInputs
          onSettingsChange={onLootSettingsChange}
          showInfo={showInfo}
          {...lootSettings}
        />
      </AccordionGroup> */}
    </GeneratorPanel>
  );
}

// -- Private Components -------------------------------------------------------

/**
 * Renders a map generator accordion group.
 */
function AccordionGroup({
  children,
  expandedPane,
  id,
  label,
  onAccordionClick,
}: {
  children: React.ReactNode;
  expandedPane: false | string;
  id: string;
  label: string;
  onAccordionClick: (panel: string) => (event: React.SyntheticEvent, newExpanded: boolean) => void;
}) {
  return (
    <Accordion
      disableGutters
      expanded={expandedPane === id}
      onChange={onAccordionClick(id)}
    >
      <AccordionSummary
        aria-controls={id}
        expandIcon={<ChevronRightIcon aria-hidden />}
        id={`${id}-handle`}
      >
        {label}
      </AccordionSummary>
      <AccordionDetails>
        {children}
      </AccordionDetails>
    </Accordion>
  );
}
