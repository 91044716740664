import { createProbabilityTable } from '../../lib/dice';

import type { Dice } from '../../lib/dice';

// -- Types --------------------------------------------------------------------

export type Rarity = typeof rarities[number];

// -- Config -------------------------------------------------------------------

/**
 * Rarities
 */
export const rarities = [
  'abundant',
  'common',
  'average',
  'uncommon',
  'rare',
  'veryRare',
  'exotic',
  'legendary',
] as const;

/**
 * Rarity scores. Determines the distribution of loot rarities. A higher score
 * is more abundant, a lower score is more rare.
 */
const rarityScores: Readonly<Record<Rarity, number>> = {
  /* eslint-disable perfectionist/sort-objects */
  abundant: 10,
  common: 9,
  average: 7,
  uncommon: 5,
  rare: 4,
  veryRare: 3,
  exotic: 2,
  legendary: 1,
  /* eslint-enable perfectionist/sort-objects */
};

// -- Public Functions ---------------------------------------------------------

/**
 * Creates a probability table and returns a function which rolls a rarity.
 */
export function getRarityProbabilityRoll(
  dice: Dice,
  raritiesToRoll: Rarity[] = [ ...rarities ]
) {
  const probabilityTable = createProbabilityTable(raritiesToRoll, rarityScores);
  return dice.getProbabilityRoll<Rarity>(probabilityTable);
}
