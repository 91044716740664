import {
  FormHelperText,
  FormLabel,
  Slider,
  Typography,
} from '@mui/material';
import { useCallback, useId } from 'react';

// -- Types --------------------------------------------------------------------

type ValueDisplay = 'number' | 'percent';

// -- Config -------------------------------------------------------------------

/** Slider label styles. */
const labelSx = {
  color: 'text.primary',
  display: 'flex',
  fontSize: 'body2.fontSize',
  justifyContent: 'space-between',
  mb: 1,
  mx: 1.75,
};

/** Slider styles. */
const sliderSx = {
  display: 'block',
  mx: 1.75,
  width: 'auto',
};

// -- Public Component ---------------------------------------------------------

/**
 * Renders a controlled slider and label.
 */
export default function LabeledSlider({
  infoText,
  label,
  max,
  min,
  onChange,
  showInfo,
  value,
  valueDisplay = 'number',
}: {
  infoText: React.ReactNode;
  label: string;
  max: number;
  min: number;
  onChange: (newValue: number) => void;
  showInfo: boolean;
  value: number;
  valueDisplay?: ValueDisplay;
}) {
  const id = useId();
  const labelId = `slider-label-${id}`;
  const infoTextId = `slider-desc-${id}`;

  /** Handles slider change events. */
  const onSliderChange = useCallback((event: Event, newValue: number[] | number) => {
    onChange(newValue as number);
  }, [ onChange ]);

  const valueText = getSliderValueText(value, valueDisplay);

  return (
    <div>
      <FormLabel
        component="span"
        sx={labelSx}
      >
        <span id={labelId}>{label}</span>

        <Typography
          aria-hidden
          color="primary"
          fontSize="body2.fontSize"
          fontWeight="600"
        >
          {valueText}
        </Typography>
      </FormLabel>

      <Slider
        aria-labelledby={labelId}
        aria-valuetext={valueText}
        max={max}
        min={min}
        onChange={onSliderChange}
        slotProps={{
          input: {
            'aria-describedby': showInfo ? infoTextId : undefined,
          },
        }}
        sx={sliderSx}
        value={value}
        valueLabelDisplay="off"
      />

      {showInfo &&
        <FormHelperText id={infoTextId}>
          {infoText}
        </FormHelperText>
      }
    </div>
  );
}

// -- Private Functions --------------------------------------------------------

/**
 * Returns the slider value text.
 */
function getSliderValueText(value: number, display: ValueDisplay): string {
  return display === 'percent' ? `${value}%` : value.toString();
}
