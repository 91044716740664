import { Stack } from '@mui/material';

import { areas } from '../../config/area';
import { sizes } from '../../config/attributes';
import { conditions } from '../../config/attributes/condition';
import { inputStackPadding, inputStackSpacing } from '../../config/ui';
import { maxPercent, minPercent } from '../../lib/dice';
import { DETAIL } from '../../lib/matrix';
import WidowFix from '../Display/WidowFix';
import LabeledSlider from '../Input/LabeledSlider';
import Multiselect from '../Input/Multiselect';
import { availableDetails } from './hooks/useAreaSettings';

import type { Area } from '../../config/area';
import type { Condition, Size } from '../../config/attributes';
import type { AreaSettings } from '../../lib/generate/area';
import type { AreaSettingsAction } from './hooks/useAreaSettings';

// -- Types --------------------------------------------------------------------

interface AreaInputProps {
  onSettingsChange: (action: AreaSettingsAction) => void;
  showInfo: boolean;
}

// -- Public Component ---------------------------------------------------------

/**
 * Renders area generator inputs.
 */
export default function AreaInputs({
  areas: selectedAreas,
  conditions: selectedConditions,
  details: selectedDetails,
  sizes: selectedSizes,
  trapFrequency,
  trapProbability,
  ...inputProps
}: AreaInputProps & AreaSettings) {
  return (
    <Stack p={inputStackPadding} spacing={inputStackSpacing}>
      <AreaMultiselect
        {...inputProps}
        selectedAreas={selectedAreas}
      />

      <ConditionMultiselect
        {...inputProps}
        selectedConditions={selectedConditions}
      />

      <SizeMultiselect
        {...inputProps}
        selectedSizes={selectedSizes}
      />

      <DetailsMultiselect
        {...inputProps}
        selectedDetails={selectedDetails}
      />

      <TrapFrequencySlider
        {...inputProps}
        trapFrequency={trapFrequency}
      />

      {trapFrequency > 0 &&
        <TrapProbabilitySlider
          {...inputProps}
          trapProbability={trapProbability}
        />
      }
    </Stack>
  );
}

// -- Private Components -------------------------------------------------------

/**
 * Renders area type multiselect.
 */
function AreaMultiselect({
  onSettingsChange,
  selectedAreas,
  showInfo,
}: AreaInputProps & {
  selectedAreas: Area[];
}) {
  return (
    <Multiselect
      allowEmpty
      infoText={
        <WidowFix>
          What types of areas should be included?
        </WidowFix>
      }
      items={areas}
      label="Areas"
      onChange={(items: string[]) => onSettingsChange({ areas: items as Area[] })}
      selectedItems={selectedAreas}
      showInfo={showInfo}
    />
  );
}

/**
 * Renders area condition multiselect.
 */
function ConditionMultiselect({
  onSettingsChange,
  selectedConditions,
  showInfo,
}: AreaInputProps & {
  selectedConditions: Condition[];
}) {
  return null; // TDL area conditions in descriptions
  return (
    <Multiselect
      allowEmpty
      infoText={
        <WidowFix>
          What conditions are the areas in?
          Condition randomization is distributed by a probability table.
        </WidowFix>
      }
      items={conditions}
      label="Conditions"
      onChange={(items: string[]) => onSettingsChange({ conditions: items as Condition[] })}
      selectedItems={selectedConditions}
      showInfo={showInfo}
    />
  );
}

/**
 * Renders area size multiselect.
 */
function SizeMultiselect({
  onSettingsChange,
  selectedSizes,
  showInfo,
}: AreaInputProps & {
  selectedSizes: Size[];
}) {
  return (
    <Multiselect
      infoText={
        <WidowFix>
          What sizes of rooms and areas do you want to generate?
          Size randomization is distributed evenly.
          Some areas have specific size requirements and may ignore this setting.
          For example, if the great hall is selected but only tiny areas are allowed, a great hall will still be generated as a large or massive area.
        </WidowFix>
      }
      items={sizes}
      label="Sizes"
      onChange={(items: string[]) => onSettingsChange({ sizes: items as Size[] })}
      selectedItems={selectedSizes}
      showInfo={showInfo}
    />
  );
}

/**
 * Renders area details multiselect.
 */
function DetailsMultiselect({
  onSettingsChange,
  selectedDetails,
  showInfo,
}: AreaInputProps & {
  selectedDetails: DETAIL[];
}) {
  return null; // TDN add details to map generator
  return (
    <Multiselect
      allowEmpty
      infoText={
        <WidowFix>
          The details to include in areas.
          Some areas, such as an alchemy lab, will always include specific details, such as an alchemy bench, even if the detail is excluded from your selection here.
          However, if no details are selected, all details will be excluded.
        </WidowFix>
      }
      items={availableDetails}
      label="Details"
      onChange={(items: string[]) => onSettingsChange({ details: items as DETAIL[] })}
      selectedItems={selectedDetails}
      showInfo={showInfo}
    />
  );
}

/**
 * Renders the dungeon trap frequency slider.
 */
function TrapFrequencySlider({
  onSettingsChange,
  showInfo,
  trapFrequency,
}: AreaInputProps & {
  trapFrequency: number;
}) {
  return null; // TDN add traps to map generator
  return (
    <LabeledSlider
      infoText={
        <WidowFix>
          The frequency in which traps will be distributed throughout areas.
          Setting this to zero disables trap distribution. Setting it to max means up to five traps might be placed in a single area.
        </WidowFix>
      }
      label="Trap Frequency"
      max={5}
      min={0}
      onChange={(value: number) => onSettingsChange({ trapFrequency: value })}
      showInfo={showInfo}
      value={trapFrequency}
    />
  );
}

/**
 * Renders the dungeon trap probability slider.
 */
function TrapProbabilitySlider({
  onSettingsChange,
  showInfo,
  trapProbability,
}: AreaInputProps & {
  trapProbability: number;
}) {
  return null; // TDN add traps to map generator
  return (
    <LabeledSlider
      infoText={
        <WidowFix>
          The probability that a trap will be added to an area.
          Setting this to 100% means every area will contain the maximum number of traps based on the Trap Frequency 😱
        </WidowFix>
      }
      label="Trap Probability"
      max={maxPercent}
      min={minPercent}
      onChange={(value: number) => onSettingsChange({ trapProbability: value })}
      showInfo={showInfo}
      value={trapProbability}
      valueDisplay="percent"
    />
  );
}
