import { Typography } from '@mui/material';

import WidowFix from './WidowFix';

import styles from './EmptyMessage.module.css';

/**
 * Renders an empty message.
 */
export default function EmptyMessage({
  children,
  isMaxWidth,
  message,
  title,
}: {
  children?: React.ReactNode;
  isMaxWidth?: boolean;
  message?: string[];
  title: string;
}) {
  return (
    <div
      className={styles.emptyMessage}
      data-max-width={isMaxWidth || undefined}
    >
      <Typography
        component="h1"
        variant="h3"
      >
        {title}
      </Typography>

      {message?.map((text, i) => (
        <Typography
          color="textSecondary"
          component="p"
          fontSize="1.25rem"
          fontStyle="italic"
          key={i}
          marginTop={1.5}
        >
          <WidowFix>
            {text}
          </WidowFix>
        </Typography>
      ))}

      {children &&
        <div className={styles.messageExtras}>
          {children}
        </div>
      }
    </div>
  );
}
