import { createContext, useCallback, useEffect, useMemo, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import type { To } from 'react-router-dom';

// -- Types --------------------------------------------------------------------

type UnsavedChangesContextState = {
  clearChangesFlag: () => void;
  flagChanges: () => void;
  onCancelNav: () => void;
  onConfirmNav: () => void;
  onNav: (event: React.MouseEvent<HTMLAnchorElement>, to: To) => void;
  showUnsavedChangesAlert: boolean;
};

// -- Contexts -----------------------------------------------------------------

export const UnsavedChangesContext = createContext<UnsavedChangesContextState>({} as UnsavedChangesContextState);

// -- Public Component ---------------------------------------------------------

/**
 * Provides an "unsaved changes" context to the entire app to prevent users from
 * losing unsaved changes when navigating away from a page.
 */
export default function UnsavedChangesContextProvider({
  children,
}: {
  children: React.ReactNode;
}) {
  const location = useLocation();
  const navigate = useNavigate();

  const [ hasUnsavedChanges, setHasUnsavedChanges ] = useState(false);
  const [ whereTo, setWhereTo ] = useState<To | undefined>();
  const [ showUnsavedChangesAlert, setShowUnsavedChangesAlert ] = useState(false);

  /** Flags unsaved changes. */
  const flagChanges = useCallback(() => setHasUnsavedChanges(true), []);

  /** Un-flags unsaved changes. */
  const clearChangesFlag = useCallback(() => setHasUnsavedChanges(false), []);

  /** Callback handling the unsaved changes confirmation before navigation. */
  const onNav = useCallback((event: React.MouseEvent<HTMLAnchorElement>, to: To) => {
    if (!hasUnsavedChanges || location.pathname === to) {
      return;
    }

    event.preventDefault();

    setWhereTo(to);
    setShowUnsavedChangesAlert(true);
  }, [ hasUnsavedChanges, location.pathname ]);

  /** Callback which resets state on navigation confirmation. */
  const onConfirmNav = useCallback(() => {
    if (!whereTo) {
      console.error('No to value set for navigation in <UnsavedChangesContextState>');
    }

    setHasUnsavedChanges(false);
    setShowUnsavedChangesAlert(false);

    if (whereTo) {
      navigate(whereTo);
    }

    setWhereTo(undefined);
  }, [ navigate, whereTo ]);

  /** Callback which clears state on navigation cancel. */
  const onCancelNav = useCallback(() => {
    setWhereTo(undefined);
    setShowUnsavedChangesAlert(false);
  }, []);

  /** Handles the beforeunload event to prevent discarding unsaved changes. */
  const handleBeforeUnload = useCallback((event: BeforeUnloadEvent) => {
    if (hasUnsavedChanges) {
      event.preventDefault();
      event.returnValue = true;
    }
  }, [ hasUnsavedChanges ]);

  useEffect(() => {
    globalThis.window.addEventListener('beforeunload', handleBeforeUnload);

    return () => {
      globalThis.window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  });

  const settingsContext: UnsavedChangesContextState = useMemo(() => ({
    clearChangesFlag,
    flagChanges,
    onCancelNav,
    onConfirmNav,
    onNav,
    showUnsavedChangesAlert,
  }), [
    clearChangesFlag,
    flagChanges,
    onCancelNav,
    onConfirmNav,
    onNav,
    showUnsavedChangesAlert,
  ]);

  return (
    <UnsavedChangesContext.Provider value={settingsContext}>
      {children}
    </UnsavedChangesContext.Provider>
  );
}
