// -- Types --------------------------------------------------------------------

interface Path {
  about: '/about';
  areas: '/areas';
  loot: '/loot';
  maps: '/maps';
  privacy: '/privacy';
  releaseNotes: '/release-notes';
  roadmap: '/roadmap';
  root: '/';
  support: '/support';
}

export type AppPaths = typeof path[keyof typeof path];

// -- Config -------------------------------------------------------------------

/**
 * Application router paths.
 */
export const path: Readonly<Path> = {
  about: '/about',
  areas: '/areas',
  loot: '/loot',
  maps: '/maps',
  privacy: '/privacy',
  releaseNotes: '/release-notes',
  roadmap: '/roadmap',
  root: '/',
  support: '/support',
};
