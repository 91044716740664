import { Typography } from '@mui/material';
import { Fragment } from 'react';

import Fetching from '../../components/Display/Fetching';
import StaticPage from '../../components/Display/StaticPage';
import WidowFix from '../../components/Display/WidowFix';
import useFetchData from '../../components/hooks/useFetchData';
import { path } from '../../config/path';

// -- Types --------------------------------------------------------------------

export interface PrivacyCopy {
  body: string[];
  title?: string;
}

// -- Config -------------------------------------------------------------------

/** Path to the privacy data file. */
const privacyJsonPath = '/data/privacy.json';

// -- Public Component ---------------------------------------------------------

/**
 * Renders the privacy policy page.
 */
export default function PrivacyPage() {
  const { data: privacyCopy, ...fetchState } = useFetchData<PrivacyCopy[]>(privacyJsonPath);

  return (
    <StaticPage
      metaDescription="Mystic Waffle takes privacy seriously. Learn what data we collect to run the application."
      path={path.privacy}
      title="Privacy Policy"
    >
      <Fetching {...fetchState} />

      {privacyCopy &&
        <div>
          {privacyCopy.map(({ body, title }, i) => (
            <Fragment key={i}>
              {title &&
                <Typography
                  marginBottom={2.5}
                  variant="h2"
                >
                  {title}
                </Typography>
              }

              {body.map((paragraph, j) => (
                <p className="paragraph" key={`${i}:${j}`}>
                  <WidowFix>
                    {paragraph}
                  </WidowFix>
                </p>
              ))}
            </Fragment>
          ))}
        </div>
      }
    </StaticPage>
  );
}
