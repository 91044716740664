import { useEffect, useRef, useState } from 'react';

import { breakpoint } from '../../config';

// -- Types --------------------------------------------------------------------

type Viewport = 'lg' | 'sm';

// -- Config -------------------------------------------------------------------

const mediaQuery = `(width >= ${breakpoint}px)`;

// -- Public Hook --------------------------------------------------------------

/**
 * Checks the viewport width via `window.matchMedia()` against the breakpoint
 * config and returns a viewport size.
 */
export default function useViewport(): Viewport {
  const [ isLarge, setIsLarge ] = useState(globalThis.window.matchMedia(mediaQuery).matches);
  const matchMediaRef = useRef<MediaQueryList | null>(null);

  useEffect(() => {
    matchMediaRef.current = globalThis.window.matchMedia(mediaQuery);

    /** Updates state on match media change. */
    function checkMatch(event: MediaQueryListEvent): void {
      setIsLarge(event.matches);
    }

    matchMediaRef.current.addEventListener('change', checkMatch);

    return () => {
      matchMediaRef.current?.removeEventListener('change', checkMatch);
    };
  }, []);

  return isLarge ? 'lg' : 'sm';
}
