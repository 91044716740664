import { memo } from 'react';
import { Circle, Group } from 'react-konva';

import { cellPx, colors, detailPx, shadowOffset } from '../../../config/map';
import { DETAIL } from '../../../lib/matrix';

import type { Coordinates } from '../../../lib/matrix';

// -- Config -------------------------------------------------------------------

const halfCellPx = cellPx / 2;

const radius = cellPx / 6;

const shadowRadius = radius + (detailPx / 2);

// -- Memoized Components ------------------------------------------------------

const DetailsMemo = memo(Details);

// -- Public Component ---------------------------------------------------------

/**
 * Renders a column.
 */
export default function Column({
  coordinates: [ x, y ],
}: {
  coordinates: Coordinates;
}) {
  const xPx = x * cellPx;
  const yPx = y * cellPx;

  return (
    <Group
      data-id={`detail-${DETAIL.Column}`}
      data-theme="classic"
      x={xPx}
      y={yPx}
    >
      <DetailsMemo />
    </Group>
  );
}

// -- Private Components -------------------------------------------------------

/**
 * Renders column details.
 */
function Details() {
  return (
    <>
      <Circle
        fill={colors.shadow}
        offsetX={shadowOffset}
        offsetY={shadowOffset}
        radius={shadowRadius}
        x={halfCellPx}
        y={halfCellPx}
      />

      <Circle
        fill={colors.shadow}
        radius={radius}
        stroke={colors.border}
        strokeWidth={detailPx}
        x={halfCellPx}
        y={halfCellPx}
      />
    </>
  );
}
