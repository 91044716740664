import { Typography } from '@mui/material';

import FancyBox from '../../components/Display/FancyBox';
import Fetching from '../../components/Display/Fetching';
import StaticPage from '../../components/Display/StaticPage';
import WidowFix from '../../components/Display/WidowFix';
import useFetchData from '../../components/hooks/useFetchData';
import { path } from '../../config/path';

import styles from './styles.module.css';

// -- Types --------------------------------------------------------------------

export interface ReleaseNote {
  body?: string[];
  date: string;
  img?: boolean;
  notes?: string[];
  title: string;
  version: string;
}

// -- Config -------------------------------------------------------------------

/** Path to the release notes data file. */
const releaseNotesJsonPath = '/data/release-notes.json';

// -- Public Component ---------------------------------------------------------

/**
 * Renders the release notes page.
 */
export default function ReleaseNotesPage() {
  const { data: releaseNotes, ...fetchState } = useFetchData<ReleaseNote[]>(releaseNotesJsonPath);

  return (
    <StaticPage
      metaDescription="Release notes for Mystic Waffle's D&D/TTRPG dungeon, area, and loot generator."
      path={path.releaseNotes}
      title="Release Notes"
    >
      <Fetching {...fetchState} />

      {releaseNotes?.map(({ body, date, img, notes, title, version }) => (
        <Note
          body={body}
          date={date}
          key={version}
          notes={notes}
          showImg={img}
          title={title}
          version={version}
        />
      ))}
    </StaticPage>
  );
}

// -- Private Components -------------------------------------------------------

/**
 * Renders a release note.
 */
function Note({
  body,
  date,
  notes,
  showImg,
  title,
  version,
}: {
  body?: string[];
  date: string;
  notes?: string[];
  showImg?: boolean;
  title: string;
  version: string;
}) {
  return (
    <FancyBox
      className={styles.box}
      component="article"
      title={<WidowFix>{title}</WidowFix>}
      titleComponent="h2"
    >
      <Typography
        align="center"
        color="primary"
        component="p"
        fontWeight="600"
      >
        Version {version}
      </Typography>

      <Typography
        align="center"
        component="p"
        mb={2}
      >
        <time dateTime={date}>
          {new Date(date).toLocaleDateString('en-us', { day: 'numeric', month: 'short', year: 'numeric' })}
        </time>
      </Typography>

      {body?.map((paragraph, index) => (
        <p className="paragraph" key={index}>{paragraph}</p>
      ))}

      {notes &&
        <ul>
          {notes.map((note, index) => (
            <li key={index}>{note}</li>
          ))}
        </ul>
      }

      {showImg &&
        <img
          alt={`${title} Screenshot`}
          className={styles.screenshot}
          loading="lazy"
          src={`/img/release-notes/${version}.jpg`}
        />
      }
    </FancyBox>
  );
}
