import { memo } from 'react';
import { Group, Text } from 'react-konva';

import {
  cellPx,
  colors,
  fontFamilyTitle,
  fontSizeMapTitleLarge,
} from '../../../config/map';
import { DETAIL } from '../../../lib/matrix';

import type { Coordinates } from '../../../lib/matrix';

// -- Memoized Components ------------------------------------------------------

const DetailsMemo = memo(Details);

// -- Public Component ---------------------------------------------------------

/**
 * Renders a trap indicator.
 */
export default function Trap({
  coordinates: [ x, y ],
}: {
  coordinates: Coordinates;
}) {
  const xPx = x * cellPx;
  const yPx = y * cellPx;

  return (
    <Group
      data-id={`detail-${DETAIL.Trap}`}
      data-theme="classic"
      x={xPx}
      y={yPx}
    >
      <DetailsMemo />
    </Group>
  );
}

// -- Private Components -------------------------------------------------------

/**
 * Renders column details.
 */
function Details() {
  return (
    <Text
      align="center"
      fill={colors.shadow}
      fontFamily={fontFamilyTitle}
      fontSize={fontSizeMapTitleLarge}
      fontStyle="400"
      fontVariant="small-caps"
      height={cellPx}
      text="t"
      verticalAlign="middle"
      width={cellPx}
      wrap="word"
    />
  );
}
