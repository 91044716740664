import { useCallback, useState } from 'react';

import { AREA, DRAW_OPTION, drawOptionsBrush, TOOL } from '../../lib/matrix';
import useDimensionsObserver from '../hooks/useDimensionsObserver';
import useTabState from '../Interface/hooks/useTabState';
import Layout from '../Layout';
import Artwork from './Artwork';
import DebugDisplay from './DebugDisplay';
import useMapSelection from './hooks/useMapSelection';
import useRotate from './hooks/useRotate';
import InteractiveStage, { drawTools } from './InteractiveStage';
import Actions from './Interface/Actions';
import MapEditorOptions from './Interface/MapEditorOptions';

import styles from './index.module.css';

import type { Brush, Coordinates } from '../../lib/matrix';
import type { InteractiveMapState } from './hooks/useInteractiveMap';
import type { MapInfoState } from './hooks/useMapInfo';

// -- Public Component ---------------------------------------------------------

/**
 * Renders an interactive map.
 */
export default function MapCanvas({
  debugMatrix,
  instructions,
  isEmpty,
  mapInfo,
  matrix,
  matrixHeight,
  matrixWidth,
  onClearMap,
  onDownloadMap,
  onDraw,
  onErase,
  onExit,
  onFlipConnection,
  onRedo,
  onSetAreaInfo,
  onSetConnectionInfo,
  onSetDimensions,
  onSetShow,
  onSetTheme,
  onSetTitle,
  onUndo,
  onUpdateRegion,
  title,
}: MapInfoState & Omit<InteractiveMapState, 'currentHistoryEntry' | 'onDownloadMap'> & {
  isEmpty: boolean;
  onDownloadMap: () => void;
  onExit: () => void;
}) {
  const {
    height: stageHeight,
    ref: containerRef,
    width: stageWidth,
  } = useDimensionsObserver<HTMLDivElement>();

  const [ activeTool, setActiveTool ] = useState<TOOL>(TOOL.Draw);
  const [ activeBrush, setActiveBrush ] = useState<Brush>(AREA.Dungeon);
  const { activeTabIndex, onSetTab } = useTabState();

  const {
    onSelect,
    selectedRegionId,
  } = useMapSelection(matrix);

  const onChangeTool = useCallback((tool: TOOL, hover?: Coordinates) => {
    onSelect(); // Clear selection

    if (tool === TOOL.Select) {
      onDraw([], AREA.Dungeon, DRAW_OPTION.Free);
    }

    setActiveTool(tool);

    if (tool === TOOL.Draw && activeTabIndex !== 0) {
      onSetTab(0);
    }

    if (hover && drawTools.has(tool)) {
      tool === TOOL.Draw
        ? onDraw([ hover ], activeBrush, drawOptionsBrush[activeBrush][0])
        : onErase([ hover ], DRAW_OPTION.Free);
    }
  }, [ activeBrush, activeTabIndex, onDraw, onErase, onSetTab, onSelect ]);

  const onDrawWithBrush = useCallback((coordinates: Coordinates[], drawOption: DRAW_OPTION, options?: { commit: true }) => {
    onDraw(coordinates, activeBrush, drawOption, options);
  }, [ activeBrush, onDraw ]);

  const onChangeBrush = useCallback((brush: Brush, hover?: Coordinates) => {
    onChangeTool(TOOL.Draw);
    setActiveBrush(brush);

    if (hover) {
      onDraw([ hover ], brush, drawOptionsBrush[brush][0]);
    }
  }, [ onChangeTool, onDraw ]);

  const onClear = useCallback(() => {
    onChangeTool(TOOL.Draw);
    setActiveBrush(AREA.Dungeon);
    onClearMap();
  }, [ onClearMap, onChangeTool ]);

  const onRotate = useRotate({
    activeBrush,
    activeTool,
    connectionInstructions: instructions.connections,
    onFlipConnection,
    selectedRegionId,
  });

  return (
    <Layout
      sidebar={
        <MapEditorOptions
          activeBrush={activeTool === TOOL.Draw ? activeBrush : undefined}
          activeTabIndex={activeTabIndex}
          activeTool={activeTool}
          instructions={instructions}
          isMapEmpty={isEmpty}
          mapInfo={mapInfo}
          matrixHeight={matrixHeight}
          matrixWidth={matrixWidth}
          onChangeBrush={onChangeBrush}
          onSetAreaInfo={onSetAreaInfo}
          onSetConnectionInfo={onSetConnectionInfo}
          onSetDimensions={onSetDimensions}
          onSetShow={onSetShow}
          onSetTab={onSetTab}
          onSetTheme={onSetTheme}
          onSetTitle={onSetTitle}
          onUpdateRegion={onUpdateRegion}
          selectedRegionId={selectedRegionId}
          title={title}
        />
      }
    >
      <div className={styles.content}>
        <Actions
          activeBrush={activeBrush}
          activeTool={activeTool}
          isMapEmpty={isEmpty}
          onClearMap={onClear}
          onDownloadMap={onDownloadMap}
          onExit={onExit}
        />

        <div
          className={styles.mapCanvasContainer}
          ref={containerRef}
        >
          {Boolean(stageWidth) && Boolean(stageHeight) && (
            <InteractiveStage
              activeBrush={activeBrush}
              activeTool={activeTool}
              instructions={instructions}
              matrix={matrix}
              matrixHeight={matrixHeight}
              matrixWidth={matrixWidth}
              onChangeBrush={onChangeBrush}
              onChangeTool={onChangeTool}
              onDraw={onDrawWithBrush}
              onErase={onErase}
              onRedo={onRedo}
              onRotate={onRotate}
              onSelect={onSelect}
              onUndo={onUndo}
              selectedRegionId={selectedRegionId}
              stageHeight={stageHeight}
              stageWidth={stageWidth}
            >
              <Artwork
                {...mapInfo}
                instructions={instructions}
                matrixHeight={matrixHeight}
                matrixWidth={matrixWidth}
                title={title}
              />

              {debugMatrix && <DebugDisplay debugMatrix={debugMatrix} />}
            </InteractiveStage>
          )}
        </div>
      </div>
    </Layout>
  );
}
