import { useEffect } from 'react';

import { appDomain, brandName } from '../../config';
import { path as appPaths } from '../../config/path';

import type { AppPaths } from '../../config/path';

/**
 * Updates the page's meta data.
 */
export default function Metadata({
  description,
  path,
  title,
}: {
  description: string;
  path: AppPaths | '/404';
  title: string;
}) {
  useEffect(() => {
    setLink('canonical', path);
    setMeta('description', description);
    setTitle(title);
  }, [ description, path, title ]);

  return null;
}

// -- Private Functions --------------------------------------------------------

/**
 * Sets the document's title, creating the title tag if it doesn't exist.
 */
function setTitle(title: string): void {
  let tag = globalThis.document.querySelector('head title');

  if (!tag) {
    tag = globalThis.document.createElement('title');
    globalThis.document.head.prepend(tag);
  }

  tag.textContent = `${title} | ${brandName}`;
}

/**
 * Sets a meta tag's content, creating the meta tag if it doesn't exist.
 */
function setMeta(name: 'description', content: string): void {
  let meta = globalThis.document.querySelector(`head meta[name="${name}"]`);

  if (!meta) {
    meta = globalThis.document.createElement('meta');
    meta.setAttribute('name', name);
    globalThis.document.head.prepend(meta);
  }

  meta.setAttribute('content', content);
}

/**
 * Sets a document head link tag's content, creating the tag if it doesn't exist.
 */
function setLink(rel: 'canonical', path: string): void {
  let link = globalThis.document.querySelector(`head link[rel="${rel}"]`);

  if (!link) {
    link = globalThis.document.createElement('link');
    link.setAttribute('rel', rel);
    globalThis.document.head.prepend(link);
  }

  if (path === appPaths.root) {
    path = '';
  }

  link.setAttribute('href', `https://${appDomain}${path}`);
}
