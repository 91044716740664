import { themes } from '../../config/map';
import { CONNECTION, connectionsFlippable, connectionsLockable } from '../../lib/matrix';
import { isArea, isConnection } from '../../lib/matrix/utility';

import type { MatrixHistoryEntry } from '../matrix';

// -- Types --------------------------------------------------------------------

export type Theme = typeof themes[number];

export interface MapInfo {

  /** Region info keyed by matrix region id. */
  areaInfo: Record<number, RegionAreaInfo>;

  /** Connection info keyed by matrix region id. */
  connectionInfo: Record<number, RegionConnectionInfo>;

  /** Whether area numbers are displayed. */
  showAreaNumbers?: boolean;

  /** Whether the compass rose is displayed. */
  showCompass?: boolean;

  /** Whether the legend is displayed. */
  showLegend?: boolean;

  /** Whether the distance scale is displayed. */
  showScale?: boolean;

  /* The map's theme. */
  theme: Theme;
}

/**
 * Map snapshot for saving and restoring maps.
 */
export type MapSnapshot = MapInfo & MatrixHistoryEntry & {
  title?: string;
  v: number;
};

/** Area region info. */
export interface RegionAreaInfo {
  description?: string;
  title?: string;
}

/** Connection region info. */
export interface RegionConnectionInfo {
  flipped?: boolean;
  locked?: boolean;
}

// -- Public Functions ---------------------------------------------------------

/**
 * Filters out irrelevant map snapshot data: deleted regions, left over
 * connection properties, empty titles and descriptions, etc.
 */
export function filterMapSnapshot(snapshot: MapSnapshot): MapSnapshot {
  const filteredSnapshot: MapSnapshot = {
    /* eslint-disable perfectionist/sort-objects */
    v: snapshot.v,
    title: snapshot?.title || undefined,
    theme: snapshot.theme,
    dimensions: snapshot.dimensions,
    areaInfo: {},
    /* eslint-enable perfectionist/sort-objects */
    connectionInfo: {},
    details: snapshot?.details || undefined,
    regions: snapshot?.regions || undefined,
    showAreaNumbers: snapshot?.showAreaNumbers,
    showCompass: snapshot?.showCompass,
    showLegend: snapshot?.showLegend,
    showScale: snapshot?.showScale,
  };

  if (!snapshot?.regions) {
    return filteredSnapshot;
  }

  // Filter any deleted regions from map info.
  for (const { id, type } of snapshot.regions) {
    if (isArea(id)) {
      const areaInfo = filterAreaInfo(snapshot.areaInfo[id]);

      if (areaInfo) {
        filteredSnapshot.areaInfo[id] = areaInfo;
      }
    }

    if (isConnection(id)) {
      if (!snapshot.connectionInfo[id]) {
        continue;
      }

      // Filter out any left over connection info properties.
      const { flipped, locked, ...connectionProperties } = snapshot.connectionInfo[id];
      const connectionInfo: RegionConnectionInfo = { ...connectionProperties };

      if (flipped && connectionsFlippable.has(type as CONNECTION)) {
        connectionInfo.flipped = flipped;
      }

      if (locked && connectionsLockable.has(type as CONNECTION)) {
        connectionInfo.locked = locked;
      }

      if (Object.keys(connectionInfo).length) {
        filteredSnapshot.connectionInfo[id] = connectionInfo;
      }
    }
  }

  return filteredSnapshot;
}

// -- Private Functions --------------------------------------------------------

/**
 * Filters out empty area info.
 */
function filterAreaInfo({ description, title }: RegionAreaInfo = {}): RegionAreaInfo | null {
  const filteredAreaInfo: RegionAreaInfo = {};

  if (title?.trim()) {
    filteredAreaInfo.title = title;
  }

  if (description?.trim()) {
    filteredAreaInfo.description = description;
  }

  if (Object.keys(filteredAreaInfo).length) {
    return filteredAreaInfo;
  }

  return null;
}
