import { Fragment } from 'react';
import { Circle, Ellipse, Group } from 'react-konva';

import { cellPx, colors, detailPx } from '../../../config/map';
import { DETAIL } from '../../../lib/matrix';
import { getMaxRectangle, toCoordinatesMap } from '../../../lib/matrix/utility';

import type { Coordinates, Rect } from '../../../lib/matrix';

// -- Types --------------------------------------------------------------------
interface FountainProps {
  coordinates: Coordinates[];
}

// -- Config -------------------------------------------------------------------

const fountainRimWidth = cellPx * 0.1;

const centerRadius = cellPx * 0.12;

const fountainInset = cellPx * 0.2;

// -- Public Component ---------------------------------------------------------

/**
 * Renders a fountain.
 *
 * TDL define ratio for square fountains and non-square fountains. Add rounded
 * rect fountains in both long and irregular shapes.
 *
 * TODO tests
 * TDL memo
 */
export default function Fountain(props: { coordinates: Coordinates[] }) {
  const fountains = useFountainInstructions(props);

  return (
    <Group
      data-id={`detail-${DETAIL.Fountain}`}
      data-theme="classic"
    >
      {fountains.map(({ height, width, x: rectX, y: rectY }, i) => {
        let radiusX = width / 2;
        let radiusY = height / 2;

        const x = rectX + radiusX;
        const y = rectY + radiusY;

        radiusX -= fountainInset;
        radiusY -= fountainInset;

        return (
          <Fragment key={i}>
            <Ellipse
              fill={colors.regionDungeon}
              radiusX={radiusX}
              radiusY={radiusY}
              stroke={colors.border}
              strokeWidth={detailPx}
              x={x}
              y={y}
            />

            <Ellipse
              fill={colors.water}
              radiusX={radiusX - fountainRimWidth}
              radiusY={radiusY - fountainRimWidth}
              stroke={colors.border}
              strokeWidth={detailPx}
              x={x}
              y={y}
            />

            {(width > cellPx && height > cellPx) && (
              <Circle
                fill={colors.regionDungeon}
                radius={centerRadius}
                stroke={colors.border}
                strokeWidth={detailPx}
                x={x}
                y={y}
              />
            )}
          </Fragment>
        );
      })}
    </Group>
  );
}

// -- Private Hooks ------------------------------------------------------------

/**
 * Returns instructions for fountain placement and size.
 */
function useFountainInstructions({ coordinates }: FountainProps): Rect[] {
  const coordinatesMap = toCoordinatesMap(coordinates);
  const rects: Rect[] = [];

  /**
   * Recursively extracts rectangles from the coordinates map.
   */
  function recursivelyGetFountainRect() {
    const maxRectangle = getMaxRectangle(coordinatesMap);

    if (!maxRectangle) {
      return;
    }

    const { height, width, x, y } = maxRectangle;

    rects.push({
      height: height * cellPx,
      width: width * cellPx,
      x: x * cellPx,
      y: y * cellPx,
    });

    for (let cellX = x; cellX < x + width; cellX++) {
      for (let cellY = y; cellY < y + height; cellY++) {
        coordinatesMap.delete(`${cellX},${cellY}`);
      }
    }

    if (coordinatesMap.size) {
      recursivelyGetFountainRect();
    }
  }

  recursivelyGetFountainRect();

  return rects;
}
