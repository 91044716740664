import { Button, Typography } from '@mui/material';
import { alpha } from '@mui/material/styles';
import { Link as RouterLink } from 'react-router-dom';

import { appColors } from '../../config/color';

import styles from './BigJuicyButton.module.css';

import type { To } from 'react-router-dom';

// -- Config -------------------------------------------------------------------

/* Big juicy button styles. */
const buttonSx = {
  borderColor: alpha(appColors.primary, 0.4),
  display: 'flex',
  flexDirection: 'column',
  minHeight: '16rem',
  minWidth: '16rem',
};

// -- Public Component ---------------------------------------------------------

/**
 * Renders a big juicy button.
 */
export default function BigJuicyButton({
  icon: Icon,
  label,
  onClick,
  to,
}: {
  icon: React.ElementType;
  label: string;
  onClick?: () => void;
  to?: To;
}) {
  if (!to && !onClick) {
    throw new TypeError('`to` or `onClick` must be provided in <BigJuicyButton>');
  }

  return (
    <Button
      component={to ? RouterLink : 'button'}
      onClick={onClick}
      sx={buttonSx}
      to={to}
      variant="outlined"
    >
      <Icon
        aria-hidden
        className={styles.icon}
      />
      <Typography
        component="p"
        fontWeight="600"
        marginTop={0.5}
        textTransform="none"
        variant="h4"
      >
        {label}
      </Typography>
    </Button>
  );
}
