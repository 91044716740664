import { memo } from 'react';
import { Group, Line } from 'react-konva';

import {
  areaBorderPx,
  cellPx,
  colors,
} from '../../../config/map';
import { CONNECTION, connectionDirectionsMeridian } from '../../../lib/matrix';

import type { ConnectionDirection, Coordinates } from '../../../lib/matrix';
import type { ConnectionProps } from './';

// -- Config -------------------------------------------------------------------

const separatorInset = cellPx / 4;
const dashLength = cellPx / 30;
const dashSpace = cellPx / 5;

const indicatorPointsNorth = [ separatorInset, separatorInset, (cellPx - separatorInset), separatorInset ];
const indicatorPointsSouth = [ separatorInset, (cellPx - separatorInset), (cellPx - separatorInset), (cellPx - separatorInset) ];
const indicatorPointsEast = [ (cellPx - separatorInset), separatorInset, (cellPx - separatorInset), (cellPx - separatorInset) ];
const indicatorPointsWest = [ separatorInset, separatorInset, separatorInset, (cellPx - separatorInset) ];

// -- Public Component (Memoized) ----------------------------------------------

/**
 * Renders secret door details.
 */
export default memo(function SecretDoor(props: ConnectionProps) {
  const { direction, flipped, heightPx, widthPx, xPx, yPx } = props;

  const isMeridian = connectionDirectionsMeridian.has(direction);
  const segments = (isMeridian ? widthPx : heightPx) / cellPx;

  const indicatorCoordinates: Coordinates[] = [];
  for (let i = 0; i < segments; i++) {
    indicatorCoordinates.push(isMeridian
      ? [ i * cellPx, 0 ]
      : [ 0, i * cellPx ]
    );
  }

  return (
    <Group
      data-id={CONNECTION.SecretDoor}
      x={xPx}
      y={yPx}
    >
      {indicatorCoordinates.map(([ x, y ], i) => (
        <DoorIndicator
          direction={direction}
          flipped={flipped}
          key={i}
          xPx={x}
          yPx={y}
        />
      ))}
    </Group>
  );
});

// -- Private Components -------------------------------------------------------

/**
 * Renders secret door dashed line indicators.
 */
function DoorIndicator({ direction, flipped, xPx, yPx }: Pick<ConnectionProps, 'direction'
  | 'flipped'
  | 'xPx'
  | 'yPx'
>
) {
  const separatorLinePoints = getIndicatorPoints(direction, flipped);

  return (
    <Line
      dash={[ dashLength, dashSpace ]}
      lineCap="round"
      points={separatorLinePoints}
      stroke={colors.shadowDetail}
      strokeWidth={areaBorderPx}
      x={xPx}
      y={yPx}
    />
  );
}

// -- Private Functions --------------------------------------------------------

/**
 * Returns secret door indicator anchor points.
 */
function getIndicatorPoints(direction: ConnectionDirection, flipped?: boolean): number[] {
  switch (direction) {
    case 'north-south':
      return flipped
        ? indicatorPointsNorth
        : indicatorPointsSouth;

    case 'north':
      return indicatorPointsNorth;

    case 'south':
      return indicatorPointsSouth;

    case 'east-west':
      return flipped
        ? indicatorPointsEast
        : indicatorPointsWest;

    case 'east':
      return indicatorPointsEast;

    case 'west':
      return indicatorPointsWest;
  }
}
