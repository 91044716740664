import { Button, Toolbar } from '@mui/material';
import { useContext } from 'react';
import { Link as RouterLink } from 'react-router-dom';

import { UnsavedChangesContext } from '../../components/Utility/UnsavedChangesContextProvider';
import { appTitle } from '../../config';
import { path } from '../../config/path';
import Logo from '../Display/Logo';
import Drawer from './Drawer';
import MainNav from './MainNav';

import styles from './Header.module.css';

// -- Config -------------------------------------------------------------------

const logoStyle = {
  '&:hover': {
    background: 'none',
  },
  fontFamily: 'h1.fontFamily',
  fontSize: '1.1rem',
  fontWeight: 600,
  letterSpacing: '0.05rem',
  pl: 0,
  pr: '0.75rem',
  textTransform: 'none',
  textWrap: 'nowrap',
};

// -- Public Component ---------------------------------------------------------

/**
 * Renders the application header bar.
 */
export default function Header({ className }: { className: string }) {
  const { onNav } = useContext(UnsavedChangesContext);

  return (
    <header
      className={`${styles.header} ${className}`}
      role="banner"
    >
      <Toolbar className={styles.toolbar} disableGutters>
        <Button
          color="inherit"
          component={RouterLink}
          onClick={(event) => onNav(event, path.root)}
          sx={logoStyle}
          to={path.root}
        >
          <Logo aria-hidden className={styles.logo} />
          <span className={styles.logoText}>{appTitle}</span>
        </Button>

        <MainNav className="hide-sm" />
        <Drawer className="hide-lg" />
      </Toolbar>
    </header>
  );
}
