// -- Types --------------------------------------------------------------------

type Minimums = Map<string, number>;

export type NumericRange = [
  min: number,
  max: number
];

// -- Public Functions ---------------------------------------------------------

/**
 * Returns an object of numerical ranges based on the given `minimums` Map.
 */
export function createRangeLookup(minimums: Minimums, options: { maximum?: number } = {}): Record<string, NumericRange> {
  const { maximum = Number.POSITIVE_INFINITY } = options;

  if (!minimums.size) {
    throw new TypeError('Minimums cannot be an empty map in createRangeLookup()');
  }

  return [ ...minimums.entries() ].reduce((ranges, quantity, index, entries) => {
    const [ key, min ] = quantity;

    const next = index + 1;
    const max = entries[next] ? entries[next][1] - 1 : maximum;

    if (max < min) {
      throw new TypeError(`Range value cannot be less than the previous value in createRangeLookup(), key="${key}"`);
    }

    ranges[key] = [ min, max ];

    return ranges;
  }, {} as Record<string, NumericRange>);
}
