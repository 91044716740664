import { cellPx } from '../../config/map';
import {
  CONNECTION,
  connectionDirectionsMeridian,
  directionsCardinal,
} from './';

import type {
  ConnectionDirection,
  Coordinates,
  DirectionCardinal,
  Path,
  Rect,
} from './';

// -- Types --------------------------------------------------------------------

interface PathModifies { flipped: boolean }

// -- Config -------------------------------------------------------------------

/** Distance an indented connection is inset from the cell's edge in pixels. */
export const connectionInsetPx = cellPx / 5;

/** Distance an indented connection pops-out from an area in pixels. */
const connectionPopOutPx = cellPx / 4;

/** Distance a secret door is inset from the area's edge in pixels. */
const connectionSecretDoorInsetPx = cellPx / 2;

/** Connection types which have an indented outline, doors, passages, etc. */
const connectionTypesIndented = [
  CONNECTION.WoodDoor,
  CONNECTION.Passageway,
];

// -- Public Functions ---------------------------------------------------------

/**
 * Returns a single cell's path in pixel units.
 */
export function getCellPath(coordinates: Coordinates): Path {
  let [ x, y ] = coordinates;

  x = x * cellPx;
  y = y * cellPx;

  return [
    [ x, y ],
    [ x + cellPx, y ],
    [ x + cellPx, y + cellPx ],
    [ x, y + cellPx ],
  ];
}

/**
 * Returns an expanded single cell's path in pixel units.
 */
export function getCellPathExpanded(coordinates: Coordinates, { expand }: { expand: number }): Path {
  let [ x, y ] = coordinates;

  x = x * cellPx;
  y = y * cellPx;

  return [
    [
      x - expand,
      y - expand,
    ],
    [
      x + cellPx + expand,
      y - expand,
    ],
    [
      x + cellPx + expand,
      y + cellPx + expand,
    ],
    [
      x - expand,
      y + cellPx + expand,
    ],
  ];
}

/**
 * Returns a connections border paths.
 */
export function getConnectionBorderPaths(
  rect: Rect,
  direction: ConnectionDirection,
  type: CONNECTION,
  modifiers: PathModifies
): Path[] {
  const isEdge = directionsCardinal.has(direction as DirectionCardinal);

  if (isEdge) {
    return getConnectionPathsBasic(direction, rect);
  }

  if (type === CONNECTION.SecretDoor) {
    return getConnectionPathsSecretDoor(direction, rect, modifiers);
  }

  if (connectionTypesIndented.includes(type)) {
    return getConnectionPathsIndented(direction, rect);
  }

  return getConnectionPathsBasic(direction, rect);
}

/**
 * Returns a path for the given rectangle.
 */
export function getRectanglePath({ height, width, x, y }: Rect): Path {
  return [
    [ x, y ],
    [ x + width, y ],
    [ x + width, y + height ],
    [ x, y + height ],
  ];
}

// -- Private Functions --------------------------------------------------------

/**
 * Returns basic connection paths; a line on each side.
 */
function getConnectionPathsBasic(
  direction: ConnectionDirection,
  rect: Rect
): Path[] {
  const { height, width, x, y } = rect;

  if (connectionDirectionsMeridian.has(direction)) {
    return [
      [
        [ x, y ],
        [ x, (y + height) ],
      ], [
        [ (x + width), (y + height) ],
        [ (x + width), y ],
      ],
    ];
  }

  return [
    [
      [ x, y ],
      [ (x + width), y ],
    ], [
      [ (x + width), (y + height) ],
      [ x, (y + height) ],
    ],
  ];
}

/**
 * Returns an indented connection path.
 */
function getConnectionPathsIndented(
  direction: ConnectionDirection,
  rect: Rect
): Path[] {
  const { height, width, x, y } = rect;

  switch (direction) {
    case 'north-south':
      return [
        [
          [ (x + width), y ],
          [ (x + width), (y + connectionPopOutPx) ],
          [ (x + width - connectionInsetPx), (y + connectionPopOutPx) ],
          [ (x + width - connectionInsetPx), (y + cellPx - connectionPopOutPx) ],
          [ (x + width), (y + cellPx - connectionPopOutPx) ],
          [ (x + width), (y + cellPx) ],
        ], [
          [ x, (y + cellPx) ],
          [ x, (y + cellPx - connectionPopOutPx) ],
          [ (x + connectionInsetPx), (y + cellPx - connectionPopOutPx) ],
          [ (x + connectionInsetPx), (y + connectionPopOutPx) ],
          [ x, (y + connectionPopOutPx) ],
          [ x, y ],
        ],
      ];

    case 'east-west':
      return [
        [
          [ x, y ],
          [ (x + connectionPopOutPx), y ],
          [ (x + connectionPopOutPx), (y + connectionInsetPx) ],
          [ (x + cellPx - connectionPopOutPx), (y + connectionInsetPx) ],
          [ (x + cellPx - connectionPopOutPx), y ],
          [ (x + cellPx), y ],
        ], [
          [ (x + cellPx), (y + height) ],
          [ (x + cellPx - connectionPopOutPx), (y + height) ],
          [ (x + cellPx - connectionPopOutPx), (y + height - connectionInsetPx) ],
          [ (x + connectionPopOutPx), (y + height - connectionInsetPx) ],
          [ (x + connectionPopOutPx), (y + height) ],
          [ x, (y + height) ],
        ],
      ];

    default:
      throw new TypeError(`Invalid direction "${direction}" in getConnectionPathsIndented()`);
  }
}

/**
 * Returns a secret door connection path.
 */
function getConnectionPathsSecretDoor(
  direction: ConnectionDirection,
  rect: Rect,
  { flipped }: PathModifies
): Path[] {
  const { height, width, x, y } = rect;

  switch (direction) {
    case 'north-south':
      if (flipped) {
        return [
          [
            [ x, (y + height) ],
            [ x, (y + connectionSecretDoorInsetPx) ],
            [ (x + width), (y + connectionSecretDoorInsetPx) ],
            [ (x + width), (y + height) ],
          ],
        ];
      }

      return [
        [
          [ x, y ],
          [ x, (y + height - connectionSecretDoorInsetPx) ],
          [ (x + width), (y + height - connectionSecretDoorInsetPx) ],
          [ (x + width), y ],
        ],
      ];

    case 'east-west':
      if (flipped) {
        return [
          [
            [ x, y ],
            [ (x + connectionSecretDoorInsetPx), y ],
            [ (x + connectionSecretDoorInsetPx), (y + height) ],
            [ x, (y + height) ],
          ],
        ];
      }

      return [
        [
          [ x + width, y ],
          [ (x + connectionSecretDoorInsetPx), y ],
          [ (x + connectionSecretDoorInsetPx), (y + height) ],
          [ (x + width), (y + height) ],
        ],
      ];

    default:
      throw new TypeError(`Invalid direction "${direction}" in getConnectionPathsSecretDoor()`);
  }
}
