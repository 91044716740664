import { useEffect, useState } from 'react';

/**
 * Fetches json from the server and parses it, returning a loading state, a
 * possible error state, and parsed json data.
 */
export default function useFetchData<StaticData>(path: string): {
  data?: StaticData;
  isError: boolean;
  isLoading: boolean;
} {
  const [ isLoading, setIsLoading ] = useState(false);
  const [ isError, setIsError ] = useState(false);
  const [ data, setData ] = useState<StaticData>();

  useEffect(() => {
    if (isError || isLoading || typeof data !== 'undefined') {
      return;
    }

    /** Fetches and parses json data. */
    async function fetchData() {
      setIsLoading(true);

      let result;

      try {
        const response = await globalThis.fetch(path);
        result = await response.json() as StaticData;
      } catch {
        setIsError(true);
        setIsLoading(false);
        return;
      }

      setData(result);
      setIsLoading(false);
    }

    void fetchData();
  }, [
    data,
    isError,
    isLoading,
    path,
  ]);

  return {
    data,
    isError,
    isLoading,
  };
}
