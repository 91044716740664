import { memo } from 'react';

import { DETAIL } from '../../../../lib/matrix';
import PixelArt from './';
import Cell from './Cell';

import type { Coordinates } from '../../../../lib/matrix';
import type { Pixels } from './';

// -- Config -------------------------------------------------------------------

/** Rubble pixels: v1 */
const pixels: Pixels = [
  [ 'black', [[ 14, 18 ], [ 17, 18 ], [ 17, 19 ], [ 14, 19 ]], [[ 18, 16 ], [ 18, 18 ], [ 17, 18 ], [ 17, 16 ]], [[ 14, 18 ], [ 13, 18 ], [ 13, 17 ], [ 14, 17 ]], [[ 15, 17 ], [ 14, 17 ], [ 14, 16 ], [ 15, 16 ]], [[ 15, 15 ], [ 17, 15 ], [ 17, 16 ], [ 15, 16 ]], [[ 7, 6 ], [ 5, 6 ], [ 5, 7 ], [ 4, 7 ], [ 4, 8 ], [ 3, 8 ], [ 3, 9 ], [ 4, 9 ], [ 4, 11 ], [ 5, 11 ], [ 5, 12 ], [ 6, 12 ], [ 6, 13 ], [ 8, 13 ], [ 8, 14 ], [ 13, 14 ], [ 13, 13 ], [ 15, 13 ], [ 15, 12 ], [ 16, 12 ], [ 16, 9 ], [ 15, 9 ], [ 15, 7 ], [ 14, 7 ], [ 14, 5 ], [ 12, 5 ], [ 12, 4 ], [ 10, 4 ], [ 10, 5 ], [ 7, 5 ]], [[ 13, 10 ], [ 15, 10 ], [ 15, 12 ], [ 14, 12 ], [ 14, 11 ], [ 13, 11 ]], [[ 13, 10 ], [ 12, 10 ], [ 12, 9 ], [ 13, 9 ]], [[ 6, 9 ], [ 5, 9 ], [ 5, 8 ], [ 6, 8 ]], [[ 14, 7 ], [ 14, 9 ], [ 13, 9 ], [ 13, 7 ]], [[ 9, 7 ], [ 8, 7 ], [ 8, 6 ], [ 9, 6 ]], [[ 13, 7 ], [ 12, 7 ], [ 12, 6 ], [ 13, 6 ]], [[ 14, 4 ], [ 15, 4 ], [ 15, 6 ], [ 16, 6 ], [ 16, 8 ], [ 17, 8 ], [ 17, 13 ], [ 16, 13 ], [ 16, 14 ], [ 14, 14 ], [ 14, 15 ], [ 7, 15 ], [ 7, 14 ], [ 5, 14 ], [ 5, 13 ], [ 4, 13 ], [ 4, 12 ], [ 3, 12 ], [ 3, 10 ], [ 2, 10 ], [ 2, 7 ], [ 3, 7 ], [ 3, 6 ], [ 4, 6 ], [ 4, 5 ], [ 6, 5 ], [ 6, 4 ], [ 9, 4 ], [ 9, 3 ], [ 14, 3 ]]],
  [ 'gray10', [[ 13, 9 ], [ 12, 9 ], [ 12, 10 ], [ 13, 10 ], [ 13, 11 ], [ 12, 11 ], [ 12, 12 ], [ 11, 12 ], [ 11, 13 ], [ 9, 13 ], [ 9, 12 ], [ 5, 12 ], [ 5, 11 ], [ 4, 11 ], [ 4, 9 ], [ 3, 9 ], [ 3, 8 ], [ 4, 8 ], [ 4, 7 ], [ 5, 7 ], [ 5, 6 ], [ 7, 6 ], [ 7, 5 ], [ 12, 5 ], [ 12, 7 ], [ 13, 7 ]], [[ 6, 10 ], [ 7, 10 ], [ 7, 8 ], [ 5, 8 ], [ 5, 9 ], [ 6, 9 ]], [[ 10, 6 ], [ 8, 6 ], [ 8, 7 ], [ 10, 7 ]]],
  [ 'gray20', [[ 15, 18 ], [ 14, 18 ], [ 14, 17 ], [ 15, 17 ]], [[ 16, 17 ], [ 15, 17 ], [ 15, 16 ], [ 16, 16 ]], [[ 14, 12 ], [ 13, 12 ], [ 13, 13 ], [ 11, 13 ], [ 11, 12 ], [ 12, 12 ], [ 12, 11 ], [ 14, 11 ]], [[ 6, 12 ], [ 9, 12 ], [ 9, 13 ], [ 6, 13 ]], [[ 14, 10 ], [ 13, 10 ], [ 13, 9 ], [ 14, 9 ]], [[ 13, 6 ], [ 12, 6 ], [ 12, 5 ], [ 13, 5 ]], [[ 11, 5 ], [ 10, 5 ], [ 10, 4 ], [ 11, 4 ]]],
  [ 'gray30', [[ 15, 19 ], [ 17, 19 ], [ 17, 20 ], [ 15, 20 ]], [[ 18, 19 ], [ 17, 19 ], [ 17, 18 ], [ 18, 18 ]], [[ 15, 18 ], [ 15, 17 ], [ 16, 17 ], [ 16, 16 ], [ 17, 16 ], [ 17, 18 ]], [[ 19, 18 ], [ 18, 18 ], [ 18, 17 ], [ 19, 17 ]], [[ 16, 13 ], [ 17, 13 ], [ 17, 15 ], [ 15, 15 ], [ 15, 16 ], [ 7, 16 ], [ 7, 15 ], [ 14, 15 ], [ 14, 14 ], [ 16, 14 ]], [[ 5, 14 ], [ 7, 14 ], [ 7, 15 ], [ 5, 15 ]], [[ 5, 14 ], [ 4, 14 ], [ 4, 13 ], [ 5, 13 ]], [[ 8, 13 ], [ 13, 13 ], [ 13, 14 ], [ 8, 14 ]], [[ 18, 10 ], [ 18, 13 ], [ 17, 13 ], [ 17, 10 ]], [[ 13, 12 ], [ 15, 12 ], [ 15, 13 ], [ 13, 13 ]], [[ 14, 10 ], [ 14, 7 ], [ 15, 7 ], [ 15, 9 ], [ 16, 9 ], [ 16, 12 ], [ 15, 12 ], [ 15, 10 ]], [[ 7, 8 ], [ 7, 10 ], [ 6, 10 ], [ 6, 8 ]], [[ 10, 7 ], [ 9, 7 ], [ 9, 6 ], [ 10, 6 ]], [[ 14, 5 ], [ 14, 7 ], [ 13, 7 ], [ 13, 5 ]], [[ 12, 5 ], [ 11, 5 ], [ 11, 4 ], [ 12, 4 ]]],
];

// -- Memoized Components ------------------------------------------------------

const DetailsMemo = memo(Details);

// -- Public Component ---------------------------------------------------------

/**
 * Renders a pixel art rubble.
 */
export default function Rubble({ coordinates }: { coordinates: Coordinates }) {
  return (
    <Cell
      coordinates={coordinates}
      type={DETAIL.Rubble}
    >
      <DetailsMemo />
    </Cell>
  );
}

// -- Private Components -------------------------------------------------------

/** Renders pixels. */
function Details() {
  return <PixelArt pixels={pixels} />;
}
