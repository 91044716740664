import { discordInviteUrl } from '../../config';
import LoadingIndicator from '../Display/LoadingIndicator';
import WarningBox from '../Display/WarningBox';
import Link from '../Interface/Link';

import styles from './Fetching.module.css';

import type useFetchData from '../hooks/useFetchData';

type FetchingProps = Pick<ReturnType<typeof useFetchData>, 'isError' | 'isLoading'>;

/**
 * Renders a loading indicator and error message for fetching data.
 */
export default function Fetching({ isError, isLoading }: FetchingProps) {
  return (
    <>
      {isError &&
        <WarningBox className={styles.fetchError} role="alert">
          <p>
            Oh no! Goblins have hacked into the JavaScript and the page can’t be loaded!
            Please report this error to AJ on <Link href={discordInviteUrl}>Discord</Link>.
          </p>
        </WarningBox>
      }

      {isLoading &&
        <div className={styles.fetchStatus}>
          <LoadingIndicator />
        </div>
      }
    </>
  );
}
