import type Konva from 'konva';

/**
 * Extracts the data URL from a Konva stage ref.
 *
 * Wrapper function for easy mocking in tests.
 */
export default function getDataUrl(stageRef: React.RefObject<Konva.Stage>): string | undefined {
  return stageRef.current?.toDataURL();
}
