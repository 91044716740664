import { Button, FormHelperText } from '@mui/material';
import { useState } from 'react';

import {
  CONNECTION,
  connections,
  connectionsLockable,
} from '../../../lib/matrix';
import { isConnectionFlippable } from '../../../lib/matrix/utility';
import LabeledSwitch from '../../Input/LabeledSwitch';
import Select from '../../Input/Select';
import InputPanel from '../../Interface/InputPanel';

import type { RegionConnectionInfo } from '../../../lib/map';
import type {
  ConnectionDirection,
  MatrixInstructionsConnection,
} from '../../../lib/matrix';
import type { InteractiveMapState } from '../hooks/useInteractiveMap';
import type { MapInfoState } from '../hooks/useMapInfo';

// -- Types --------------------------------------------------------------------

interface ConnectionEditorInputsProps {
  direction: ConnectionDirection;
  info?: RegionConnectionInfo;
}

// -- Config -------------------------------------------------------------------

/** Flip direction button styles. */
const flipButtonSx = {
  justifyContent: 'space-between',
  paddingRight: '0.5rem',
};

// -- Public Component ---------------------------------------------------------

/**
 * Renders the map connection editor.
 */
export default function ConnectionEditorInputs({
  connectionId,
  direction,
  info,
  onSetConnectionInfo,
  onUpdateRegion,
  type,
}: ConnectionEditorInputsProps
  & Pick<InteractiveMapState, 'onUpdateRegion'>
  & Pick<MapInfoState, 'onSetConnectionInfo'>
  & Pick<MatrixInstructionsConnection, 'connectionId' | 'type'>
) {
  const [ showInfo, setShowInfo ] = useState(false);
  const showDirectionToggle = isConnectionFlippable(type, direction);

  return (
    <InputPanel
      aria-label="Connection settings"
      onSetShowInfo={setShowInfo}
      showInfo={showInfo}
    >
      <Select
        infoText="Change connection type."
        items={[ ...connections ]}
        label="Type"
        onChange={(newType) => onUpdateRegion(connectionId, { type: newType as CONNECTION })}
        showInfo={showInfo}
        value={type}
      />

      {connectionsLockable.has(type) &&
        <LabeledSwitch
          infoText="This should slow them down."
          isChecked={info?.locked || false}
          label="Locked"
          onChange={(checked: boolean) => onSetConnectionInfo(connectionId, { locked: checked })}
          showInfo={showInfo}
        />
      }

      {showDirectionToggle &&
        <ConnectionDirectionToggle
          connectionId={connectionId}
          isFlipped={info?.flipped}
          onSetConnectionInfo={onSetConnectionInfo}
          showInfo={showInfo}
        />
      }
    </InputPanel>
  );
}

// -- Private Components -------------------------------------------------------

/**
 * Renders the connection direction toggle button.
 */
function ConnectionDirectionToggle({
  connectionId,
  isFlipped,
  onSetConnectionInfo,
  showInfo,
}: Pick<MapInfoState, 'onSetConnectionInfo'> & {
  connectionId: number;
  isFlipped?: boolean;
  showInfo: boolean;
}) {
  return (
    <div>
      <Button
        aria-describedby="flip-direction-toggle"
        fullWidth
        onClick={() => onSetConnectionInfo(connectionId, { flipped: !isFlipped })}
        sx={flipButtonSx}
        variant="outlined"
      >
        <span>Flip Direction</span>
        <kbd>R</kbd>
      </Button>

      {showInfo &&
        <FormHelperText id="flip-direction-toggle">
          Toggle connection direction.
        </FormHelperText>
      }
    </div>
  );
}
