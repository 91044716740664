/**
 * Extracts and attempts to parse JSON from a file input.
 *
 * @example
 *
 * <Button>
 *   Import
 *   <VisuallyHiddenFileInput
 *     accept="application/json"
 *     onChange={(event) => readFile(event, {
 *       onImportError,
 *       onImportSuccess,
 *     })}
 *   />
 * </Button>
 */
export async function readJsonFile<JsonData>(event: React.ChangeEvent<HTMLInputElement>, {
  onImportError,
  onImportSuccess,
}: {
  onImportError: (message: string) => void;
  onImportSuccess: (data: JsonData) => void;
}) {
  const file = event.currentTarget.files?.[0];

  if (!file) {
    onImportError('No file was selected. Weird.');
    return;
  }

  const content = await file.text();

  let data: JsonData;

  try {
    data = JSON.parse(content) as JsonData;
  } catch (error) {
    onImportError('This file could not be parsed as JSON. Your file is corrupt!');
    return;
  }

  onImportSuccess(data);
}
