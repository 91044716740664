import {
  Checkbox,
  FormControl,
  FormHelperText,
  InputLabel,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select,
  Typography,
} from '@mui/material';
import { isValidElement, useCallback, useId, useState } from 'react';

import { capitalize, toWords } from '../../lib/string';

import type { MenuProps as MenuPropsType, SelectChangeEvent } from '@mui/material';

// -- Config -------------------------------------------------------------------

/** Checkbox menu item styles. */
const checkboxMenuItemSx = { p: 0 };

/** Multiselect's inner menu component props.  */
const menuProps: Partial<MenuPropsType> = {
  // Prevents selected items from impacting the initial focus.
  variant: 'menu',
};

/** Action item menu item styles. */
const actionItemSx = { px: 1.5, py: 1 };

// -- Public Component ---------------------------------------------------------

/**
 * Renders a controlled multiselect menu with select all and clear all options.
 *
 * `items` and `selectedItems`, and thus the return value of `onChange`, should
 * be camel case strings which are converted to spaced title case for display.
 */
export default function Multiselect({
  allowEmpty,
  infoText,
  items,
  label,
  onChange,
  selectedItems,
  showInfo,
}: {
  allowEmpty?: boolean;
  infoText: React.ReactNode;
  items: string[] | readonly string[];
  label: string;
  onChange: (newItems: string[]) => void;
  selectedItems: string[];
  showInfo: boolean;
}) {
  const id = useId();
  const labelId = `multiselect-label-${id}`;
  const infoTextId = `multiselect-desc-${id}`;

  const [ isOpen, setIsOpen ] = useState(false);

  /** Handles the menu close event. */
  const handleClose = useCallback(() => setIsOpen(false), []);

  /** Handles the menu open event. */
  const handleOpen = useCallback(() => setIsOpen(true), []);

  /** Handles select menu change events. */
  const onSelectionChange = useCallback((
    event: SelectChangeEvent<string[]>,
    child: React.ReactNode
  ) => {
    if (isValidElement<{ 'aria-readonly': boolean }>(child) && child.props['aria-readonly']) {
      return;
    }

    const newItems = event.target.value as string[];
    onChange(newItems);
  }, [ onChange ]);

  /** Renders the select menu's label based on the current selection. */
  const renderValue = useCallback((selected: string[]) => {
    if (!selected.length) {
      return 'None';
    }

    if (selected.length === items.length) {
      return 'All';
    }

    if (selected.length < 4) {
      return selected.map((item) => capitalize(toWords(item))).join(', ');
    }

    return `${selected.length} Selected`;
  }, [ items ]);

  const isError = !allowEmpty && !selectedItems.length;
  const isAllSelected = items.length === selectedItems.length;

  let helperText;

  if (isError) {
    helperText = 'Select at least one item';
  } else if (showInfo) {
    helperText = infoText;
  }

  return (
    <div>
      <FormControl
        error={isError}
        fullWidth
      >
        <InputLabel id={labelId} shrink>
          {label}
        </InputLabel>

        <Select
          aria-describedby={helperText ? infoTextId : undefined}
          displayEmpty
          input={<OutlinedInput label={label} notched />}
          labelId={labelId}
          MenuProps={menuProps}
          multiple
          onChange={onSelectionChange}
          onClose={handleClose}
          onOpen={handleOpen}
          open={isOpen}
          renderValue={renderValue}
          value={selectedItems}
        >
          <MenuItem
            aria-readonly
            onClick={handleClose}
            sx={actionItemSx}
          >
            <Typography
              color="primary"
              fontSize="0.75rem"
              variant="button"
            >
              Close Menu
            </Typography>
          </MenuItem>

          <MenuItem
            aria-readonly
            divider
            onClick={() => isAllSelected ? onChange([]) : onChange([ ...items ])}
            sx={actionItemSx}
          >
            <Typography
              color="primary"
              fontSize="0.75rem"
              variant="button"
            >
              {isAllSelected ? 'Clear All' : 'Select All'}
            </Typography>
          </MenuItem>

          {items.map((item) => (
            <MenuItem
              key={item}
              sx={checkboxMenuItemSx}
              value={item}
            >
              <Checkbox checked={selectedItems.includes(item)} />
              <ListItemText primary={capitalize(toWords(item))} />
            </MenuItem>
          ))}
        </Select>

        {helperText &&
          <FormHelperText id={infoTextId}>
            {helperText}
          </FormHelperText>
        }
      </FormControl>
    </div>
  );
}
