import { Line, Path, Rect as Rectangle, Text } from 'react-konva';

import { canvasUiColors } from '../../config/color';
import {
  areaBorderPx,
  cellPx,
  colors,
  fontSizeMapTitleTiny,
  previewExpandPx,
  previewOpacity,
  previewSelectionOpacity,
} from '../../config/map';
import { getMapEntryRequired } from '../../lib';
import {
  CONNECTION,
  Coordinates,
  DETAIL,
  DRAW_OPTION,
  TOOL,
} from '../../lib/matrix';
import { getCellPath, getCellPathExpanded } from '../../lib/matrix/path';
import {
  getCellValue,
  getLineCoordinates,
  getRectangleCoordinates,
  isArea,
  isConnection,
  isHorizontalLine,
} from '../../lib/matrix/utility';
import { unionPaths } from '../../lib/polygon';
import RegionShape from './RegionShape';

import type { Brush, CellValue, MatrixImmutable, MatrixInstructions, Rect } from '../../lib/matrix';

// -- Types --------------------------------------------------------------------

interface InteractivePreviewProps {

  /** The current brush. */
  activeBrush: Brush;

  /** The current draw option. */
  activeDrawOption?: DRAW_OPTION;

  /** The current tool. */
  activeTool: TOOL;

  /** Array of coordinates for an active draw. */
  draw: Coordinates[];

  /** Id of a hovered region. */
  highlightRegionId?: CellValue;

  /** Coordinates of the hovered cell, or null if the hover is out of bounds. */
  hover?: Coordinates;

  /** Map instructions. */
  instructions: MatrixInstructions;

  /** Current matrix state. */
  matrix: MatrixImmutable;

  /** Id of the currently selected region. */
  selectedRegionId: CellValue;
}

// -- Config -------------------------------------------------------------------

const invalidIndicatorInsetPx = cellPx / 3;

/** Data paths for the invalid cell indicator "X". */
const invalidIndicatorPath = `
  M${invalidIndicatorInsetPx}, ${invalidIndicatorInsetPx}, ${cellPx - invalidIndicatorInsetPx}, ${cellPx - invalidIndicatorInsetPx},
  M${invalidIndicatorInsetPx}, ${cellPx - invalidIndicatorInsetPx}, ${cellPx - invalidIndicatorInsetPx}, ${invalidIndicatorInsetPx},
`;

// -- Public Component ---------------------------------------------------------

/**
 * Renders a preview of the current map interaction, including hover, draw, and
 * selected regions.
 */
export default function InteractivePreview({
  activeBrush,
  activeDrawOption,
  activeTool,
  draw,
  highlightRegionId,
  hover,
  instructions,
  matrix,
  selectedRegionId,
}: InteractivePreviewProps) {
  if (selectedRegionId || highlightRegionId) {
    return (
      <>
        {selectedRegionId &&
          <HighlightRegion
            instructions={instructions}
            isSelected
            regionId={selectedRegionId}
          />
        }

        {highlightRegionId && highlightRegionId !== selectedRegionId &&
          <HighlightRegion
            instructions={instructions}
            regionId={highlightRegionId}
          />
        }
      </>
    );
  }

  if (!activeDrawOption || !hover) {
    return null;
  }

  const isDraw = draw.length;

  return (
    <>
      {isDraw
        ? (
          <DrawPreview
            activeDrawOption={activeDrawOption}
            draw={draw}
          />
        ) : (
          <HoverPreview
            coordinates={hover}
            instructions={instructions}
            matrix={matrix}
          />
        )
      }

      {activeTool === TOOL.Draw &&
        <InvalidCells
          activeBrush={activeBrush}
          activeDrawOption={activeDrawOption}
          coordinates={isDraw ? draw : [ hover ]}
          matrix={matrix}
        />
      }
    </>
  );
}

// -- Private Components -------------------------------------------------------

/**
 * Renders a draw preview.
 */
function DrawPreview({ activeDrawOption, draw }: {
  activeDrawOption: DRAW_OPTION;
  draw: Coordinates[];
}) {
  if (activeDrawOption === DRAW_OPTION.Rectangle || activeDrawOption === DRAW_OPTION.Line) {
    const [ start, end ] = activeDrawOption === DRAW_OPTION.Line
      ? getPreviewLine(draw)
      : draw;

    const rect = getPreviewRect(start, end);

    return (
      <>
        <RectangleDrawTooltip
          end={end}
          start={start}
        />

        <Rectangle
          {...rect}
          lineCap="round"
          lineJoin="round"
          opacity={previewOpacity}
          stroke={canvasUiColors.preview}
          strokeWidth={areaBorderPx}
        />
      </>
    );
  }

  const paths = unionPaths(draw.map((cell) => getCellPathExpanded(cell, { expand: previewExpandPx })));

  return paths.map((path, i) => (
    <Line
      closed
      key={i}
      lineCap="round"
      lineJoin="round"
      opacity={previewOpacity}
      points={path.flat()}
      stroke={canvasUiColors.preview}
      strokeWidth={areaBorderPx}
    />
  ));
}

/**
 * Renders a highlight shape on area and connection hover.
 */
function HighlightRegion({
  instructions,
  isSelected,
  regionId,
}: {
  instructions: MatrixInstructions;
  isSelected?: boolean;
  regionId: number;
}) {
  const coordinates = isArea(regionId)
    ? instructions.areas.get(regionId)?.coordinates
    : instructions.connections.get(regionId)?.coordinates;

  if (!coordinates) {
    throw new TypeError(`Missing coordinates for region "${regionId}" in <HighlightRegion>`);
  }

  const paths = unionPaths(coordinates.map(getCellPath));

  return (
    <RegionShape
      fill={canvasUiColors.preview}
      opacity={isSelected ? previewOpacity : previewSelectionOpacity}
      paths={paths}
    />
  );
}

/**
 * Renders the highlighted cell on hover.
 */
function HoverPreview({
  coordinates,
  instructions,
  matrix,
}: {
  coordinates: Coordinates;
  instructions: MatrixInstructions;
  matrix: MatrixImmutable;
}) {
  const cellValue = getCellValue(matrix, coordinates);
  const rect = isConnection(cellValue)
    ? getPreviewRectConnection(getMapEntryRequired(instructions.connections, cellValue).coordinates)
    : getPreviewRect(coordinates);

  // const rect = getPreviewRect(previewCoordinates);

  return (
    <Rectangle
      {...rect}
      lineCap="round"
      lineJoin="round"
      opacity={previewOpacity}
      stroke={canvasUiColors.preview}
      strokeWidth={areaBorderPx}
    />
  );
}

/**
 * Renders an invalid cell indicator for each invalid cell.
 */
function InvalidCells({
  activeBrush,
  activeDrawOption,
  coordinates,
  matrix,
}: {
  activeBrush: Brush;
  activeDrawOption: DRAW_OPTION;
  coordinates: Coordinates[];
  matrix: MatrixImmutable;
}) {
  const invalidCells = getInvalidCells(
    matrix,
    coordinates,
    activeBrush,
    activeDrawOption
  );

  return invalidCells.map(([ x, y ], i) => (
    <Path
      data={invalidIndicatorPath}
      key={i}
      lineCap="round"
      lineJoin="round"
      opacity={previewOpacity}
      stroke={canvasUiColors.preview}
      strokeWidth={areaBorderPx}
      x={x * cellPx}
      y={y * cellPx}
    />
  ));
}

/**
 * Rectangle draw size tooltip.
 */
function RectangleDrawTooltip({
  end,
  start,
}: {
  end?: Coordinates;
  start: Coordinates;
}) {
  const [ x1, y1 ] = start;
  const [ x2 = x1, y2 = y1 ] = end || [];

  const minX = Math.min(x1, x2);
  const maxX = Math.max(x1, x2);

  const minY = Math.min(y1, y2);
  const maxY = Math.max(y1, y2);

  const rectWidth = maxX - minX + 1;
  const rectHeight = maxY - minY + 1;

  return (
    <Text
      fill={colors.label}
      fontSize={fontSizeMapTitleTiny}
      text={`${rectWidth} x ${rectHeight}`}
      x={(minX * cellPx) + previewExpandPx}
      y={(minY * cellPx) - fontSizeMapTitleTiny - (previewExpandPx * 2)}
    />
  );
}

// -- Private Functions --------------------------------------------------------

/**
 * Returns draw cells for the current draw option.
 */
function getDrawCells(coordinates: Coordinates[], drawOption: DRAW_OPTION): Coordinates[] {
  if (drawOption === DRAW_OPTION.Rectangle) {
    const [ start, end ] = coordinates;
    return getRectangleCoordinates(start, end);
  }

  if (drawOption === DRAW_OPTION.Line) {
    const [ start, end ] = coordinates;
    return getLineCoordinates(start, end);
  }

  return coordinates;
}

/**
 * Returns invalid cells for the hover and draw previews.
 *
 * A cell will be invalid when the current matrix, which includes values for the
 * active draw or hover, has not applied the value for the current action.
 */
function getInvalidCells(
  matrix: MatrixImmutable,
  coordinates: Coordinates[],
  brush: Brush,
  drawOption: DRAW_OPTION
): Coordinates[] {
  const drawCoordinates = getDrawCells(coordinates, drawOption);

  if (brush in CONNECTION && !isConnection(getCellValue(matrix, coordinates[0]))) {
    return drawCoordinates;
  }

  if (brush in DETAIL) {
    return drawCoordinates.filter((cell) => !isArea(getCellValue(matrix, cell)));
  }

  return drawCoordinates.filter((cell) => getCellValue(matrix, cell) === null);
}

/**
 * Returns a rectangle for the given start and end coordinates.
 */
function getPreviewRect(start: Coordinates, end?: Coordinates): Rect {
  if (!end) {
    end = start;
  }

  const [ startX, startY ] = start;
  const [ endX, endY ] = end;

  return {
    height: ((Math.abs(end[1] - start[1]) + 1) * cellPx) + (previewExpandPx * 2),
    width: ((Math.abs(end[0] - start[0]) + 1) * cellPx) + (previewExpandPx * 2),
    x: (Math.min(startX, endX) * cellPx) - previewExpandPx,
    y: (Math.min(startY, endY) * cellPx) - previewExpandPx,
  };
}

/**
 * Returns a rectangle for the given start and end coordinates.
 */
function getPreviewRectConnection(coordinates: Coordinates[]): Rect {
  const { 0: start, [coordinates.length - 1]: end } = coordinates
    .sort(([ ax, ay ], [ bx, by ]) => ax === bx ? (ay - by) : (ax - bx));

  return getPreviewRect(start, end);
}

/**
 * Returns start and end coordinates for a line draw preview.
 */
function getPreviewLine(coordinates: Coordinates[]): Coordinates[] {
  const [ start, end ] = coordinates;

  if (!end) {
    return [ start, end ];
  }

  const [ startX, startY ] = start;
  const [ endX, endY ] = end;

  return isHorizontalLine(start, end)
    ? [ start, [ endX, startY ]]
    : [ start, [ startX, endY ]];
}
