import { discordInviteUrl, patreonUrl } from '../../config';
import Link from '../Interface/Link';

const linkRegex = /\[|\]/g;

/**
 * Injects links into dynamically loaded copy. Links should be denoted by square
 * brackets and contain a keyword which will be matched to a URL.
 */
export default function CopyWithLinks({ copy }: { copy: string }) {
  const parts = copy.split(linkRegex);

  return parts.map((part, j) => {
    if (part === 'Discord') {
      return <Link href={discordInviteUrl} key={j}>{part}</Link>;
    }

    if (part === 'Patreon') {
      return <Link href={patreonUrl} key={j}>{part}</Link>;
    }

    return part;
  });
}
