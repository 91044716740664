import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  FormHelperText,
  Stack,
} from '@mui/material';
import { useEffect, useState } from 'react';

import {
  areaRegions,
  connectionMaxSpan,
  connections,
  details,
} from '../../../lib/matrix';
import { toWords } from '../../../lib/string';
import {
  ChevronRight as ChevronRightIcon,
  Pencil as PencilIcon,
} from '../../Display/Icons';
import WidowFix from '../../Display/WidowFix';
import Keybindings from './Keybindings';

import styles from './Palette.module.css';

import type { Brush } from '../../../lib/matrix';

// -- Types --------------------------------------------------------------------

type BrushGroup = 'Areas' | 'Connections' | 'Details';

// -- Config -------------------------------------------------------------------

/** Brush groups config. */
const brushGroups: {
  brushes: Brush[];
  description: string;
  label: BrushGroup;
}[] = [
  {
    brushes: [ ...areaRegions ],
    description: 'Areas are the basic building blocks of map composition, comprising of rooms, hallways, and (eventually) open spaces.',
    label: 'Areas',
  },
  {
    brushes: [ ...connections ],
    description: `Connections separate enclosed areas or connect enclosed areas to external spaces. Connections have a maximum of ${connectionMaxSpan} cells.`,
    label: 'Connections',
  },
  {
    brushes: [ ...details ],
    description: 'Detail can be applied to areas to add purpose and embellishment.',
    label: 'Details',
  },
];

/** Keyboard shortcut indicator keys. */
const keybindings: Record<BrushGroup, string> = {
  Areas: 'A',
  Connections: 'C',
  Details: 'X',
};

/** Help text styles. */
const helperTextSx = {
  margin: '0 1rem 1rem 1rem',
};

/** Palette button styles. */
const paletteButtonSx = {
  justifyContent: 'space-between',
  paddingLeft: '1rem',
  transition: 'none',
  width: '100%',
};

/** Palette button styles. */
const paletteButtonSelectedSx = {
  ...paletteButtonSx,
  '&.MuiButtonBase-root:hover': {
    backgroundColor: 'secondary.main',
  },
};

// -- Public Component ---------------------------------------------------------

/**
 * Renders the map's brush palette.
 */
export default function Palette({
  activeBrush,
  onChangeBrush,
}: {
  activeBrush?: Brush;
  onChangeBrush: (brush: Brush) => void;
}) {
  const [ showInfo, setShowInfo ] = useState(false);
  const [ showKeybindings, setShowKeybindings ] = useState(false);

  return (
    <div className={styles.panel}>
      <Stack
        alignItems="stretch"
        direction="row"
        justifyContent="stretch"
        m={2}
        mb={4}
        spacing={1}
      >
        <Button
          aria-label={`${showInfo ? 'Hide' : 'Show'} drawing category descriptions`}
          aria-pressed={showInfo}
          color={showInfo ? 'inherit' : 'primary'}
          onClick={() => setShowInfo(!showInfo)}
          size="small"
          variant="outlined"
        >
          Info
        </Button>

        <Button
          color="primary"
          onClick={() => setShowKeybindings(true)}
          size="small"
          variant="outlined"
        >
          Shortcuts
        </Button>
      </Stack>

      {brushGroups.map(({ brushes, description, label }, i) => {
        return (
          <AccordionGroup
            activeBrush={activeBrush}
            brushes={brushes}
            description={description}
            index={i}
            key={i}
            label={label}
            showInfo={showInfo}
          >
            <ul className={styles.paletteList}>
              {brushes?.map((brush) => (
                <PaletteOption
                  isSelected={activeBrush === brush}
                  key={brush}
                  label={toWords(brush)}
                  onClick={() => onChangeBrush(brush)}
                />
              ))}
            </ul>
          </AccordionGroup>
        );
      })}

      {showKeybindings &&
        <Keybindings onClose={() => setShowKeybindings(false)} />
      }
    </div>
  );
}

// -- Private Components -------------------------------------------------------

/**
 * Renders a palette accordion group.
 */
function AccordionGroup({
  activeBrush,
  brushes,
  children,
  description,
  index,
  label,
  showInfo,
}: {
  activeBrush?: Brush;
  brushes: Brush[];
  children: React.ReactNode;
  description: string;
  index: number;
  label: BrushGroup;
  showInfo: boolean;
}) {
  const [ isExpanded, setIsExpanded ] = useState(true);
  const idPrefix = `brush-palette-accordion-${index}`;
  const descriptionId = `${idPrefix}-description`;

  useEffect(() => {
    if (activeBrush && brushes.includes(activeBrush)) {
      setIsExpanded(true);
    }
  }, [ brushes, activeBrush ]);

  return (
    <Accordion
      disableGutters
      expanded={isExpanded}
      onChange={() => setIsExpanded(!isExpanded)}
    >
      <AccordionSummary
        aria-controls={idPrefix}
        aria-describedby={showInfo ? descriptionId : undefined}
        expandIcon={<ChevronRightIcon aria-hidden />}
        id={`${idPrefix}-handle`}
      >
        {label} <kbd className={styles.keybinding}>{keybindings[label]}</kbd>
      </AccordionSummary>
      <AccordionDetails>
        {showInfo &&
          <FormHelperText
            id={descriptionId}
            sx={helperTextSx}
          >
            <WidowFix>{description}</WidowFix>
          </FormHelperText>
        }

        {children}
      </AccordionDetails>
    </Accordion>
  );
}

/**
 * Renders a pallet option button.
 */
function PaletteOption({
  isSelected,
  label,
  onClick,
}: {
  isSelected: boolean;
  label: string;
  onClick: () => void;
}) {
  return (
    <li>
      <Button
        aria-pressed={isSelected}
        color={isSelected ? 'secondary' : 'primary'}
        onClick={onClick}
        sx={isSelected ? paletteButtonSelectedSx : paletteButtonSx}
        tabIndex={-1}
        variant={isSelected ? 'contained' : 'text'}
      >
        {label}

        {isSelected &&
          <PencilIcon aria-hidden className={styles.icon} />
        }
      </Button>
    </li>
  );
}
