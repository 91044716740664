import { createRangeLookup } from '../../lib/number';

import type { NumericRange } from '../../lib/number';

// -- Types --------------------------------------------------------------------

export type Quantity = typeof quantities[number];

// -- Config -------------------------------------------------------------------

/**
 * Quantities
 */
export const quantities = [
  'zero',
  'one',
  'couple',
  'few',
  'some',
  'several',
  'many',
  'numerous',
] as const;

/**
 * All quantities excluding "zero"
 */
export const quantitiesPositive: Exclude<Quantity, 'zero'>[] = quantities.filter(
  (quantity) => quantity !== 'zero'
);

/**
 * A lookup of range counts for each quantity.
 */
export const quantityRanges = createRangeLookup(new Map([
  [ 'zero', 0 ],
  [ 'one', 1 ],
  [ 'couple', 2 ],
  [ 'few', 3 ],
  [ 'some', 5 ],
  [ 'several', 8 ],
  [ 'many', 14 ],
  [ 'numerous', 25 ],
]), { maximum: 100 }) as Readonly<Record<Quantity, NumericRange>>;
