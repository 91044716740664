import { memo } from 'react';
import { Group, Rect as Rectangle } from 'react-konva';

import { cellPx, colors, detailPx } from '../../../config/map';
import { DETAIL } from '../../../lib/matrix';

import type { Coordinates } from '../../../lib/matrix';

// -- Config -------------------------------------------------------------------

const chestWidth = cellPx * 0.7;
const chestHeight = cellPx * 0.5;
const chestX = cellPx * 0.15;
const chestY = cellPx * 0.25;

const strapWidth = cellPx * 0.1;
const strapInset = cellPx * 0.13;

// -- Memoized Components ------------------------------------------------------

const DetailsMemo = memo(Details);

// -- Public Component ---------------------------------------------------------

/**
 * Renders a chest.
 */
export default function Chest({
  coordinates: [ x, y ],
}: {
  coordinates: Coordinates;
}) {
  const xPx = x * cellPx;
  const yPx = y * cellPx;

  return (
    <Group
      data-id={`detail-${DETAIL.Chest}`}
      data-theme="classic"
      x={xPx}
      y={yPx}
    >
      <DetailsMemo />
    </Group>
  );
}

// -- Private Components -------------------------------------------------------

/**
 * Renders chest details.
 */
function Details() {
  return (
    <>
      <Rectangle
        fill={colors.detail}
        height={chestHeight}
        stroke={colors.border}
        strokeWidth={detailPx}
        width={chestWidth}
        x={chestX}
        y={chestY}
      />

      <Rectangle
        fill={colors.shadow}
        height={chestHeight}
        stroke={colors.border}
        strokeWidth={detailPx}
        width={strapWidth}
        x={chestX + strapInset}
        y={chestY}
      />

      <Rectangle
        fill={colors.shadow}
        height={chestHeight}
        stroke={colors.border}
        strokeWidth={detailPx}
        width={strapWidth}
        x={chestX + chestWidth - strapInset - strapWidth}
        y={chestY}
      />
    </>
  );
}
