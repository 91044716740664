import { useCallback, useEffect, useReducer } from 'react';

import Matrix, { DRAW_OPTION } from '../../../lib/matrix';
import {
  addHistoryEntry,
  redoHistory,
  replaceHistoryEntry,
  undoHistory,
} from '../../../lib/matrix/history';

import type { MapSnapshot } from '../../../lib/map';
import type {
  Brush,
  Coordinates,
  Dimensions,
  MatrixHistoryEntry,
  MatrixImmutable,
  MatrixInstructions,
  RegionUpdate,
} from '../../../lib/matrix';
import type { Debug, DebugMatrix } from '../../../lib/matrix/debug';

// -- Types --------------------------------------------------------------------

interface ActionClear {
  type: 'clear';
}

interface ActionDebug {
  debug: Debug;
  type: 'debug';
}

interface ActionDraw {
  brush: Brush;
  commit?: boolean;
  coordinates: Coordinates[];
  drawOption: DRAW_OPTION;
  type: 'draw';
}

interface ActionErase {
  commit?: boolean;
  coordinates: Coordinates[];
  drawOption: DRAW_OPTION;
  type: 'erase';
}

interface ActionHistoryRedo {
  type: 'redo';
}

interface ActionHistoryUndo {
  type: 'undo';
}

interface ActionSetDimensions {
  matrixHeight: number;
  matrixWidth: number;
  type: 'setDimensions';
}

interface ActionSetTitle {
  title: string;
  type: 'setTitle';
}

type ActionUpdateRegion = {
  regionId: number;
  type: 'updateRegion';
  updates: RegionUpdate;
};

export interface InteractiveMapState {

  /** The maps current history entry. */
  currentHistoryEntry: MatrixHistoryEntry;

  /** A matrix used for map debugging only. */
  debugMatrix?: DebugMatrix;

  /** Instructions for drawing the map. */
  instructions: MatrixInstructions;

  /** The core map matrix. */
  matrix: MatrixImmutable;

  /** The height of the map matrix. */
  matrixHeight: number;

  /** The width of the map matrix. */
  matrixWidth: number;

  /** Callback for clearing the map. */
  onClearMap: () => void;

  /** Callback for drawing cells on the map. */
  onDraw: (coordinates: Coordinates[], brush: Brush, drawOption: DRAW_OPTION, options?: { commit?: true }) => void;

  /** Callback for erasing cells from the map. */
  onErase: (coordinates: Coordinates[], drawOption: DRAW_OPTION, options?: { commit?: true }) => void;

  /** Callback which moves forward one step in the map's history. */
  onRedo?: () => void;

  /** Callback for modifying the matrix's dimensions. */
  onSetDimensions: (dimensions: Dimensions) => void;

  /** Callback which sets the map's title. */
  onSetTitle: (title: string) => void;

  /** Callback which moves backward one step in the map's history. */
  onUndo?: () => void;

  /** Callback which updates a region's properties. */
  onUpdateRegion: (regionId: number, updates: RegionUpdate) => void;

  /* The map's title. */
  title: string;
}

type ReducerAction = ActionClear
  | ActionDebug
  | ActionDraw
  | ActionErase
  | ActionHistoryRedo
  | ActionHistoryUndo
  | ActionSetDimensions
  | ActionSetTitle
  | ActionUpdateRegion;

type ReducerState = {
  canRedo: boolean;
  canUndo: boolean;
  debug?: Debug;
  debugMatrix?: DebugMatrix;
  dimensions: Dimensions;
  historyEntries: MatrixHistoryEntry[];
  historyIndex: number;
  instructions: MatrixInstructions;
  matrix: Matrix;
  source: MatrixImmutable;
  title: string;
};

/** Defines a global `setDebugMatrix()` function for map debugging. */
declare global {
  function setDebugMatrix(value?: Debug): void;
}

// -- Config -------------------------------------------------------------------

/** Valid debug matrix options. */
export const debugValues = new Set<Debug>([
  'areas',
  'coordinates',
  'details',
  'info',
  'labels',
  'values',
]);

// -- Public Hook --------------------------------------------------------------

/**
 * Manages interactive map state.
 */
export default function useInteractiveMap(snapshot?: MapSnapshot): InteractiveMapState {
  const [ state, dispatch ] = useReducer(mapReducer, null, () => getDefaultState(snapshot));

  const {
    canRedo,
    canUndo,
    debugMatrix,
    dimensions: [ matrixWidth, matrixHeight ],
    historyEntries,
    historyIndex,
    instructions,
    source,
    title,
  } = state;

  /** Clears the current map, preserving dimensions and history. */
  const onClearMap = useCallback(() => {
    dispatch({ type: 'clear' });
  }, []);

  const currentHistoryEntry = historyEntries[historyIndex];

  /** Draws cells on the map. */
  const onDraw = useCallback((
    coordinates: Coordinates[],
    brush: Brush,
    drawOption: DRAW_OPTION,
    { commit = false } = {}
  ) => {
    dispatch({
      brush,
      commit,
      coordinates,
      drawOption,
      type: 'draw',
    });
  }, []);

  /** Erases cells from the map. */
  const onErase = useCallback((
    coordinates: Coordinates[],
    drawOption: DRAW_OPTION,
    { commit = false } = {}
  ) => {
    dispatch({
      commit,
      coordinates,
      drawOption,
      type: 'erase',
    });
  }, []);

  /** Updates a region's properties */
  const onUpdateRegion = useCallback((regionId: number, updates: RegionUpdate) => {
    dispatch({
      regionId,
      type: 'updateRegion',
      updates,
    });
  }, []);

  const redo = useCallback(() => dispatch({ type: 'redo' }), []);
  const undo = useCallback(() => dispatch({ type: 'undo' }), []);

  /** Moves map history forward one step, if possible. */
  const onRedo = canRedo ? redo : undefined;

  /** Moves map history backwards one step, if possible. */
  const onUndo = canUndo ? undo : undefined;

  /** Sets the map's dimensions. */
  const onSetDimensions = useCallback(([ width, height ]: Dimensions) => {
    dispatch({
      matrixHeight: height,
      matrixWidth: width,
      type: 'setDimensions',
    });
  }, []);

  /** Sets the map's title. */
  const onSetTitle = useCallback((newTitle: string) => {
    dispatch({ title: newTitle, type: 'setTitle' });
  }, []);

  useEffect(() => {
    registerGlobalDebugSetter((debug: Debug) => {
      dispatch({
        debug,
        type: 'debug',
      });
    });
  }, []);

  return {
    currentHistoryEntry,
    debugMatrix,
    instructions,
    matrix: source,
    matrixHeight,
    matrixWidth,
    onClearMap,
    onDraw,
    onErase,
    onRedo,
    onSetDimensions,
    onSetTitle,
    onUndo,
    onUpdateRegion,
    title,
  };
}

// -- Reducer ------------------------------------------------------------------

/**
 * Map grid reducer.
 */
function mapReducer(state: ReducerState, action: ReducerAction): ReducerState {

  // -- Clear ------------------------------------------------------------------

  if (action.type === 'clear') {
    const { historyEntries, historyIndex, matrix: previousMatrix } = state;
    const matrix = new Matrix({
      entry: { dimensions: previousMatrix.getDimensions() },
      idPool: previousMatrix.getIdPool(),
    });

    const {
      getDebugMatrix,
      getDimensions,
      getHistoryEntry,
      getInstructions,
      getSource,
    } = matrix;

    return {
      ...state,
      ...addHistoryEntry(historyEntries, historyIndex, getHistoryEntry()),
      debugMatrix: getDebugMatrix(state.debug),
      dimensions: getDimensions(),
      instructions: getInstructions(),
      matrix,
      source: getSource(),
    };
  }

  // -- Debug ------------------------------------------------------------------

  if (action.type === 'debug') {
    return {
      ...state,
      debug: action.debug === 'info' ? undefined : action.debug,
      debugMatrix: state.matrix.getDebugMatrix(action.debug),
    };
  }

  // -- Draw & Erase -----------------------------------------------------------

  if (action.type === 'draw' || action.type === 'erase') {
    const { coordinates, drawOption, type } = action;

    const {
      historyEntries,
      historyIndex,
    } = state;

    const matrix = state.matrix.clone();

    const {
      draw,
      erase,
      getDebugMatrix,
      getHistoryEntry,
      getInstructions,
      getSource,
    } = matrix;

    const hasMutation = type === 'draw'
      ? draw(coordinates, action.brush, drawOption)
      : erase(coordinates, drawOption);

    if (action.commit && hasMutation) {
      return {
        ...state,
        ...addHistoryEntry(historyEntries, historyIndex, getHistoryEntry()),
        debugMatrix: getDebugMatrix(state.debug),
        instructions: getInstructions(),
        matrix,
        source: getSource(),
      };
    }

    return {
      ...state,
      debugMatrix: getDebugMatrix(state.debug),
      instructions: getInstructions(),
      source: getSource(),
    };
  }

  // -- History ----------------------------------------------------------------

  if (action.type === 'redo' || action.type === 'undo') {
    const { historyEntries, historyIndex } = state;

    const { entry, ...historyState } = action.type === 'redo'
      ? redoHistory(historyEntries, historyIndex)
      : undoHistory(historyEntries, historyIndex);

    const matrix = new Matrix({ entry, idPool: state.matrix.getIdPool() });

    const {
      getDebugMatrix,
      getDimensions,
      getInstructions,
      getSource,
    } = matrix;

    return {
      ...state,
      ...historyState,
      debugMatrix: getDebugMatrix(state.debug),
      dimensions: getDimensions(),
      instructions: getInstructions(),
      matrix,
      source: getSource(),
    };
  }

  // -- Set Dimensions ----------------------------------------------------------

  if (action.type === 'setDimensions') {
    const { matrixHeight, matrixWidth } = action;
    const { historyEntries, historyIndex, matrix: previousMatrix } = state;

    const [ previousWidth, previousHeight ] = previousMatrix.getDimensions();

    if (matrixWidth === previousWidth && matrixHeight === previousHeight) {
      // Guards against empty history entries when dimensions are unchanged.
      throw new TypeError(`New dimensions of "${matrixWidth},${matrixHeight}" are identical to current dimensions in mapReducer()`);
    }

    const { details, regions } = previousMatrix.getHistoryEntry();

    const matrix = new Matrix({
      entry: { details, dimensions: [ matrixWidth, matrixHeight ], regions },
      idPool: previousMatrix.getIdPool(),
    });

    const {
      getDebugMatrix,
      getDimensions,
      getHistoryEntry,
      getInstructions,
      getSource,
    } = matrix;

    return {
      ...state,
      ...addHistoryEntry(historyEntries, historyIndex, getHistoryEntry()),
      debugMatrix: getDebugMatrix(state.debug),
      dimensions: getDimensions(),
      instructions: getInstructions(),
      matrix,
      source: getSource(),
    };
  }

  // -- Set Title --------------------------------------------------------------

  if (action.type === 'setTitle') {
    return { ...state, title: action.title };
  }

  // -- Update Region ----------------------------------------------------------

  if (action.type === 'updateRegion') {
    const { historyEntries, historyIndex, matrix } = state;

    matrix.updateRegion(action.regionId, action.updates);

    return {
      ...state,
      ...replaceHistoryEntry(historyEntries, historyIndex, matrix.getHistoryEntry()),
      debugMatrix: state.matrix.getDebugMatrix(state.debug),
      instructions: state.matrix.getInstructions(),
    };
  } /* v8 ignore next 4 */

  // @ts-expect-error - Unknown action type
  throw new TypeError(`Unknown action type "${action.type}" in useInteractiveMap(), mapReducer()`);
}

// -- Private Functions --------------------------------------------------------

/** Initializes reducer state. */
function getDefaultState(snapshot?: MapSnapshot): ReducerState {
  const matrix = new Matrix({ entry: snapshot });

  const {
    getDimensions,
    getHistoryEntry,
    getInstructions,
    getSource,
  } = matrix;

  return {
    ...addHistoryEntry([], 0, getHistoryEntry()),
    dimensions: getDimensions(),
    instructions: getInstructions(),
    matrix,
    source: getSource(),
    title: snapshot?.title || '',
  };
}

/**
 * Registers a global debug setter for use in the console.
 */
function registerGlobalDebugSetter(setDebug: (debug?: Debug) => void) {
  globalThis.setDebugMatrix = function (value?: Debug): string {
    if (!value) {
      setDebug();
      return 'Matrix debug disabled';
    }

    if (!debugValues.has(value)) {
      return `Invalid debug matrix option "${value}". Valid options include "${[ ...debugValues ].join('", "')}".`;
    }

    setDebug(value);

    return `Matrix debug set to "${value}"`;
  };
}
