import CopyWithLinks from '../../components/Display/CopyWithLinks';
import Fetching from '../../components/Display/Fetching';
import StaticPage from '../../components/Display/StaticPage';
import useFetchData from '../../components/hooks/useFetchData';
import { path } from '../../config/path';

import styles from './styles.module.css';

// -- Types --------------------------------------------------------------------

export interface RoadMapCopy {
  body: string[];
  items: string[];
}

// -- Config -------------------------------------------------------------------

/** Path to the road map data file. */
const roadMapJsonPath = '/data/roadmap.json';

// -- Public Component ---------------------------------------------------------

/**
 * Renders the roadmap page.
 */
export default function RoadmapPage() {
  const { data: roadMap, ...fetchState } = useFetchData<RoadMapCopy>(roadMapJsonPath);

  return (
    <StaticPage
      metaDescription="Mystic Waffle's dungeon generator feature roadmap. Want to see a feature get added? Let us know on our Discord!"
      path={path.roadmap}
      title="Roadmap"
    >
      <Fetching {...fetchState} />

      {roadMap &&
        <>
          <div>
            {roadMap.body.map((paragraph, i) => {
              return (
                <p className="paragraph" key={i}>
                  <CopyWithLinks copy={paragraph} />
                </p>
              );
            })}
          </div>

          <ul className={styles.items}>
            {roadMap.items.map((paragraph, i) => (
              <li key={i}>
                {paragraph}
              </li>
            ))}
          </ul>
        </>
      }
    </StaticPage>
  );
}
