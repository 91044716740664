import { memo } from 'react';
import { Circle, Group, Star } from 'react-konva';

import { cellPx, colors, detailPx } from '../../../config/map';
import { DETAIL } from '../../../lib/matrix';

import type { Coordinates } from '../../../lib/matrix';

// -- Config -------------------------------------------------------------------

const halfCellPx = cellPx / 2;

const circleRadius = cellPx * 0.35;

const starRadius = cellPx * 0.3;

const starInnerRadius = cellPx * 0.14;

// -- Memoized Components ------------------------------------------------------

const DetailsMemo = memo(Details);

// -- Public Component ---------------------------------------------------------

/**
 * Renders a statue.
 */
export default function Statue({
  coordinates: [ x, y ],
}: {
  coordinates: Coordinates;
}) {
  const xPx = x * cellPx;
  const yPx = y * cellPx;

  return (
    <Group
      data-id={`detail-${DETAIL.Statue}`}
      data-theme="classic"
      x={xPx}
      y={yPx}
    >
      <DetailsMemo />
    </Group>
  );
}

// -- Private Components -------------------------------------------------------

/**
 * Renders statue details.
 */
function Details() {
  return (
    <>
      <Circle
        fill={colors.regionDungeon}
        radius={circleRadius}
        stroke={colors.border}
        strokeWidth={detailPx}
        x={halfCellPx}
        y={halfCellPx}
      />

      <Star
        fill={colors.border}
        innerRadius={starInnerRadius}
        numPoints={5}
        outerRadius={starRadius}
        x={halfCellPx}
        y={halfCellPx}
      />
    </>
  );
}
